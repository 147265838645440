import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import NotFound from "../NotFound";
import SignInPage from "../SignInPage";
import SignUpPage from "../SignUpPage";
import Otpwrapper from "../../FormWrapper/Opt";
import NewPassword from "../../FormWrapper/NewPassword";
import ResetPassword from "../../FormWrapper/ResetPassword";
import Profile from "../../FormWrapper/Profile";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import "./auth.scss"

export default function AuthLayout({ routes }) {
  const location = useLocation();
  const isAuthenticated = localStorage.getItem("user.access");
  const currentLocation = location.pathname;
  const getCurrentRoute = () => {
    console.log(currentLocation)
    switch (currentLocation) {
      case "/login":
        return <SignInPage route={routes} />;
      case "/signup":
        return <SignUpPage route={routes} />;
      case "/otp":
        return <Otpwrapper route={routes} />;
      case "/reset-password":
        return <ResetPassword route={routes} />;
      case "/new-password":
        return <NewPassword route={routes} />;
      case "/profile":
        return <Profile route={routes} />;
      default:
        if(currentLocation.includes('register'))
        return <SignUpPage route={routes} />;
        else if(currentLocation.includes('new-password'))
        return  <NewPassword route={routes} />
        else
        return <NotFound />;
    }
  };

  if (isAuthenticated && !currentLocation.includes('profile')) return <Redirect to={{ pathname: "/dashboard/All" }} />;
  else {
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
          // margin: "auto",
        }}
        height={{sm:"100%"}}
        className="box"
        
      >
        <Grid
          container
          className="container"
          spacing={2}
          flexWrap="nowrap"
        >
          <Grid
            item
            container
            xs={12}
            md={6}
            // direction={{ xs: "column-reverse", sm: "column" }}
            className="item"
            rowGap={{ xs: 2, sm: 4 }}
            flexWrap="nowrap"
            flexGrow={1}
            margin="auto"
            height={{sm:"100%"}}
          >
            <Grid
              maxHeight={{ xs: "auto", sm: 200 }}
              item
              container
              xs={12}
              justifyContent="center"
              alignItems={{ xs: "center", sm: "flex-end" }}
            >
              <Box
                component="img"
                sx={{
                  height: { xs: 25, sm: 39 },
                  width: { xs: 195, sm: 300 },
                  display: "block",


                }}
                alt="Logo"
                src={require("../../../assets/images/logo.jpg")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              // alignItems={{ xs: "center", sm: "flex-end" }}
              // alignItems="flex-start"
            >
              <Grid
                style={{alignContent: "center" }}
                item
                container
                direction="column"
                alignItems="flex-start"
                
              >
                <Grid item xs={12} sx={{ width: { xs: 195, sm: 300 } }}  style={{display:"flex"}}>
                  {getCurrentRoute()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} maxHeight={{ xs: "40%", sm: "100%" }}>
            <Box
              sx={{
                width: "100%",
                height:"100%"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height:"100%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: { xs: "0px 0px 50px 50px", sm: 0 },
                  background: {
                    xs: "#489DFA",
                    sm: " linear-gradient(163.95deg, #007AFF 11.17%, #64AEFF 105.3%)",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexGrow: 2,
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: { xs: 209, sm: 270, md: 350, lg: 498 },
                      width: { xs: 232, sm: 300, md: 400, lg: 556 },
                      borderRadius: 0,
                    }}
                    alt="Frame"
                    src="/assets/pic1.svg"
                  />
                </Box>
                { (
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: { xs: 300, sm: 400, md: 500 },
                      fontSize: { xs: 18, sm: 18, md: 24 },
                      flexGrow: 1,
                    }}
                    component="div"
                    align="center"
                    color="white"
                  >
                    Create your educational worksheet effectively
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
