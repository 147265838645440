import React from "react";
import { Field, ErrorMessage } from "formik";

function SelectField(props) {
  const { label, name, options, ...rest } = props;
  // console.log(options)
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Field as="select" id={name} name={name} {...rest} >
        <option value="" hidden default>
          Please select
        </option>
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </Field>
      <ErrorMessage component="div" style={{color:"red",fontSize:"xx-small",position:"absolute"}} name={name} />
    </div>
  );
}

export default SelectField;
