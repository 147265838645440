import React, { useState, useEffect } from "react";
import "./Container.scss";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import RGL, { WidthProvider } from "react-grid-layout";

import parse from "html-react-parser";

// //Mui

import { Button, Container } from "@material-ui/core";

// //icons
import AddIcon from "@material-ui/icons/Add";

//redux
import { useDispatch, useSelector } from "react-redux";

import {
  addContainerBtnClicked,
  selectContainer,
  setBoxSize,
  setContainerHeight,
  setBox,
  selectBox,
  fetchContainer
} from "../../store/actions/containerActions";
import { setActivetab,fetchPage } from "../../store/actions/pageActions";

import { MathJax } from "better-react-mathjax";
import { useRef } from "react";
import Graph from "../graph/Graph";
import Api from "../../ApiRequests";

const GridLayout = WidthProvider(RGL);

const Container3 = ({
  container,
  containerIndex,
  page,
  pageIndex,
  preview,
}) => {
  const dispatch = useDispatch();

  const data = useSelector((state)=>state.worksheet)

  const {currentUser} = useSelector(state=>state.auth)

  const location = window.location.pathname.split('/')[2]

  const loadSheet=()=>{
    console.log("called load");
    Api.getWorksheetById(location).then(res=>{
      dispatch({type:"LOAD_WORKSHEET",payload:res.data.body.workSheet})
      dispatch(fetchPage(res.data.body.data.page))
      dispatch(fetchContainer(res.data.body.data.container))
    })
  }

  // useEffect(()=>{
  //   if(location)
  //  {
  //    loadSheet()
  //  }
  // },[])

  const [, forceRender] = useState({});

  const pageWidth = page.width;
  const pageContentHeight = page.pageContentHeight;

  const width = pageWidth;
  const height = 120;

  const [isDraggable, setIsDraggable] = useState(false);

  const calculMaxHeight = pageContentHeight;



  const layout = container.boxes
    ? container.boxes.map((box, index, boxes) => {
        return {
          ...box,
          i: "box-" + index,
          x: box.x,
          y: box.y ? box.y : 0,
          w: box.w,
          h: box.h,
          maxH: 3 * (pageContentHeight / 120) - 1,
          minH: 1,
        };
      })
    : [];

  const resizeContainer = (element, id, layout, newItem, oldItem) => {
    ///debugger;
    return new Promise((resolve, reject) => {
      let h = 0;

      layout.map((l) => {
        if (h < l.h + l.y) {
          h = l.h + l.y;
        }
        return l;
      });

      if (h + 3 > oldItem.maxH) {
        dispatch(setBox(id, oldItem)).then(() => {
          reject();
        });

        return;
      }

      for (let i = 0; i < h; i++) {
        const lines = layout.filter((l) => l.y === i);
        if (lines.length > 4) {
          // console.log(lines.length);
          dispatch(setBox(id, oldItem)).then(() => {
            reject(lines.length);
          });

          return;
        }
      }
      const parent = element.parentElement || element.parentNode;
      const grandParent = parent.parentElement || parent.parentNode;

      dispatch(setContainerHeight(page, grandParent.offsetHeight, id)).then(
        () => {
          resolve();
          return;
        }
      );
    });
  };

  const onCLickContainerbtn = () => {
    dispatch(setActivetab("container"));
    dispatch(addContainerBtnClicked());

    ///
  };
  return (
    <div>
      {container.hasAddButton && !preview ? (
        <div
          className="container-btn"
          style={{
            height: 120,
            color: container.selected ? "red" : "black",
          }}
        >
          {
            <Button
              color="primary"
              variant="contained"
              className="btn-blue"
              onClick={onCLickContainerbtn}
            >
              <AddIcon />
            </Button>
          }
        </div>
      ) : !container.header ? (
        <div
          style={{
            borderWidth: `${container.borderStyle ? 4 : 1}px`,
            maxHeight: calculMaxHeight,
            borderStyle: container.borderStyle || "solid",
            borderColor: container.borderColor || "#007aff",
            backgroundColor: container.backgroundColor
              ? container.backgroundColor
              : preview
                ? "white"
                : "#eff7ff",
          }}
          className={
            container.selected
              ? "selected-container-boxes"
              : "notselected-container"
          }
          id={container.id}
          onClick={(e) => {
            dispatch(selectContainer(container.id));
          }}
        >
          {container.icon && (
            <img
              src={container.icon}
              width="50"
              height="50"
              style={{ position: "absolute", top: -25, right: 5 }}
              alt=""
            />
          )}
          {container?.boxes?.length > 0 ? (
            <GridLayout
              className="layout"
              cols={12}
              width={width}
              rowHeight={height / 3}
              layout={layout}
              autoSize={true}
              margin={[5, 0]}
              isDraggable={!preview && isDraggable}
              isResizable={!preview}
              onResizeStop={(
                layout,
                oldItem,
                newItem,
                placeholder,
                e,
                element
              ) => {
                return resizeContainer(
                  element,
                  container.id,
                  layout,
                  newItem,
                  oldItem
                )
                .then(() => {
                  dispatch(setBoxSize(page, containerIndex, layout));
                })
                .catch((error) => {
                  forceRender({});
                  // console.log("error",error);
                });
              }}
              onDragStop={(
                layout,
                oldItem,
                newItem,
                placeholder,
                e,
                element
              ) => {
                return resizeContainer(
                  element,
                  container.id,
                  layout,
                  newItem,
                  oldItem
                )
                .then(() => {
                  dispatch(setBoxSize(page, containerIndex, layout));
                })
                .catch(() => {
                  forceRender({});

                });
              }}
            >
              {container.boxes.map((box, index, boxes) => {
                const boxStyle = {
                  borderWidth: `${container.borderHeight}px`,
                  backgroundColor: box.backgroundColor
                    ? box.backgroundColor
                    : preview
                      ? "white"
                      : "#eff7ff",
                  borderStyle: box.borderStyle || "solid",
                  borderColor: box.borderColor || "#007aff",
                };
                return (
                  <div
                    className="col"
                    id={container.id}
                    key={"box-" + index}
                    onMouseEnter={(e) => {
                      e.stopPropagation();
                      setIsDraggable(true);
                    }}
                    onMouseLeave={(e) => {
                      e.stopPropagation();
                      setIsDraggable(false);
                    }}
                  >
                    <div
                      className="col-content"
                      style={boxStyle}
                      onClick={(e) => {
                        e.stopPropagation();

                        dispatch(selectBox(container.index, index));
                      }}
                    >
                      {box.icon && (
                        <img
                          src={box.icon}
                          width="50"
                          height="50"
                          style={{ position: "absolute", top: -25, right: 5 }}
                          alt=""
                        />
                      )}
                      <ShowBoxContent box={box} page={page} />
                    </div>
                  </div>
                );
              })}
            </GridLayout>
          ) : (
            <div className="col" style={{ height: 120 }}></div>
          )}
        </div>
      ) : (
        <div
          onClick={(e) => {

            dispatch(selectContainer(container.id));
          }}
        >
          <div className="header">
            <p className="header-title">{currentUser?.institution}</p>
            <br/>
            <p className="header-text">Tutor : {data?.notesBy}</p>
            <p className="header-text">
              Cell : {data?.phoneNumber} | Email : {data?.email} | Website :{" "}
              {data?.website}{" "}
            </p>
            <p></p>
            <p className="header-sous-title">{data?.title}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const ShowBoxContent = ({ box, page }) => {
  const tableRef = useRef();

  const Text = () =>
    box.type === "text" && (
      <div className="ql-editor text" style={{ maxHeight: box.h * (120 / 3) }}>
        <MathJax>{parse(box.text || "")}</MathJax>
      </div>
    );

  const Table = () =>
    box.type === "table" && (
      <table
        ref={tableRef}
        style={{
          width: tableRef.current
            ? tableRef.current.parentElement.parentElement.offsetWidth - 5
            : "100%",
          height: box.h * (120 / 3),
        }}
      >
        {box.table.map((row, i) => {
          return (
            <tr key={`sideright-table-tr-${i}`}>
              {row.map((ceil, j) => {
                return (
                  <td
                    key={`sideright-table-td-${j}`}
                    style={{
                      cursor: "pointer",
                      width: tableRef.current
                        ? (tableRef.current.parentElement.parentElement
                            .offsetWidth -
                        5) /
                          box.nbColumns
                        : undefined,
                      height: tableRef.current
                        ? (tableRef.current.parentElement.parentElement
                            .offsetHeight -
                        5) /
                          box.nbRows
                        : undefined,
                    }}
                  >
                    <div
                      className="ql-editor test"
                      style={{
                        padding: 4,
                        overflow: "hidden",
                        width: tableRef.current
                          ? (tableRef.current.parentElement.parentElement
                              .parentElement.offsetWidth -
                              5) /
                            box.nbColumns
                          : undefined,
                        height: tableRef.current
                          ? (tableRef.current.parentElement.parentElement
                              .parentElement.offsetHeight -
                              5) /
                            box.nbRows
                          : undefined,
                      }}
                    >
                      {parse(ceil.text)}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
    );

  const _Math = () =>
    box.type === "math" && (
      <div className="ql-editor">
        <MathJax>{`` + box.text + ``}</MathJax>
      </div>
    );

  const Line = () =>
    box.type === "line" && (
      <div
        style={{
          minHeight: (box.nbLines)*box.nbLinesHeight,
          maxHeight:(box.h*40),
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        {Array(box.nbLines || 0)
          .fill(null)
          .map((line, index) => {
            return <hr key={"hr-" + index} style={{marginTop:index===0?box.nbLinesHeight:0, marginBottom:box.nbLinesHeight }} />;
          })}
      </div>
    );

  const _Graph = () => {
    // console.log(page);

    return (
      <div>
        <Graph
          box={box}
          _height={box.h * (120 / 3)}
          _width={(page.width * box.w) / 12}
        />
      </div>
    );
  };

  const Image = () =>
    box.type === "image" && (
      <div
        style={{
          height: box.h * (120 / 3) - 30,
          width: "100%",
          backgroundSize: "contain",
          backgroundImage: `url(${box.image})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition:"center",
          // position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "1rem"
          }}
        >
          <span style={{ color: "black", lineBreak: "anywhere" }}>
            {box.caption}
          </span>
        </div>
      </div>
    );

  return (
    <div id={box.id} className="box-content">
      {Text()}
      {_Math()}
      {Table()}
      {Line()}
      {Image()}
      {box.type === "graph" && box.graph && _Graph()}
    </div>
  );
};

export default Container3;
