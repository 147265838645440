// Render Prop
import { useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import FormControl from "../Form/FormControl";
import { validationSchema } from "../Form/validation/loginValidation";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const LoginWrapper = ({ onSignInSubmit, initialValues }) => {
  const [show, setShow] = useState(false);
  const { load } = useSelector((state) => state.auth);
  // console.log(load)
  const icon = {
    fontSize: "1rem",
    color: "#79747E",
    cursor: "pointer",
  };

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);

          onSignInSubmit(values);
        }}
        // enableReinitialize={true}
      >
        {(formProps) => (
          <Form style={{ width: "100%" }}>
            <Grid
              className={styles.formCont}
              container
              direction="column"
              rowGap={{ xs: 2.2, sm: 4 }}
              justifyContent="center"
              style={{ display: "flex", width: "100%" }}
            >
              <Grid item>
                <h1 className={styles.heading}>Login</h1>
              </Grid>
              <Grid item style={{ width: "90%" }}>
                <div className={styles.inputbox}>
                  <EmailIcon style={{ fontSize: "1rem", color: "#79747E" }} />
                  <FormControl
                    control="input"
                    type="email"
                    name="email"
                    placeholder="Email"
                    errorout={true}
                    className={styles.input}
                   
                  />
                </div>
                <ErrorMessage
                  component="div"
                  style={{
                    position: "absolute",
                    color: "red",
                    fontSize: "xx-small",
                  }}
                  name="email"
                />
              </Grid>
              <Grid item>
                <div className={styles.inputbox}>
                  <LockIcon style={{ fontSize: "1rem", color: "#79747E" }} />
                  <FormControl
                    control="input"
                    type={show ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    errorout={true}
                    className={styles.input}
                  />
                  {show ? (
                    <VisibilityIcon
                      style={icon}
                      onClick={() => setShow(!show)}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={icon}
                      onClick={() => setShow(!show)}
                    />
                  )}
                </div>
                <ErrorMessage
                  component="div"
                  style={{
                    position: "absolute",
                    color: "red",
                    fontSize: "xx-small",
                  }}
                  name="password"
                />
              </Grid>
              <Grid item>
                <p className={styles.link}>
                  <Link to="/reset-password" className={styles.fakelink}>
                    Forgot Password?
                  </Link>
                </p>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={load}
                  className={styles.btn}
                >
                  <div style={{ marginRight: "5px" }}>Login</div>
                  {load && (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  )}
                </Button>
                <p></p>
                <p className={styles.link}>
                  Don't have an account?{" "}
                  <Link to="/signup" className={styles.fakelink}>
                    Sign Up
                  </Link>
                </p>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginWrapper;
