import * as React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Drawer, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Menu,
  MenuItem,
} from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import UpdateIcon from "@mui/icons-material/Update";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditWorksheetModal from "./EditWorksheetModal";
import Api from "../../../../ApiRequests";
import DeleteWorksheetModal from "./DeleteWorksheet";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { fetchPage } from "../../../../store/actions/pageActions";
import {fetchContainer} from "../../../../store/actions/containerActions";
import { toast } from "react-toastify";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function SwipeableEdgeDrawer({
  id,
  open,
  handleOpen,
  index,
  props,
  setDeleted,
  setCopiedWorksheet,
  setEdited
}) {
  // console.log(props.status)
  const history = useHistory();
  const dispatch = useDispatch()
  const [edit, setEdit] = React.useState(false);
  const [deleteModal, setDelete] = React.useState(false);
  const [editProps, setEditProps] = React.useState({});
  // console.log(document.getElementById(id)?.clientWidth);
  const [opened, setOpened] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [isDeleted,setIsDeleted]=React.useState(false)

  // const loadSheet=(id)=>{
  //   Api.getWorksheetById(id).then(res=>{
  //     dispatch({type:"LOAD_WORKSHEET",payload:res.data.body.workSheet})
  //     dispatch(fetchPage(res.data.body.data.page))
  //     dispatch(fetchContainer(res.data.body.data.container))
  //   })
  // }

  const deleteWorksheet = (id) => {
    Api.deleteSheet(id)
      .then((res) => {


        setIsDeleted(true)
        setDeleted(id);
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const generateCopy = () => {
    Api.generateCopy(props.id)
      .then((res) => {
        // setCreated(true)
        setCopiedWorksheet(res.data.body);
        // dispatch({type:"SUCCESS",payload:res.data.message})
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  // console.log(message,opened,severity)
  return (
    <>
      <Collapse
        in={open}
        style={{
          position: "absolute",
          bottom: "0",
          width: "100%",
          background: "white",
          borderRadius: "5px",
          border: " 1px solid #007AFF",
          overflow: "auto",
          borderBottom: "0",
        }}
      >
        <List>
          {props.status === "Draft" && (
            <ListItemButton
              onClick={() => {
                setEditProps(props);
                setEdit(true);
                handleOpen(index);
              }}
              style={{ fontSize: "medium", display: "block",paddingLeft:"8px" }}
            >
              <ListItemIcon style={{ color: "black" }}>
                <EditIcon fontSize="small" style={{ marginRight: "8px" }} />{" "}
                Edit
              </ListItemIcon>
            </ListItemButton>
          )}
          {props.status === "Draft" && <Divider />}
          {props.status === "Draft" && (
            <ListItemButton
              onClick={() => {
                handleOpen(index);
                history.push(`/home/${props.id}`);
                // loadSheet(props.id);
              }}
              style={{ fontSize: "medium", display: "block",paddingLeft:"8px" }}
            >
              <ListItemIcon style={{ color: "black" }}>
                <UpdateIcon fontSize="small" style={{ marginRight: "8px" }} />{" "}
                Update
              </ListItemIcon>
            </ListItemButton>
          )}
          {props.status === "Draft" && <Divider />}
          {props.status === "Draft" && (
            <ListItemButton
              onClick={() => {
                handleOpen(index);
                setDelete(true);

              }}
              style={{ fontSize: "medium", display: "block",paddingLeft:"8px" }}
            >
              <ListItemIcon style={{ color: "black" }}>
                <DeleteIcon fontSize="small" style={{ marginRight: "8px" }} />{" "}
                Delete
              </ListItemIcon>
            </ListItemButton>
          )}
          {props.status === "Draft" && <Divider />}
          <ListItemButton
            onClick={() => {
              handleOpen(index);
              generateCopy(props.id);
            }}
            style={{ fontSize: "medium", display: "block",paddingLeft:"8px" }}
          >
            <ListItemIcon style={{ color: "black" }}>
              <ContentCopyIcon
                fontSize="small"
                style={{ marginRight: "8px" }}
              />{" "}
              Copy {props.title}
            </ListItemIcon>
          </ListItemButton>
        </List>
        <EditWorksheetModal
          open={edit}
          setOpen={setEdit}
          setEdited={setEdited}
          props={editProps}
        />
        <DeleteWorksheetModal
          deleteWorksheet={deleteWorksheet}
          id={props.id}
          open={deleteModal}
          setOpen={setDelete}
        />
      </Collapse>

      {/* <Drawer
        anchor="top"
        // variant="persistent"
        open={open}
        onClose={() => {
        console.log("index",index)
        handleOpen(index)}}
        PaperProps={{
        style: {
          position: "absolute",
          top: "10%",
          height: "90%",
          width: "100%",
          borderRadius: "5px",
          border:" 1px solid #007AFF"
        },
        }}
        BackdropProps={{ style: { position: "absolute",cursor:"pointer" } }}
        ModalProps={{
        container: document.getElementById(id),
        style: { position: "absolute" },
        }}
        SlideProps={{
        onExiting: (node) => {
          node.style.webkitTransform = "scaleX(0)";
          node.style.transform = "scaleX(0)";
          node.style.transformOrigin = "top left";
        },

        }}
        >
        <List>
        <ListItemButton onClick={()=>{
        setEditProps(props)
        setEdit(true)
        }}>Edit</ListItemButton>
        <Divider/>
        <ListItemButton>Preview</ListItemButton>
        <Divider/>
        <ListItemButton onClick={()=>history.push(`/home/${props.id}`)}>Publish</ListItemButton>
        <Divider/>
        <ListItemButton>Update </ListItemButton>
        <Divider/>
        <ListItemButton onClick={()=>setDelete(true)}>Delete</ListItemButton>
        <ListItemButton onClick={()=>generateCopy(props.id)}>Generate Copy</ListItemButton>
        </List>
        <EditWorksheetModal
        open={edit}
        setOpen={setEdit}
        setCreated={setCreated}
        props={editProps}
        />
        <DeleteWorksheetModal
        deleteWorksheet={deleteWorksheet}
        id={props.id}
        open={deleteModal}
        setOpen={setDelete}
        setCreated={setCreated}/>
    </Drawer> */}
    </>
  );
}

export default SwipeableEdgeDrawer;
