import React from "react";
import { initialValues } from "./constants";
import SignUpWrapper from "../../FormWrapper/SignUp";
import { signup } from "../../../store/actions/auth";
import {useDispatch} from "react-redux"
import { useHistory } from "react-router-dom";
export default function SignUpPage() {
  const history = useHistory()
  const dispatch = useDispatch()
  let onSignUpSubmit = (values) => {
    // console.log(values)
    dispatch(signup(values,history))
  };

  return (
    <SignUpWrapper
      onSignUpSubmit={onSignUpSubmit}
      initialValues={initialValues}
    />
  );
}
