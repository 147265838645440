// Render Prop
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Button, CircularProgress, Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import FormControl from "../Form/FormControl";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styles from "./styles.module.scss";
import Api from "../../ApiRequests";
import { useHistory } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
const ResetPassword = () => {
  const initialValues = { email: "" };
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid Email").required("Required"),
  });
  const dispatch = useDispatch()
  const {load}= useSelector(state=>state.auth)
  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // console.log(values)
          dispatch({type:"LOADING"})
          Api.resetPassword(values)
            .then((res) => {
              console.log("Sending",res)
              dispatch({type:"SUCCESS",payload:res.data.message})
              dispatch({type:"LOADED"})
            })
            .catch(() => { dispatch({type:"LOADED"})});
        }}
      >
        {(formProps) => (
          <Form>
            <Grid
              className={styles.formCont}
              container
              direction="column"
              rowGap={{ xs: 2, sm: 4 }}
              justifyContent="center"
              style={{ alignContent: "flex-start" }}
            >
              <Grid item>
                <h1 className={styles.heading}>
                  <ArrowBackIosIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push("/login");
                    }}
                  />{" "}
                  Reset Password
                </h1>
              </Grid>

              <Grid item>
                <div className={styles.inputbox}>
                  <EmailIcon style={{ fontSize: "1rem", color: "#79747E" }} />
                  <FormControl
                    control="input"
                    type="email"
                    name="email"
                    placeholder="Email"
                    errorout={true}
                  />
                </div>
              </Grid>

              <Grid item>
                <Button
                disabled={!formProps.isValid||!formProps.dirty||load}
                  variant="contained"
                  type="submit"
                  className={styles.btn}
                >
                  <div style={{marginRight:"5px"}}>
                  Send Link
                  </div>
                   {load&&<CircularProgress size={20} style={{color:"white"}}/>}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
