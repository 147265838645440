import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setBoxHeight, setBoxText, resetBoxType } from "../../store/actions/containerActions";
import { deleteBox } from '../../store/actions/containerActions';
import { BorderStyle } from '../borderstyle/BorderStyle';

//icons
import DeleteIcon from "@material-ui/icons/Delete";
import "./style.scss"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const EditMathBox = ({ selectedPage, box }) => {
  const [_box , _setBox] = useState(box)
  const height = 120;
  const dispatch = useDispatch();
  const textarea = useRef()

  const pageContentHeight = selectedPage.pageContentHeight;
  useEffect(()=>{
    dispatch(setBoxText(box, _box.text));
    const rowHeight = height / 3;

    const contentHeight = document.getElementById(box.id).offsetHeight;

    if (box.h > pageContentHeight / rowHeight - 0.3) {
      //alert('error')
      return;
    }

    if (box.h * rowHeight < contentHeight) {
      document.getElementById(box.id).parentElement.parentElement.style.height =
        document.getElementById(box.id).offsetHeight + "px";
      dispatch(setBoxHeight(selectedPage, box, contentHeight / rowHeight));
    }
  }, [_box.text])
  const handleChange = (e) => {
    const html = e.target.value;
    _setBox({
      ..._box,
      text : html
    })
  };

  const addText = (text) =>{
    _setBox({
      ..._box,
      text : textarea.current.value +  ``  +   text + ``
    })
  }

  const stringToHTML=(index, symbol)=>{
    // let dom = document.querySelector(`#formula-${index}`);
    // // dom.innerHTML = text;
    // console.log(dom);

    var button = document.createElement('button');
    button.innerHTML = symbol.sign;
    button.onclick = function() {addText(symbol.latex)}
    // console.log(button);
  }

  const symbolsArr = [

    {
      "sign":"sin",
      "latex":"\\(\\sin(x)\\)"
    },
    {
      "sign":"cos",
      "latex":"\\(\\cos(x)\\)"
    },
    {
      "sign":"tan",
      "latex":"\\(\\tan(x)\\)"
    },
    {
      "sign":"cot",
      "latex":"\\(\\cot(x)\\)"
    },
    {
      "sign":"sec",
      "latex":"\\(\\sec(x)\\)"
    },
    {
      "sign":"csc",
      "latex":"\\(\\csc(x)\\)"
    },
    {
      "sign":"csc",
      "latex":"\\(\\csc(x)\\)"
    },
    {
      "sign":"∪",
      "latex":"\\(\\cup\\)"
    },
    {
      "sign":"≃",
      "latex":"\\(\\simeq\\)"
    },
    {
      "sign":"≈",
      "latex":"\\(\\approx\\)"
    },
    {
      "sign":"⊂",
      "latex":"\\(\\subset\\)"
    },
    {
      "sign":"⊆",
      "latex":"\\(\\subseteq\\)"
    },
    {
      "sign":"⊃",
      "latex":"\\(\\supset\\)"
    },
    {
      "sign":"⊇",
      "latex":"\\(\\supseteq\\)"
    },
    {
      "sign":"⊈",
      "latex":"\\(\\nsubseteq\\)"
    },
    {
      "sign":"⊉",
      "latex":"\\(\\nsupseteq\\)"
    },
    {
      "sign":"←",
      "latex":"\\(\\leftarrow\\)"
    },
    {
      "sign":"→",
      "latex":"\\(\\rightarrow\\)"
    },
    {
      "sign":"↔",
      "latex":"\\(\\leftrightarrow\\)"
    },
    {
      "sign":"⇌",
      "latex":"\\(\\rightleftharpoons\\)"
    },
    {
      "sign":"⇄",
      "latex":"\\(\\leftrightarrows\\)"
    },
    {
      "sign":"∞",
      "latex":"\\(\\infty\\)"
    },
    {
      "sign":"∫",
      "latex":"\\(\\int\\)"
    },
    {
      "sign":"∫∫",
      "latex":"\\(\\iint\\)"
    },
    {
      "sign":"hyp",
      "latex":"Hyp"
    },
    {
      "sign":"log",
      "latex":"\\(\log(x)\\)"
    },
    {
      "sign":"e",
      "latex": "e"
    },
    {
      "sign": "%",
      "latex":  "%"
    },
    {
      "sign":"e",
      "latex": "e"
    },

  ]
  return (
    <div>

      <Box className="display-flex box-head brd-botm">

        <Button
          variant="outlined"
          onClick={()=>{
            dispatch(resetBoxType(box,false));
            _setBox({
              ..._box,
              text : ""
            })
          }}
        >
          Reset
        </Button>

        <Button
          variant="outlined"
          onClick={()=>{
            _setBox({
              ..._box,
              text : ""
            })
          }}
        >
          clear
        </Button>

        <Button
          variant="outlined"
          color="error"
          onClick={() => dispatch(deleteBox(box))}
        >
          {/* <DeleteIcon fontSize="small" /> */}
          Delete Box
        </Button>
      </Box>
      <Box className="head">
        <h4>
          Box {box.index + 1} : {box.type}
        </h4>
      </Box>


      <Box  className="brd-botm" style={{padding:"1rem"}}>
        <div>
          
          <h5>Latex Editor</h5>

          <Box className="formulabox">
            {symbolsArr.map((symbol,index)=>{
              return <button key={index} className="btn-frmula"  onClick={()=>addText(symbol.latex)}>{symbol.sign}</button>
            })}
            <button className="btn-frmula"  onClick={()=>addText("\\(\\alpha\\)")}>&alpha;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\beta\\)")}>&beta;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\gamma\\)")}>&gamma;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\delta\\)")}>&delta;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\sigma\\)")}>&sigma;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\theta\\)")}>&theta;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\lambda\\)")}>&lambda;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\Omega\\)")}>&Omega;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\phi\\)")}>&phi;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\rho\\)")}>&rho;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\Sigma\\)")}>&Sigma;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\epsilon\\)")}>&epsilon;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\\le\\)")}>&le;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\\ge\\)")}>&ge;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(x^n\\)")}>x<sup>n</sup></button>
            <button className="btn-frmula"  onClick={()=>addText("π")}>&#x3C0;</button>
            <button className="btn-frmula"  onClick={()=>addText("\\(\\sqrt{x}\\)")}>&radic;</button>
          </Box>
          <Box className="latex-area">
            <textarea
              ref={textarea}
              cols="20"
              rows="7"
              value={_box.text}
              onChange={handleChange}
            ></textarea>
          </Box>

        </div>
      </Box>



      <BorderStyle type="container" box={box} />


    </div>
  );
};

export default EditMathBox;
