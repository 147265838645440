import { useEffect, useRef, useState } from "react";
import Nav from "../../Nav";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CreateWorksheetModal from "./CreateWorksheetModal/CreateWorksheetModal";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { getMyProfile } from "../../../store/actions/auth";
import Api from "../../../ApiRequests";
import { IconButton } from "@material-ui/core";
import { Grid, Paper, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SwipeableEdgeDrawer from "./CreateWorksheetModal/Drawer";
import { ArrowBack } from "@material-ui/icons";
import { ArrowBackIos } from "@mui/icons-material";
import EditWorksheetModal from "./CreateWorksheetModal/EditWorksheetModal";
import { useHistory } from "react-router-dom";

let count=0;
let limit = 10;
let offset = 0;

const Worksheets = ({ status }) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [created, setCreated] = useState(null);
  const { currentUser } = useSelector((state) => state.auth);
  const [mySheets, setmySheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [infiniteLoad, setinfiniteLoad] = useState(false);
  const ref = useRef(null);

  document.body.onscroll = () => {
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
      if(count > limit){
        limit = limit+10;
        setinfiniteLoad(true);
        loadData()
      }
    }
  };

  const loadData = () => {

    Api.getWorksheet({ limit: limit, offset: offset, status: status }).then((res) => {
      count = res?.data?.body.count;
      setmySheets(
        res.data.body.results.map((result) => {
          result.open = false;
          return result;
        })
      );
      setinfiniteLoad(false);
      setLoading(false);
    });
  };

  useEffect(() => {
      setLoading(true);
      loadData();
  }, []);

  const handleClick = (right) => {
    // console.log(ref.current.offsetWidth);
    if (right) ref.current.scrollLeft += ref.current.offsetWidth;
    else ref.current.scrollLeft += -ref.current.offsetWidth;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (idx) => {
    setmySheets(
      mySheets.map((sheet, index) => {
        if (idx === index) sheet.open = !sheet.open;
        return sheet;
      })
    );
  };

  const isopen = Boolean(anchorEl);
  const id = isopen ? "simple-popover" : undefined;

  return (
    <div className={styles.wrapper}>
      <div className={styles.section} style={{ paddingLeft: "0px" }}>
        <div className={styles.sectionHeader}>
          <div className={styles.heading}>
            <ArrowBackIos onClick={() => history.push("/dashboard/All")} />
            <h3>{status==='draft'?"My Worksheets (Draft)":"My Published Worksheets"}</h3>
          </div>
          <button className={styles.createBtn} onClick={() => setOpen(true)}>
            Create Worksheet
          </button>
          <button className={styles.createBtnI} onClick={() => setOpen(true)}>
            <img src="/img/9.svg" alt="" />
          </button>
        </div>
      </div>
      <Grid container ref={ref} style={{ width: "100%" }}>
        {loading
          ? [1, 2, 3,4,5].map(() => (
            <Grid
              key={id}
              item
              xs={12}
              sm={6}
              md={3}
              style={{maxWidth:"225px",margin:"0 1%"}}
            >
              <Skeleton variant="rectangular" className={styles.skeleton} />
            </Grid>
          ))
          : <>
            {
              mySheets.map(
                (
                  {
                      id,
                      title,
                      notesBy,
                      phoneNumber,
                      email,
                      website,
                      type,
                      open,
                      image,
                      fullname,
                      status,
                  },
                    index
                ) => (
                  <>
                    <Grid key={id} item xs={12} sm={6} md={3} style={{maxWidth:"225px",margin:"0 1%"}}>
                      <div className={styles.box} id={id}>
                        <div>
                          <div className="header">
                            <p
                              className="header-title"
                              style={{
                                fontSize: "90%",
                                lineHeight: "100%",
                                margin: "3%",
                                position: "relative",
                                overflowWrap: "break-word",
                              }}
                            >
                              {currentUser?.institution}
                            </p>

                            <p
                              className="header-sous-title"
                              style={{
                                fontSize: "80%",
                                lineHeight: "100%",
                                margin: "3%",
                                position: "relative",
                                overflowWrap: "break-word",
                              }}
                            >
                              Tutor : {notesBy}
                            </p>
                            <p
                              className="header-text"
                              style={{
                                fontSize: "55%",
                                lineHeight: "100%",
                                margin: "3%",
                                position: "relative",
                                overflowWrap: "break-word",
                              }}
                            >
                              Cell | {phoneNumber} | Email |{email}| Website |{" "}
                              {website}{" "}
                            </p>
                            <p
                              className="header-text"
                              style={{
                                fontSize: "75%",
                                lineHeight: "100%",
                                margin: "3%",
                                position: "relative",
                                overflowWrap: "break-word",
                              }}
                            >
                              {title.length > 10 ? `${title.substring(0,10)}...` : title}
                            </p>
                          </div>
                          <div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>

                        <div className={styles.footer}></div>
                        <div className={styles.page}>Page no: 01</div>
                        <SwipeableEdgeDrawer
                          id={id}
                          open={open}
                          handleOpen={handleOpen}
                          index={index}
                          props={{
                            id,
                            title,
                            notesBy,
                            phoneNumber,
                            email,
                            website,
                            type,
                            open,
                            image,
                            fullname,
                            status,
                          }}

                        />
                      </div>
                      <Grid item>
                        <div className={styles.meta}>
                          {title.length > 10 ? `${title.substring(0,10)}...` : title}
                          <MoreVertIcon
                            onClick={() => {
                              handleOpen(index);
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>

                  </>
                )
              )
            }

            {
              infiniteLoad && [1, 2, 3,4,5].map(() => (
                <Grid
                  key={id}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  style={{maxWidth:"225px",margin:"0 1%"}}
                >
                  <Skeleton variant="rectangular" className={styles.skeleton} />
                </Grid>
              ))
            }
          </>
        }
      </Grid>
      <CreateWorksheetModal
        open={open}
        setOpen={setOpen}

      />
    </div>
  );
};

export default Worksheets;
