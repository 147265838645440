import { Button, Grid } from "@material-ui/core";
import "./Navbar.scss";

//icons
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useHistory } from "react-router-dom";
import image from "../../img/image 51.jpg";
import { Avatar, Divider, Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../ApiRequests";
import { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { loadUser } from "../../store/actions/auth";
import TemporaryDrawer from "./Drawer";
import { KeyboardArrowUp } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";

const NavDash = () => {
  const user_data = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ref = useRef();
  const [state, setState] = useState({
    right: false,
  });
  const history = useHistory();
  const handleClick = (event) => {
    if (anchorEl === null) setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
    setAnchorEl(null);
    history.push("/login");
  };

  const handleProfile = () => {
    history.push("/settings/0");
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!user_data) dispatch(loadUser());
    // console.log("calling");
  }, [user_data]);
  // console.log(user_data);

  return (
    <nav className="dashnavbar">
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Grid item xs={10} md={4} className="logo-container">
          <Link to="/dashboard/All">
            <img src={image} alt="" className="image" />
          </Link>
        </Grid>


        <Grid item xs={2} md={8}>
          <div className="avatar">
            <Avatar
              sx={{ width: 50, height: 50 }}
              style={{ border: "2px solid #007AFF" }}
              src={user_data?.avatar}
            >
              {user_data?.fullname[0]}
            </Avatar>
            <div className="details">
              <div className="name">{user_data?.fullname}</div>
              <div className="email">{user_data?.email}</div>
            </div>
            <div className="dropdown" onClick={handleClick}>
              {anchorEl === null ? (
                <KeyboardArrowDownIcon className="details" />
              ) : (
                <KeyboardArrowUp className="details" />
              )}
            </div>

            <Menu
              className="menu"
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  border: "1px solid #007AFF",
                  marginTop: "20px",
                },
              }}
            >
              <MenuItem onClick={handleProfile} style={{ padding: "4px 8px" }}>
                <SettingsIcon fontSize="small" style={{marginRight:"8px"}} />
                Settings
              </MenuItem>
              <Divider style={{ margin: "4px" }} />
              <MenuItem onClick={handleLogout} style={{ padding: "4px 8px" }}>
                <LogoutIcon fontSize="small"  style={{marginRight:"8px"}}/>
                Logout
              </MenuItem>
            </Menu>
          </div>
          <div className="drawer">
            <MenuIcon
              className="icon"
              onClick={() => setState({ ...state, right: true })}
            />
            <TemporaryDrawer
              state={state}
              setState={setState}
              user_data={user_data}
            />
          </div>
        </Grid>
      </Grid>
    </nav>
  );
};

export default NavDash;
