import React from "react";
import OTPInput from "otp-input-react";
export default function SegmentedField({ OTP, setOTP }) {
  return (
    <OTPInput
      value={OTP}
      onChange={setOTP}
      autoFocus
      OTPLength={6}
      otpType="number"
      disabled={false}
      inputStyles={{ height: "40px", width: "40px",borderRadius:"5px",border:"1px solid black" }}
    />
  );
}
