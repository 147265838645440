import { Grid } from "@material-ui/core";
import { Button, Box } from "@mui/material";
import "./Navbar.scss";
import AskModal from "./password/AskPop";
import moment from "moment";

//icons
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import Api from "../../ApiRequests";
import { useSelector, useDispatch } from "react-redux";
import Pages from "../pages/Pages";
import useSave from "../../save";
import { toast } from "react-toastify";
import { useState } from "react";
import Skeleton from '@mui/material/Skeleton';

const Navbar = () => {
  const worksheet = useSelector((state) => state.worksheet);
  const { payload } = useSave();
  const dispatch = useDispatch();
  const [open,setOpen]=useState(false)
  const loadmetaData = () => {
    Api.getWorksheetById(worksheet.id).then((res) => {
      dispatch({ type: "LOAD_WORKSHEET", payload: res.data.body.workSheet });
      // dispatch(fetchPage(res.data.body.data.page))
      // dispatch(fetchContainer(res.data.body.data.container))
    });
  };

  const save = async () => {
    try {
      let ress = await Api.updateWorksheet(worksheet.id, payload);
      console.log(ress);
      toast.success("All changes saved", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      loadmetaData();
    } catch (err) {
      console.log(err.response);
      toast.error(`${err.response.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const generatePDF = () => {
    const allCSS = [...document.styleSheets]
      .map((styleSheet) => {
        try {
          return [...styleSheet.cssRules].map((rule) => rule.cssText).join("");
        } catch (e) {
          console.log(
            "Access to stylesheet %s is denied. Ignoring...",
            styleSheet.href
          );
        }
      })
      .filter(Boolean)
      .join("\n");
    const prtContent = document.getElementById("pages");
    setOpen(true)
    // Api.publishWorksheet(worksheet.id, {
    //   htmlContent: `<style>${allCSS}</style> ${prtContent.innerHTML}`,
    // }).then((res) => console.log(res));
  };
  // console.log(window.location)
  return (
    <nav className="navbar">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Grid item xs={2}>
          <div className="logo-container">
            <img src="./img/image 51.jpg" alt="" />
          </div>
        </Grid>

        <Grid item xs>
          <Link to="/">
            <p className="navbar-link">
              <ArrowBackIosIcon />
              {worksheet.load ? <Skeleton variant="text" style={{width: "50%"}} /> :<span>{worksheet.title.length <= 15 ? worksheet.title : `${worksheet.title.substring(0,15)}...` }</span>}
            </p>
          </Link>
        </Grid>

        <Grid item xs>
          <Box style={{ display: "flex" }}>
            {worksheet.load ?  <>
              <Skeleton variant="text" style={{width: "50%"}} />
              <Skeleton variant="circular" width={30} height={30} style={{marginLeft: "10px"}}/>
            </> : <>
              <p className="text-gray">{moment(worksheet.updatedAt).fromNow()}</p>
              <Box
                style={{
                  alignSelf: "center",
                  marginLeft: "1rem",
                  cursor: "pointer",
                }}
              >
                <CloudUploadIcon onClick={save} />
              </Box>
            </>}
          </Box>
        </Grid>

        <Grid item xs={7}>
          <div className="btn-container">
            <Button
              component={Link}
              variant="contained"
              color="primary"
              className="btn-orange btn"
              to="/preview"
            >
              Preview
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="btn-blue btn"
              onClick={generatePDF}
            >
              Publish
            </Button>
          </div>
        </Grid>
      </Grid>
      <AskModal open={open} setOpen={setOpen} id={worksheet.id}/>
    </nav>
  );
};

export default Navbar;
