import React from "react";
import { Field, ErrorMessage } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function CheckboxesField(props) {
  const { label, name, options, ...rest } = props;
  return (
    <>
      <Field
        as={FormControlLabel}
        name={name}
        control={<Checkbox />}
        label={label}
        {...rest}
      
        sx={{padding:0}}
      />
      
    </>
  );
}

export default CheckboxesField;
