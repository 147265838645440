// Render Prop
import { useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { Button, Grid } from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FormControl from "../Form/FormControl";
import { validationSchemaPass } from "../Form/validation/resetPassword";

import styles from "./styles.module.scss";
import Api from "../../ApiRequests";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const NewPassword = ({ onSignUpSubmit }) => {
  const {token} = useParams()
  const initialValues = {
    email: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [show, setShow] = useState(false);
  const [showConf, setShowConf] = useState(false);
  const history = useHistory();
  const icon = {
    fontSize: "1rem",
    color: "#79747E",
    cursor: "pointer",
  };
  const {load} = useSelector(state=>state.auth)
  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaPass}
        onSubmit={(values) => {
          values={...values,token}
          console.log(values)
          // Api.resetChangePassword(values)
          //   .then(() => {})
          //   .catch(() => {});
        }}
      >
        {(formProps) => (
          <Form>
            <Grid
              className={styles.formCont}
              container
              direction="column"
              rowGap={{ xs: 2, sm: 4 }}
              justifyContent="center"
              style={{ alignContent: "flex-start" }}
            >
              <Grid item>
                <h1 className={styles.heading}>
                  <ArrowBackIosIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push("/login");
                    }}
                  />{" "}
                  Reset Password
                </h1>
              </Grid>
              <Grid item>
                <div className={styles.inputbox}>
                  <EmailIcon style={{ fontSize: "1rem", color: "#79747E" }} />
                  <FormControl
                    control="input"
                    type="email"
                    name="email"
                    placeholder="Email"
                    errorout={true}
                    className={styles.input}
                  />
                </div>
                <ErrorMessage
                  component="div"
                  style={{
                    position: "absolute",
                    color: "red",
                    fontSize: "xx-small",
                  }}
                  name="email"
                />
              </Grid>

              <Grid item>
                <div className={styles.inputbox}>
                  <LockIcon style={{ fontSize: "1rem", color: "#79747E" }} />
                  <FormControl
                    control="input"
                    type={show ? "text" : "password"}
                    name="newPassword"
                    placeholder="New Password"
                    errorout={true}
                    className={styles.input}
                  />
                  {show ? (
                    <VisibilityIcon
                      style={icon}
                      onClick={() => setShow(!show)}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={icon}
                      onClick={() => setShow(!show)}
                    />
                  )}
                </div>
                <div style={{position:"relative"}}>
                  <ErrorMessage
                    component="div"
                    style={{
                      position: "absolute",
                      color: "red",
                      fontSize: "xx-small",
                    }}
                    name="newPassword"
                  />
                </div>
              </Grid>
              <Grid item>
                <div className={styles.inputbox}>
                  <LockIcon style={{ fontSize: "1rem", color: "#79747E" }} />
                  <FormControl
                    control="input"
                    type={showConf ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    errorout={true}
                    className={styles.input}
                  />
                  {showConf ? (
                    <VisibilityIcon
                      style={icon}
                      onClick={() => setShowConf(!showConf)}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={icon}
                      onClick={() => setShowConf(!showConf)}
                    />
                  )}
                </div>
                <ErrorMessage
                  component="div"
                  style={{
                    position: "absolute",
                    color: "red",
                    fontSize: "xx-small",
                  }}
                  name="confirmPassword"
                />
              </Grid>
              <Grid item>
                <Button
                disabled={!formProps.isValid||!formProps.dirty}
                  variant="contained"
                  type="submit"
                  className={styles.btn}
                  onClick={()=>formProps.handleSubmit(formProps.values)}
                >
                 Change Password
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewPassword;
