import { useEffect, useRef, useState } from "react";
import Nav from "../../Nav";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CreateWorksheetModal from "./CreateWorksheetModal/CreateWorksheetModal";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { getMyProfile } from "../../../store/actions/auth";
import Api from "../../../ApiRequests";
import { IconButton } from "@material-ui/core";
import { Grid, Paper, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SwipeableEdgeDrawer from "./CreateWorksheetModal/Drawer";
import { ArrowBack } from "@material-ui/icons";
import { ArrowBackIos } from "@mui/icons-material";
import EditWorksheetModal from "./CreateWorksheetModal/EditWorksheetModal";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

const Worksheet = ({
  status,
  copiedWorksheet,
  setCopiedWorksheet,
  created,
  setCreated,
}) => {
  // console.log(copiedWorksheet)
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [edited, setEdited] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);
  const [mySheets, setmySheets] = useState([]);
  const [loading, setLoading] = useState(0);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [length, setlength] = useState(Array(5).fill(0));
  const ref = useRef(null);
  const loadData = () => {
    Api.getWorksheet({ limit: 5, offset: count, status: status }).then(
      (res) => {
        setmySheets([
          ...mySheets,
          ...res.data.body.results?.map((result) => {
            result.open = false;
            return result;
          }),
        ]);
        setCount(count + res.data.body.results.length);
        setTotal(res.data.body.count);
        setlength(
         Array( Math.min(
            res.data.body.count - count - res.data.body.results.length,
            5
          )).fill(0)
        );
        setLoading(2);
        // ref.current.scrollLeft=width
      }
    );
  };
  useEffect(() => {
    // console.log("UseEffect", created, copiedWorksheet, edited, deleted);
    let isMounted = true;
    if (deleted && status === "draft" && isMounted) {
      setmySheets(mySheets.filter((sheet) => sheet.id !== deleted));
      setDeleted(null);
      setTotal(total - 1);
      setCount(count - 1);
      toast.success(`Worksheet Deleted`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (copiedWorksheet && status === "draft" && isMounted) {
      setmySheets([copiedWorksheet, ...mySheets]);
      setCopiedWorksheet(null);
      toast.success(`Worksheet Generated`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (created && status === "draft" && isMounted) {
      // console.log(created);
      setmySheets([created, ...mySheets]);
      setCreated(null);
      toast.success(`Worksheet Created`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (edited && status === "draft" && isMounted) {
      setmySheets(
        mySheets.map((sheet) => {
          if (sheet.id === edited.id) sheet = edited;
          return sheet;
        })
      );
      setEdited(null);
      toast.success(`Worksheet Edited`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return () => {
      isMounted = false;
    };
  }, [copiedWorksheet, created, deleted, edited]);
  useEffect(() => {
    if (mySheets.length === 0 && loading === 0) {
      setLoading(1);
      loadData();
    }
  }, [mySheets.length]);
  useEffect(() => {
    if (loading === 3) {
      ref.current.scrollLeft += ref.current.clientWidth;
      loadData();
    }
  }, [loading]);
  const handleClick = (right) => {
    const { scrollLeft, scrollWidth, clientWidth } = ref.current;
    setLoading(1);
    // console.log({ scrollLeft, scrollWidth, clientWidth }, "clicked");
    if (
      count < total &&
      right &&
      ref.current.clientWidth + ref.current.scrollLeft >
        ref.current.scrollWidth - 30
    ) {
      console.log("set");
      setLoading(3);
    } else {
      if (right) ref.current.scrollLeft += ref.current.offsetWidth;
      else ref.current.scrollLeft += -ref.current.offsetWidth;
      setLoading(2);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (idx) => {
    setmySheets(
      mySheets.map((sheet, index) => {
        if (idx === index) sheet.open = !sheet.open;
        return sheet;
      })
    );
  };

  const isopen = Boolean(anchorEl);
  const id = isopen ? "simple-popover" : undefined;

  return (
    <div className={styles.slider}>
      {mySheets.length > 0 && (
        <ArrowBackIos
          onClick={() => handleClick(false)}
          style={{ cursor: "pointer", zIndex: "1" }}
        />
      )}
      <div
        className={styles.container}
        ref={ref}
        style={{ width: "100%", display: "-webkit-inline-box" }}
      >
        {mySheets?.map(
          (
            {
              id,
              title,
              notesBy,
              phoneNumber,
              email,
              website,
              type,
              open,
              image,
              fullname,
              status,
              profile,
            },
            index
          ) => (
            <div className={styles.outerBox} id={index}>
              <div className={styles.box} id={id}>
                <div>
                  <div className="header">
                    <p
                      className="header-title"
                      style={{
                        fontSize: "90%",
                        lineHeight: "100%",
                        margin: "3%",
                        position: "relative",
                        overflowWrap: "break-word",
                      }}
                    >
                      {currentUser?.institution}
                    </p>

                    <p
                      className="header-sous-title"
                      style={{
                        fontSize: "80%",
                        lineHeight: "100%",
                        margin: "3%",
                        position: "relative",
                        overflowWrap: "break-word",
                      }}
                    >
                      Tutor : {notesBy}
                    </p>
                    <p
                      className="header-text"
                      style={{
                        fontSize: "55%",
                        lineHeight: "100%",
                        margin: "3%",
                        position: "relative",
                        overflowWrap: "break-word",
                      }}
                    >
                      Cell : {phoneNumber} | Email :{email}| Website : {website}{" "}
                    </p>
                    <p
                      className="header-text"
                      style={{
                        fontSize: "75%",
                        lineHeight: "100%",
                        margin: "3%",
                        position: "relative",
                        overflowWrap: "break-word",
                      }}
                    >
                      {title.length > 10 ? `${title.substring(0,10)}...` : title}
                    </p>
                  </div>
                  <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                <div className={styles.footer}></div>
                <div className={styles.page}>Page no: 01</div>
                <SwipeableEdgeDrawer
                  id={id}
                  open={open}
                  handleOpen={handleOpen}
                  index={index}
                  props={{
                    id,
                    title,
                    notesBy,
                    phoneNumber,
                    email,
                    website,
                    type,
                    open,
                    image,
                    fullname,
                    status,
                    profile,
                  }}
                  setEdited={setEdited}
                  setDeleted={setDeleted}
                  setCopiedWorksheet={setCopiedWorksheet}
                />
              </div>
              <div className={styles.meta}>
                {title.length > 10 ? `${title.substring(0,10)}...` : title}
                <MoreVertIcon
                  onClick={() => {
                    handleOpen(index);
                  }}
                />
              </div>
            </div>
          )
        )}
        {/* {console.log(loading)} */}
        {(loading === 1||loading===3) &&
          length
            .map((i, idx) => (
              <div className={styles.outerBox}>
                <Skeleton variant="rectangular" className={styles.skeleton} />
              </div>
            ))}
        {/* {((loading === 1 && mySheets.length !== 0) || count < total) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress />
          </div>
        )} */}
        {mySheets.length === 0 && loading === 2 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h2>
              {" "}
              {status === "draft" ? "Draft" : "Published"} Worksheets will
              appear here
            </h2>
          </div>
        )}
      </div>
      {mySheets.length > 0 && (
        <ArrowForwardIosIcon
          onClick={() => handleClick(true)}
          style={{ cursor: "pointer", zIndex: "1" }}
        />
      )}
    </div>
  );
};

export default Worksheet;
