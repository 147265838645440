import axios from 'axios';
const baseURL = 'https://doceditorbackend.thriftychats.com/'
// const baseURL=`http://localhost:3000/`
const axiosInstance =(endpoint,method,data,contentType)=> axios.request({
  baseURL: baseURL+endpoint,
  method,
  data,
  headers: {
    Authorization: localStorage.getItem('user.access')
      ? 'Bearer ' + localStorage.getItem('user.access')
      : null,
    'Content-Type': contentType??'application/json',
    accept: 'application/json'
  }
});
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    console.log(error.response)
    if (typeof error.response === 'undefined') {
      alert(
        'A server/network error occurred. ' +
          'Looks like Network connection is slow' +
          'Try Again'
      );
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      originalRequest.url === baseURL + 'api/auth/refresh/'
    ) {
      // dispatch(logout())

      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      error.response.statusText === 'Unauthorized'
    ) {
      const refreshToken = localStorage.getItem('refresh');

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);
        console.log(tokenParts.exp);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('api/token/', { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem('access', response.data.access);
              localStorage.setItem('refresh', response.data.refresh);

              axiosInstance.defaults.headers['Authorization'] =
                'JWT ' + response.data.access;
              originalRequest.headers['Authorization'] =
                'JWT ' + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log('Refresh token is expired', tokenParts.exp, now);
          localStorage.clear()
          window.location.href = '/login';
        }
      } else {

        console.log('Refresh token not available.');
        localStorage.clear()
        window.location.href = '/login';
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);
export default axiosInstance;
