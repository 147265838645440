//For texteditor

const initTxtSate = { editorHtml: "", formulaBox: false };

export const TextEditorReducer =(state=initTxtSate, {type, payload})=>{
  switch(type){
    case "change":
    return {...state, editorHtml: payload }
    case "showFormulaBox":
    return {...state, formulaBox:!state.formulaBox}
    case "clear_Txt_Editor":
    return {...state, editorHtml:""}
    default :
    return state
  }
}
