const initState = {

    isAuthenticated: localStorage.getItem("user.access")?true:false,
    currentUser: null,
    loading: localStorage.getItem("user.access")?false:true,
    apiError: '',
    errorMessage: null,
    successMessage:null,
    load:false,
    email:"",
    password:""

 }

  const authReducer = (state = initState, action) =>{
     // console.log(action)
     switch (action.type) {
         case "SIGN_IN" :
             return {...state, currentUser : action.payload,isAuthenticated:true}
         case "ERROR":
             return {...state,errorMessage:action.payload}
        case "SUCCESS":
            return {...state,successMessage:action.payload}
         case "LOAD_USER":
             return {...state,currentUser:action.payload}
         case "LOGOUT":
             return {...state,currentUser:null,isAuthenticated:false}
        case "LOADING":
            return {...state,load:true}
        case "LOADED":
            return {...state,load:false}
        case "OTP_EMAIL":
            return {...state,email:action.payload.email,password:action.payload.password}
        default:
             return state;

     }
 }

 export default authReducer;
