import axiosInstance from "./AxiosConfig";

const Api = {
    signUp:(values)=>{
       return axiosInstance("auth/register", "POST", values);
    },
    otpVerification:(OTP)=>{
        return axiosInstance("auth/otp-verification","POST",OTP)
    },
    login:(values)=>{
        return axiosInstance("auth/login","POST",values)
    },

    getMyProfile:()=>{
        return axiosInstance("auth/profile","GET")
    },
    getWorksheet:({limit,offset,status})=>{
        return axiosInstance(`worksheet?limit=${limit}&offset=${offset}&status=${status}`,"GET")
    },
    uploadWorksheet:()=>{
        return axiosInstance("worksheet","PUT")
    },
    getImages:({limit,offset,type})=>{
        return axiosInstance(`image/getImage?limit=${limit}&offset=${offset}&type=${type}`,"GET")
    },
    uploadImage:(file)=>{
        return axiosInstance(`image/upload-image?type`,"POST",file,"multipart/form-data")
    },
    createWorksheet:(file)=>{
        return axiosInstance(`worksheet`,"POST",file)
    },
    updateProfile:(file)=>{
        return axiosInstance(`auth/profile`,"PUT",file,"multipart/form-data")
    },
    changePassword:(file)=>{
        return axiosInstance(`auth/change-account-password`,"PUT",file)
    },
    resetPassword:(email)=>{
        return axiosInstance(`auth/reset-account-password`,"PUT",email)
    },
    editWorksheet:(files)=>{
        return axiosInstance(`worksheet`,"PUT",files)
    },
    deleteSheet:(id)=>{
        return axiosInstance(`worksheet/${id}`,"DELETE")
    },
    getWorksheetById:(id)=>{
        return axiosInstance(`worksheet/getworksheetdatabyid/${id}`,"GET")
    },
    publishWorksheet:(id,htmlContent)=>{
        return axiosInstance(`worksheet/publishWorksheet/${id}`,"POST",htmlContent)
    },
    generateCopy:(id)=>{
        return axiosInstance(`worksheet/${id}`,"POST")
    },
    resetChangePassword:(values)=>{
        return axiosInstance(`auth/resetpassword-changepassword`,"PUT",values)
    },
    createTemplate:(values)=>{
        return axiosInstance(`template/createupdate-template`,"POST",values)
    },
    CategoryContainer:(type)=>{
      return axiosInstance(`category/getcategoryofcontainer?type=${type}`, "GET")
    },
    createCategoryContainer:(payload)=>{
      return axiosInstance(`category/createcategory`, "POSt", payload)
    },
    getIcon:(type, searchPhrase)=>{
      console.log(searchPhrase);
      return axiosInstance(`image/geticonlibrary?type=${type}${searchPhrase ? `&searchPhrase=${searchPhrase}` : ""}`, "GET")
    },
    uploadIcon:(payload)=>{
      return axiosInstance(`image/upload-icon`, "POST", payload)
    },
    uploadImage:(payload)=>{
      return axiosInstance(`image/upload-image`, "POST", payload)
    },
    getImage:(searchPhrase)=>{
      return axiosInstance(`image/getimage${searchPhrase ? `?searchPhrase=${searchPhrase}` : ""}`, "GET")
    },
    updateWorksheet:(id, payload)=>{
      return axiosInstance(`worksheet/${id}`, "PUT", payload)
    },
    getTemplates:(type, id)=>{
      return axiosInstance(`template/gettemplate?type=${type}${id === undefined ? '' : `&category_id=${id}`}`, "GET")
    },
    getTemplateData:( id)=>{
      return axiosInstance(`template/gettemplatebyid/${id}`, "GET")
    }
}

export default Api
