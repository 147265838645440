import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Avatar } from '@mui/material';
import "./Navbar.scss";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
export default function TemporaryDrawer({state,setState,user_data}) {
  const history = useHistory()
  const dispatch =useDispatch()
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
          <ListItem button >
          <Avatar
            sx={{ width: 56, height: 56 }}
            style={{ border: "2px solid #007AFF",margin:"1%" }}
            src={user_data?.avatar}
          >
            {user_data?.fullname[0]}
          </Avatar>
          <div>
            <div style={{fontSize:"small"}}>{user_data?.fullname}</div>
            <div style={{fontSize:"xx-small"}}>{user_data?.email}</div>
          </div>
          </ListItem>
      </List>
      <Divider />
      <List>
          <ListItem button onClick={()=>history.push('/settings/0')}>
           My Profile
          </ListItem>
          <ListItem button onClick={()=>history.push('/settings/1')}>
           Change Password
          </ListItem>
          <ListItem button onClick={()=>history.push('/settings/2')}>
           Delete Account
          </ListItem>
          <ListItem button onClick={()=>{localStorage.clear();
        dispatch({ type: "LOGOUT" });
        history.push("/login");
        }}>
           Logout
          </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className="tempDrawer"
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
