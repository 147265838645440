import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ffa737",
    },
    secondary: {
      main: "#007aff",
    },
    background: {
      paper: "#ffffff",
      default: "#e5e5e5",
    },
  },
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontSize: 24,
      fontWeight: 500,
    },
    h2: {
      fontSize: 14,
      fontWeight: 600,
    },
    h3: {
      fontSize: 14,
      fontWeight: 500,
    },
    h4: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
    button: {
      fontSize: 12,
      fontWeight: 600,
    },
  },
});
