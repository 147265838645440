import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {Grid,Box} from "@material-ui/core"
import Button from '@mui/material/Button';
//redux
import { setStyle } from "../../store/actions/containerActions";
import IconsTab from "../iconsTab/IconsTab";

import "./style.scss"

//icons
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

export const BorderStyle = ({
  page = null,
  type,
  container = null,
  box = null,
  isNewContainer = false,
  onBorderChage,
}) => {
  const dispatch = useDispatch();
  const myCanvas1 = useRef();
  const myCanvas2 = useRef();

  useEffect(() => {
    NoneCircle(myCanvas1.current);
    NoneCircle(myCanvas2.current);
  }, []);

  const NoneCircle = (myCanvas) => {
    myCanvas.width = 50;
    myCanvas.height = 50;
    const ctx = myCanvas.getContext("2d");

    ctx.beginPath();
    ctx.arc(25, 25, 25, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.moveTo(0, 0);
    ctx.lineTo(50, 50);
    ctx.stroke();
  };

  const handleBorderStyle = (key, value) => {
    if (isNewContainer) {
      onBorderChage(key, value);
    } else {
      dispatch(setStyle(page, container, box, key, value));
    }
  };

  return (
    <div>
      <Box className="head">
        <h4>Border Style</h4>
      </Box>

      <Box style={{padding:"0rem 1rem"}}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            className="display-flex justify-content-center align-items-center"
          >
            <Button
              className="txt-nrm brd-btn"
              variant={page?.style?.borderStyle === "none" || container?.borderStyle === "none" || box?.borderStyle === "none" ? "contained" : "outlined"}
              onClick={() => handleBorderStyle("borderStyle", "none")}
            >
              No Border
            </Button>
          </Grid>

          <Grid
            item
            xs={6}
            className="display-flex justify-content-center align-items-center"
          >
            <Button
              className="txt-nrm brd-btn"
              variant={page?.style?.borderStyle === "dotted" || container?.borderStyle === "dotted" || box?.borderStyle === "dotted" ? "contained" : "outlined"}
              onClick={() => handleBorderStyle("borderStyle", "dotted")}
              startIcon={<MoreHorizOutlinedIcon/> }
            >
              Dotted
            </Button>
          </Grid>

          <Grid
            item
            xs={6}
            className="display-flex justify-content-center align-items-center"
          >
            <Button
              className="txt-nrm brd-btn"
              variant={page?.style?.borderStyle === "double" || container?.borderStyle === "double" || box?.borderStyle === "double" ? "contained" : "outlined"}
              onClick={() => handleBorderStyle("borderStyle", "double")}
              startIcon={<DragHandleIcon/> }
            >
              Two Line
            </Button>
          </Grid>

          <Grid
            item
            xs={6}
            className="display-flex justify-content-center align-items-center"
          >
            <Button
              className="txt-nrm brd-btn"
              variant={page?.style?.borderStyle === "solid" || container?.borderStyle === "solid" || box?.borderStyle === "solid" ? "contained" : "outlined"}
              onClick={() => handleBorderStyle("borderStyle", "solid")}
              startIcon={<HorizontalRuleIcon/> }
            >
              One Line
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box className="head">
        <h4>Border Color</h4>
      </Box>

      <Box style={{ padding: "0rem 1rem"}}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <canvas
            ref={myCanvas1}
            style={{ borderColor: "none", width: 50, height: 50 }}
            className={page?.style?.borderColor === "transparent" || container?.borderColor === "transparent" || box?.borderColor === "transparent" ? "circle brd-shadow" : "circle"}
            onClick={() => handleBorderStyle("borderColor", "transparent")}
          ></canvas>
          <span
            style={{ borderColor: "gray" }}
            className={page?.style?.borderColor === "gray" || container?.borderColor === "gray" || box?.borderColor === "gray" ? "circle brd-shadow" : "circle"}
            onClick={() => handleBorderStyle("borderColor", "gray")}
          ></span>
          <span
            style={{ borderColor: "black" }}
            className={page?.style?.borderColor === "black" || container?.borderColor === "black" || box?.borderColor === "black" ? "circle brd-shadow" : "circle"}
            onClick={() => handleBorderStyle("borderColor", "black")}
          ></span>
          <span
            style={{ borderColor: "blue" }}
            className={page?.style?.borderColor === "blue" || container?.borderColor === "blue" || box?.borderColor === "blue" ? "circle brd-shadow" : "circle"}
            onClick={() => handleBorderStyle("borderColor", "blue")}
          ></span>
          <span
            style={{ borderColor: "orange" }}
            className={page?.style?.borderColor === "orange" || container?.borderColor === "orange" || box?.borderColor === "orange" ? "circle brd-shadow" : "circle"}
            onClick={() => handleBorderStyle("borderColor", "orange")}
          ></span>
        </div>
      </Box>

      <Box className="head">
        <h4>Background Color</h4>
      </Box>

      <Box style={{padding: "0rem 1rem 2rem"}} className="brd-botm">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <canvas
            ref={myCanvas2}
            style={{ backgroundColor: "none", width: 50, height: 50 }}
            className={page?.style?.backgroundColor === "transparent" || container?.backgroundColor === "transparent" || box?.backgroundColor === "transparent" ? "circle brd-shadow" : "circle"}
            onClick={() => handleBorderStyle("backgroundColor", "transparent")}
          ></canvas>
          <span
            style={{ backgroundColor: "gray" }}
            className={page?.style?.backgroundColor === "gray" || container?.backgroundColor === "gray" || box?.backgroundColor === "gray" ? "circle brd-shadow" : "circle"}
            onClick={() => handleBorderStyle("backgroundColor", "gray")}
          ></span>
          <span
            style={{ backgroundColor: "black" }}
            className={page?.style?.backgroundColor === "black" || container?.backgroundColor === "black" || box?.backgroundColor === "black" ? "circle brd-shadow" : "circle"}
            onClick={() => handleBorderStyle("backgroundColor", "black")}
          ></span>
          <span
            style={{ backgroundColor: "blue" }}
            className={page?.style?.backgroundColor === "BLUE" || container?.backgroundColor === "BLUE" || box?.backgroundColor === "BLUE" ? "circle brd-shadow" : "circle"}
            onClick={() => handleBorderStyle("backgroundColor", "BLUE")}
          ></span>
          <span
            style={{ backgroundColor: "orange" }}
            className={page?.style?.backgroundColor === "orange" || container?.backgroundColor === "orange" || box?.backgroundColor === "orange" ? "circle brd-shadow" : "circle"}
            onClick={() =>
              dispatch(handleBorderStyle("backgroundColor", "orange"))
            }
          ></span>
        </div>
      </Box>

      <Box className="head">
        <h4>Icons</h4>
      </Box>

      <Box style={{padding:" 0rem 1rem"}}>
        <IconsTab page={page} container={container} box={box} />
      </Box>
    </div>
  );
};
