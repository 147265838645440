import Box from "@mui/material/Box";
import { theme } from "../../../Theme";
import { ThemeProvider } from "@mui/material/styles";

const MainLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          background: "background.paper",
          overflow:"auto"
        }}
      >
        {children}
      </Box>
    </ThemeProvider>
  );
};

export default MainLayout;
