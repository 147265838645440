import React, { useEffect, useState } from "react";
import { Button, IconButton, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedContainer,
  createContainerObj,
} from "../../utils/container";
import { getSelectedBox } from "../../utils/box";
import Select from "react-select";
import MenuList from "../Menu/Menu"
import "./Sideright.scss";
import Skeleton from '@mui/material/Skeleton';
//icons
import MoreIcon from "@material-ui/icons/MoreVert";

//redux
import { addBox } from "../../store/actions/containerActions";
import { setActivetab } from "../../store/actions/pageActions";

//tabs
import PageFormTab from "../pageformtab/PageFormTab";
import ContainerTab from "../containertab/ContainerTab";
import UploadsFormTab from "../uploadsformtab/UploadsFormTab";
import BoxTab from "../BoxTab/BoxTab"
import BoxTypeTab from "../BoxTypeTab/BoxTypeTab"

const Sideright = () => {
  const [anchorEl,setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const btnBgColor = "orange";
  const [newContainer, setNewContainer] = useState({});
  const pages = useSelector((state) => state.page.pages);
  const isAddBtnClicked = useSelector(
    (state) => state.container.isAddBtnClicked
  );
  const [selectedPage, setSelectedPage] = useState({});

  const activetab = useSelector((state) => state.page.activeTab);
  const pageContentHeight = selectedPage ? selectedPage.pageContentHeight : 885;

  const dispatch = useDispatch();

  const containers = useSelector((state) => state.container.containers);

  const [selectedContainer, setSelectedContainer] = useState({});

  const [selectedBox, setSelectedBox] = useState({});

  const [enableAddBox, setEnableAddBox] = useState(false);
  const loadingData =  useSelector((state)=> state.worksheet.load)
  useEffect(() => {
    const selectedContainer = getSelectedContainer(containers);

    if (selectedContainer.boxes) {
      const height = selectedContainer.boxes.reduce((max, box) => {
        if (box.y) {
          if (max < box.h + box.y) {
            max = box.h + box.y;
          } else {
            if (max < box.h) max = box.h;
          }
        }
        return max;
      }, 0);

      setEnableAddBox(height + 3 >= pageContentHeight / (120 / 3));
    }

    setSelectedContainer(selectedContainer);

    const selectedBox = getSelectedBox(containers);

    if (selectedBox) {
      setSelectedBox(selectedBox);
    }

    const selectedPage = pages.filter((page) => page.selected === true)[0];
    setSelectedPage(selectedPage);
  }, [containers, pages]);

  useEffect(() => {
    const newContainer = createContainerObj();
    newContainer.index = containers.length - 1;
    setSelectedContainer(newContainer);
  }, [isAddBtnClicked]);

  return (
    <Paper className="sideright">
      { loadingData ?  <Skeleton variant="rectangular" animation="wave" style={{height: "100vh"}}/>
      : <div>
        <Paper square className="tabs" elevation={0}>
          <Button
            size="small"
            className="tab"
            onClick={() => dispatch(setActivetab("page"))}
            style={{
        color: `${
        activetab === "page" ? "#007AFF" : "#00000066"
        }`,
            }}
          >
            Page
          </Button>
          <Button
            size="small"
            className="tab"
            disabled={selectedBox.selected}
            onClick={() => dispatch(setActivetab("container"))}
            style={{
        color: `${
        activetab === "container" ? "#007AFF" : "#00000066"
        }`,
            }}
          >
            Container
          </Button>
          <Button
            size="small"
            className="tab"
            onClick={() => dispatch(setActivetab("box"))}
            style={{
        color: `${
        activetab === "box" ? "#007AFF" : "#00000066"
        }`,
            }}
          >
            Box
          </Button>

          <Button
            size="small"
            disabled={selectedBox.selected}
            className="tab"
            onClick={() => dispatch(setActivetab("uploads"))}
            style={{
        color: `${
        activetab === "uploads" ? "#007AFF" : "#00000066"
        }`,
            }}
          >
            Uploads
          </Button>

          <IconButton size="small" style={{ flex: 1 }}>
            <MoreIcon onClick={handleClick}/>
          </IconButton>

        </Paper>

        {activetab === "container" && (
          <ContainerTab
            selectedPage={selectedPage}
            container={selectedContainer ?? newContainer}
          />
        )}
        {activetab === "box" && selectedContainer.height && (
          <BoxTab
            selectedPage={selectedPage}
            selectedContainer={selectedContainer}
            enableAddBox={enableAddBox}
          />
        )}

        {activetab === "box" && selectedBox.selected && (
          <BoxTypeTab selectedPage={selectedPage} selectedBox={selectedBox} />
        )}

        {activetab === "page" && <PageFormTab selectedPage={selectedPage} />}
        {activetab === "uploads" && (
          <UploadsFormTab selectedPage={selectedPage} />
        )}
      </div>}
      <MenuList anchorEl={anchorEl} setAnchorEl={setAnchorEl}/>
        </Paper>
        );
        };


export default Sideright;
