import Dialog from '@mui/material/Dialog';
import styles from "./styles.module.scss";

export const Terms = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
    window.history.back()
  }

  return (
    <Dialog
      open={open}
      maxWidth={'lg'}
      onClose={handleClose}
      PaperProps={{ classes: {root: styles.root} }}
    >
      <div className={styles.modal}>
        <h2 className={styles.heading}>Terms and Conditions</h2>
        <div className={styles.underline}></div>
        <h3>Accepting Terms</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate mauris scelerisque quis ultrices ut urna, eu nunc. Nulla risus, id cras vestibulum. Condimentum nec cras pulvinar tempor sit tristique lacinia ac augue. Vivamus sed tellus adipiscing risus, quam ultricies sit. Arcu sapien odio orci tristique. Ridiculus commodo vel nullam purus augue.
        </p>
        <p>
          Faucibus egestas platea nisi, lorem posuere massa rhoncus vestibulum nec. Viverra vestibulum, sociis et, et est sed porta odio nunc. Quis sem habitant sit pellentesque sed luctus pellentesque est. Vitae sem fringilla amet odio. At viverra egestas vitae quis dictumst urna aenean ut blandit. Viverra mattis sed eu senectus enim et pretium aliquet. Sed at auctor pellentesque blandit sed maecenas ridiculus. Sed quam quis et, natoque. Sed mauris morbi ut ultrices. At fermentum, ac tellus mattis purus. Aliquam lorem nibh id vestibulum mauris.
        </p>
        <p>
          Malesuada tellus odio sit proin eu gravida. Convallis viverra fermentum sagittis, sem varius eu elit lacus, placerat. Volutpat sit at tempor malesuada sem tellus. Sed metus ultricies nisl est bibendum placerat et. Venenatis suscipit viverra scelerisque vitae amet, ultricies sit. Proin risus eleifend vitae, mollis orci arcu malesuada condimentum. Semper id laoreet cursus elit. Enim, nisi, enim non ullamcorper et adipiscing aliquam. Commodo nisi mi quis tristique. Sapien nulla cursus feugiat etiam fringilla. Odio consectetur nec diam cras eget varius in vel nec. Ut metus sem massa, senectus duis neque. Elit congue massa pretium consectetur bibendum consectetur magnis duis. Tristique luctus proin pulvinar euismod blandit tempus, scelerisque a.
        </p>
        <p>
          Vivamus morbi et velit est cursus in quis. At adipiscing gravida suspendisse praesent purus. Ac tortor dictumst sed sed et viverra vulputate. Viverra est magna duis sed amet fermentum molestie diam. Iaculis consectetur urna tellus, nullam sed justo elit. Nulla amet ornare quam dictum nibh egestas proin dui. Auctor viverra est, nec aliquam, consequat purus aenean id amet.
        </p>
        <p>
          Magna eu magna odio id tristique congue in cursus. Urna at orci egestas enim viverra condimentum cursus phasellus suspendisse. Enim eget platea eget et elementum. Proin quam et vitae vel est id. At ultrices ac aliquam massa, urna fermentum feugiat. Pellentesque lobortis ultrices imperdiet bibendum. Nunc arcu amet dictum ornare lectus augue neque, risus. Purus massa a nibh vestibulum, fermentum faucibus in. Ac in elementum pretium mi magna morbi augue.
        </p>
      </div>
    </Dialog>
  );
}

export const Privacy = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
    window.history.back()
  }

  return (
    <Dialog 
      open={open}
      maxWidth={'lg'}
      onClose={handleClose} 
      PaperProps={{ classes: {root: styles.root} }}
    >
      <div className={styles.modal}>
        <h2 className={styles.heading}>Privacy Policy</h2>
        <div className={styles.underline}></div>
        <h3>Accepting Terms</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate mauris scelerisque quis ultrices ut urna, eu nunc. Nulla risus, id cras vestibulum. Condimentum nec cras pulvinar tempor sit tristique lacinia ac augue. Vivamus sed tellus adipiscing risus, quam ultricies sit. Arcu sapien odio orci tristique. Ridiculus commodo vel nullam purus augue.
        </p>
        <p>
          Faucibus egestas platea nisi, lorem posuere massa rhoncus vestibulum nec. Viverra vestibulum, sociis et, et est sed porta odio nunc. Quis sem habitant sit pellentesque sed luctus pellentesque est. Vitae sem fringilla amet odio. At viverra egestas vitae quis dictumst urna aenean ut blandit. Viverra mattis sed eu senectus enim et pretium aliquet. Sed at auctor pellentesque blandit sed maecenas ridiculus. Sed quam quis et, natoque. Sed mauris morbi ut ultrices. At fermentum, ac tellus mattis purus. Aliquam lorem nibh id vestibulum mauris.
        </p>
        <p>
          Malesuada tellus odio sit proin eu gravida. Convallis viverra fermentum sagittis, sem varius eu elit lacus, placerat. Volutpat sit at tempor malesuada sem tellus. Sed metus ultricies nisl est bibendum placerat et. Venenatis suscipit viverra scelerisque vitae amet, ultricies sit. Proin risus eleifend vitae, mollis orci arcu malesuada condimentum. Semper id laoreet cursus elit. Enim, nisi, enim non ullamcorper et adipiscing aliquam. Commodo nisi mi quis tristique. Sapien nulla cursus feugiat etiam fringilla. Odio consectetur nec diam cras eget varius in vel nec. Ut metus sem massa, senectus duis neque. Elit congue massa pretium consectetur bibendum consectetur magnis duis. Tristique luctus proin pulvinar euismod blandit tempus, scelerisque a.
        </p>
        <p>
          Vivamus morbi et velit est cursus in quis. At adipiscing gravida suspendisse praesent purus. Ac tortor dictumst sed sed et viverra vulputate. Viverra est magna duis sed amet fermentum molestie diam. Iaculis consectetur urna tellus, nullam sed justo elit. Nulla amet ornare quam dictum nibh egestas proin dui. Auctor viverra est, nec aliquam, consequat purus aenean id amet.
        </p>
        <p>
          Magna eu magna odio id tristique congue in cursus. Urna at orci egestas enim viverra condimentum cursus phasellus suspendisse. Enim eget platea eget et elementum. Proin quam et vitae vel est id. At ultrices ac aliquam massa, urna fermentum feugiat. Pellentesque lobortis ultrices imperdiet bibendum. Nunc arcu amet dictum ornare lectus augue neque, risus. Purus massa a nibh vestibulum, fermentum faucibus in. Ac in elementum pretium mi magna morbi augue.
        </p>
      </div>
    </Dialog>
  );
}