import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.scss";
import Api from "../../../../ApiRequests";
import { Delete } from "@material-ui/icons";

const DeleteWorksheetModal = ({
  open,
  setOpen,
  deleteWorksheet,
  id,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth="sm" onClose={handleClose} open={open} fullWidth={true}>
      <div className={styles.modal}>
        <h3 className={styles.heading} style={{ display: "flex" }}>
          <Delete />
          {"  "}Delete Worksheet
        </h3>
        <div className={styles.underline}></div>
        <div style={{ margin: "1rem 0rem" }}>
          Are You sure want to delete the worksheet?
        </div>
        <div className={styles.btns}>
          {/* {   console.log(isValid,dirty,values)} */}
          <button
            type="submit"
            style={{ background: " #FFA737" }}
            className={styles.btn2}
            variant="contained"
            onClick={() => {
              deleteWorksheet(id);
              handleClose();
            }}
          >
            Delete
          </button>
          <button type="reset" className={styles.btn1} onClick={handleClose}>
            Cancel
          </button>
        </div>
        <div></div>
      </div>
    </Dialog>
  );
};

export default DeleteWorksheetModal;
