import React from "react";
import { Route } from "react-router-dom";
import MainLayout from "../MainLayout";
export default function PublicPages({ route }) {
  const { component: Component, routes } = route;
  return (
    <Route
      exact
      path={route.path}
      render={(props) => (
        <MainLayout>
          <Component reactRouterProps={props} routes={routes} />
        </MainLayout>
      )}
    />
  );
}
