import { Route } from "react-router-dom";
import NotFound from "../NotFound";
import ProtectedPages from "../ProtectedPages";
import PublicPages from "../PublicPages";

const CustomRoute = (route) => {
  let body = null;
  if (route.isProtected) {
    body = <ProtectedPages route={route} />;
  } else if (route.isValid) {
    body = <PublicPages route={route} />;
  } else {
    body = (
      <Route>
        <NotFound />
      </Route>
    );
  }
  return <>{body}</>;
};
export default CustomRoute;
