const initState = {
  global_templates: [],
  isAddBtnClicked: 1,
  containers: [
    {
      header: true,
      height: 135,
      index: 0,
      name: "header",
      boxes: [],
      id: "container-with-header",
      borderHeight: 2
    },
    {
      hasAddButton: true,
      height: 120,
      index: 1,
      id: "container-with-button-id",
      borderHeight: 2
    },
  ],
};

const containerReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_OR_UPDATE_CONTAINER":
      return { ...state, containers: [...action.payload] };

    case "SET_BOXES":
      return { ...state, containers: [...action.payload] };

    case "SET_CONTAINER_HEIGHT":
      return { ...state, containers: [...action.payload] };

    case "SORT_CONTAINERS":
      return { ...state, containers: [...action.payload] };

    case "SET_TABLE_CEIL_TEXT":
      return { ...state, containers: [...action.payload] };
    case "SET_BOX_SIZE":
      return { ...state, containers: [...action.payload] };
    case "SELECT_CONTAINER":
      return { ...state, containers: [...action.payload] };

    case "ORDER_CONTAINERS":
      return { ...state, containers: [...action.payload] };

    case "SET_BOX":
      return { ...state, containers: [...action.payload] };

    case "ADD_BOX":
      return { ...state, containers: [...action.payload] };

    case "SELECT_BOX":
      return { ...state, containers: [...action.payload] };

    case "SET_BOX_TYPE":
      return { ...state, containers: [...action.payload] };

    case "SET_STYLE":
      return { ...state, containers: [...action.payload] };

    case "DELETE_CONTAINER":
      return { ...state, containers: [...action.payload] };

    case "DELETE_BOX":
      return { ...state, containers: [...action.payload] };

    case "SET_CONTAINER_NAME":
      return { ...state, containers: [...action.payload] };

    case "SET_CONTAINER_CATEGORY":
      return { ...state, containers: [...action.payload] };

    case "SET_TABLE_NB_COLUMNS":
      return { ...state, containers: [...action.payload] };

    case "SET_TABLE_NB_ROWS":
      return { ...state, containers: [...action.payload] };

    case "SET_TABLE_NB_LINES":
      return { ...state, containers: [...action.payload] };
    case "SET_BOX_GRAPH":
      return { ...state, containers: [...action.payload] };
    case "SET_BOX_IMAGE":
      return { ...state, containers: [...action.payload] };
    case "SET_BOX_CAPTION":
      return { ...state, containers: [...action.payload] };
    case "SET_GLOBAL_CONTAINER_TEMPLATE":
      return { ...state, global_templates: [...action.payload] }
    case "SET_CONTAINER_TEMPLATE":
      return { ...state, containers: [...action.payload] };
    case "SET_IS_ADD_CONT_BTN_CLICKED":
      return { ...state, isAddBtnClicked: action.payload };
    case "LOAD_CONTAINER":
    return { ...action.payload }  
    default:
      return state;
  }
};

export default containerReducer;
