import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteBox,
  setNbLines,
  setBoxHeight,
  setNbLinesHeight,
  resetBoxType
} from "../../store/actions/containerActions";
import { BorderStyle } from "../borderstyle/BorderStyle";

//icons
import DeleteIcon from "@material-ui/icons/Delete";

import "./style.scss"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const EditLine = ({ selectedPage, box }) => {
  const dispatch = useDispatch();
  const height = 120;
  const [err, setErr] = useState();
  const pageContentHeight = selectedPage.pageContentHeight;


    // const handleChange = () =>{
    //   setTimeout(()=>{
    //     const rowHeight = height / 3;

    //     const contentHeight=  document.getElementById(box.id).offsetHeight
    //     if (box.h > pageContentHeight / rowHeight - 0.3) {
    //       //alert('error')
    //       return;
    //     }

    //     if (box.h * rowHeight < contentHeight) {
    //       document.getElementById(box.id).parentElement.parentElement.style.height =
    //         document.getElementById(box.id).offsetHeight + "px";
    //       dispatch(setBoxHeight(selectedPage, box, contentHeight / rowHeight));
    //     }
    //   } , 33)

    // }


  return (
    <div>

      <Box className="display-flex box-head brd-botm">

        <Button
          variant="outlined"
          onClick={()=>{
            dispatch(resetBoxType(box,false));
          }}
        >
          Reset
        </Button>

        {/* <Button
          variant="outlined"
          onClick={() => {
            dispatch(setBoxText(box, ""));
            dispatch(clearTxtEditor());
          }}
          >
          clear
        </Button> */}

        <Button
          variant="outlined"
          color="error"
          onClick={() => dispatch(deleteBox(box))}
        >
          {/* <DeleteIcon fontSize="small" /> */}
          Delete Box
        </Button>
      </Box>
      <Box className="head brd-botm" style={{paddingBottom: "0.2rem"}}>
        <h4>
          Box {box.index + 1} : {box.type}
        </h4>
      </Box>


      <Box className="brd-botm line-edit" style={{padding:"0rem 1rem 1rem "}}>

        <Box>
          <p>Number Of Lines</p>
          <div className="input">
            <input
              type="number"
              min={1}
              value={box.nbLines}
              onChange={(e) => {
                const h =  document.getElementById(box.id).offsetHeight
                if (
                  h+box.nbLinesHeight < box.h * 40 -3  ||
                  e.target.value < box.nbLines
                ) {
                  setErr("")
                  dispatch(setNbLines(box, parseInt(e.target.value)));
                } else {
                  setErr(
                    "*Please Increase the Height of Box to further increase the no. of lines"
                  );
                }
              }}
            />
          </div>

          <p>Line Height</p>
          <div className="input">
            <input
              type="number"
              min={1}
              value={box.nbLinesHeight}
              onChange={(e) => {
                const h =  document.getElementById(box.id).offsetHeight
                if (
                  h+box.nbLinesHeight < box.h * 40 -3 ||
                  e.target.value < box.nbLinesHeight
                ) {
                  setErr("")
                  dispatch(setNbLinesHeight(box, parseInt(e.target.value)));
                } else {
                  setErr(
                    "*Please Increase the Height of Box to further increase the space between lines"
                  );
                }
              }}
            />
          </div>

        </Box>

        <div style={{color:"red",fontSize:"x-small"}}>{err}</div>
        <div className="line-view">
          {Array(box.nbLines)
            .fill(null)
            .map((line, index) => {
              return <hr key={"hr-" + index} />;
            })}
        </div>
      </Box>

      <BorderStyle type="container" box={box} />
    </div>
  );
};
export default EditLine;
