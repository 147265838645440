import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { validationSchema } from "../../../Form/validation/createWorksheet";
import styles from "./styles.module.scss";
import Api from "../../../../ApiRequests";
import { Formik, Field, ErrorMessage, Form } from "formik";
import FormikController from "../../../Form/FormControl";
import { Button, Grid, IconButton, Skeleton } from "@mui/material";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { CloseOutlined, Search, SearchRounded } from "@material-ui/icons";
import { borderRadius } from "@mui/system";
import { toast } from "react-toastify";

const currencies = [
  {
    value: "Weekly",
    label: "Weekly",
  },
  {
    value: "Homework",
    label: "Homework",
  }
];
const profile = [
  { value: "Teacher", label: "Teacher" },
  { value: "Tutor", label: "Tutor" },
];
const EditWorksheetModal = ({ open, setOpen, setEdited, props }) => {
  const [currency, setCurrency] = useState("item0");
  const [text, setText] = useState("");
  const [breadcrumb, setBreadcrumb] = useState(1);
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [uploadedImgs, setUploadedImgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chosenImage, setChosenImage] = useState(null);
  // console.log(props)
  let initialValues = {
    title: props?.title,
    profile: props?.profile,
    fullname: props?.fullname,
    website: props?.website,
    phoneNumber: props?.phoneNumber,
    email: props?.email,
    notesBy: props?.notesBy,
    type: props?.type,
    image: props?.image,
  };

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const loadImages = () => {
    setLoading(true);
    Api.getImages({ limit: 12, offset: 0, type: "myown" }).then((res) => {
      setUploadedImgs(res.data.body.results);
      setLoading(false);
    });
    return;
  };
  useEffect(() => {
    console.log("Calling",open)
    if (uploadedImgs.length === 0&&open) loadImages();
    // console.log("called ")
  }, [56]);
  const handleImage = (e) => {
    // console.log("reached");
    setImage(e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]));
  };

  const uploadImage = () => {
    const form = new FormData();
    form.append("image", image);
    form.append("caption", "Logo");
    Api.uploadImage(form).then(() => {
      loadImages();
      setBreadcrumb(0);
    });
  };
  const chooseImage = (caption, id, src) => {
    setChosenImage({ caption, id, src });
    setBreadcrumb(2);
  };

  const cancelImage = () => {
    setChosenImage(null);
    setBreadcrumb(0);
  };
  const createWorksheet = (values) => {
    values = { ...values, logo: chosenImage.imageurl, id: props.id };
    Api.editWorksheet(values).then((res) => {
      setEdited(res.data.body);
      setBreadcrumb(1);
      setChosenImage(null);
      setOpen(false)
    })
    .catch((err)=>{
      err.response.data.body.map((each,index)=>{
        toast.error(`${each}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      })
    })
  };
  // console.log(props)
  return (
    <Dialog maxWidth="sm" onClose={handleClose} open={open} fullWidth={true}>
      <div className={styles.modal}>
        <h3 className={styles.heading}>
          <img src="/img/9.svg" alt="" /> Edit Worksheet
        </h3>
        <div className={styles.underline}></div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              createWorksheet(values);
            }}
          >
            {({ dirty, isValid, values, handleChange, handleBlur }) => (
              <Form>
                <div className={styles.details}>
                  <div className={styles.colomn}>
                    <div className={styles.inputbox}>
                      <label className={styles.label}>Project Title</label>
                      <FormikController
                        id="outlined-textarea"
                        //label="Project Title here..."
                        variant="outlined"
                        required
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                        name="title"
                        control="input"
                      />
                    </div>
                    <div className={styles.inputbox}>
                      <label className={styles.label}>Full Name</label>
                      <FormikController
                        id="outlined-textarea"
                        //label="Project Title here..."
                        variant="outlined"
                        required
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                        name="fullname"
                        control="input"
                      />
                    </div>

                    <div className={styles.inputbox}>
                      <label className={styles.label}>Profile</label>
                      <FormikController
                        name="profile"
                        id="outlined-textarea"
                        required
                        variant="outlined"
                        style={{ border: "1px solid #FFA737" }}
                        control="select"
                        className={styles.input}
                        options={profile}
                      />
                    </div>

                    <div className={styles.inputbox}>
                      <label className={styles.label}>Notes By</label>
                      <FormikController
                        name="notesBy"
                        id="outlined-textarea"
                        required
                        variant="outlined"
                        control="input"
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                      />
                    </div>
                  </div>

                  <div className={styles.colomn}>
                    <div className={styles.inputbox}>
                      <label className={styles.label}>Email</label>
                      <FormikController
                        name="email"
                        id="outlined-textarea"
                        required
                        type="email"
                        control="input"
                        variant="outlined"
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                      />
                    </div>

                    <div className={styles.inputbox}>
                      <label className={styles.label}>Phone Number</label>
                      <FormikController
                        name="phoneNumber"
                        id="outlined-textarea"
                        required
                        type="number"
                        variant="outlined"
                        control="input"
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                      />
                    </div>

                    <div className={styles.inputbox}>
                      <label className={styles.label}>Website</label>
                      <FormikController
                        name="website"
                        id="outlined-textarea"
                        required
                        variant="outlined"
                        style={{ border: "1px solid #FFA737" }}
                        control="input"
                        className={styles.input}
                      />
                    </div>

                    <div className={styles.inputbox}>
                      <label className={styles.label}>Worksheet Type</label>
                      <FormikController
                        name="type"
                        id="outlined-textarea"
                        required
                        style={{ border: "1px solid #FFA737" }}
                        control="select"
                        className={styles.input}
                        options={currencies}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.uploadSection}>
                  <h1>Select Logo</h1>
                  {breadcrumb === 2 ? (
                    <div
                      style={{
                        width: "30%",
                        cursor: "pointer",
                        border: "1px solid #FFA737",
                        borderRadius: "5px",
                        background: "#eae0e0",
                        padding: "2px",
                        position: "relative",
                      }}
                    >
                      <img
                        src={chosenImage.src}
                        alt="pic"
                        style={{ width: "100%" }}
                      ></img>
                      <IconButton
                        onClick={cancelImage}
                        size="small"
                        style={{ position: "absolute", left: 0 }}
                      >
                        <CloseOutlined
                          style={{
                            color: "rgb(119 119 131)",
                            border: " 1px solid",
                            borderRadius: "50%",
                            background: "#f1f1f1c4",
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </div>
                  ) : (
                    <div className={styles.uploadOptions}>
                      <p
                        className={breadcrumb === 0 ? styles.selected : ""}
                        onClick={() => setBreadcrumb(0)}
                      >
                        Media
                      </p>
                      <p
                        className={breadcrumb === 1 ? styles.selected : ""}
                        onClick={() => setBreadcrumb(1)}
                      >
                        Upload Files
                      </p>
                    </div>
                  )}
                  {/* <input type="file" /> */}
                  {breadcrumb === 1 && (
                    <div className={styles.uploadBox}>
                      {!imageURL ? (
                        <label htmlFor="upload-photo" className={styles.icon}>
                          <AddPhotoAlternateIcon />
                        </label>
                      ) : (
                        <img
                          style={{
                            position: "relative",
                            height: "70%",
                            display: "flex",
                          }}
                          src={imageURL}
                          alt="pic"
                        ></img>
                      )}
                    </div>
                  )}
                  {breadcrumb === 1 && imageURL && (
                    <div className={styles.btns}>
                      <Button
                        style={{
                          backgroundColor: !imageURL ? "disabled" : "#FFA737",
                          color: "white",
                          marginBottom: "2rem",
                        }}
                        variant="contained"
                        disabled={!imageURL}
                        onClick={uploadImage}
                      >
                        Upload
                      </Button>
                    </div>
                  )}
                </div>
                {breadcrumb === 0 && (
                  <div
                    style={{
                      height: "15rem",
                      alignItems: "flex-start",
                      padding: "10px",
                      border: "1px solid #FFA737",
                      marginBottom: "2rem",
                      borderRadius: "5px",
                      overflow: "auto",
                      position: "relative",
                    }}
                  >
                    <Grid container direction="row" spacing={2}>
                      {loading
                        ? [1, 2, 3, 4].map(() => (
                          <Grid item xs={3} md={2}>
                            <Skeleton variant="rectangular" />
                          </Grid>
                        ))
                        : uploadedImgs.map(({ imageurl, caption, id }) => (
                          <Grid
                            item
                            xs={4}
                            sm={3}
                            md={2}
                            style={{ height: "5.5rem" }}
                          >
                            <div
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  background: "#eae0e0",
                                  borderRadius: "5px",
                                  border: "1px solid #FFA737",
                                  height: "100%",
                                  cursor: "pointer",
                              }}
                              onClick={() =>
                                chooseImage(caption, id, imageurl)
                              }
                            >
                              <img
                                src={imageurl}
                                style={{
                                    width: "100%",
                                    position: "relative",
                                    maxHeight: "100%",
                                }}
                                alt="pic"
                              />
                            </div>
                          </Grid>
                        ))}
                    </Grid>
                  </div>
                )}
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  id="upload-photo"
                  onChange={(e) => handleImage(e)}
                />
                <div className={styles.btns} style={{ marginTop: "2rem" }}>
                  {/* {   console.log(isValid,dirty,values)} */}
                  <Button
                    style={{
                      backgroundColor:
                      !isValid || !dirty ? "lightgrey" : "#FFA737",
                      color: "white",
                    }}
                    type="submit"
                    disabled={!isValid || !dirty}
                    variant="contained"
                  >
                    Update
                  </Button>
                  <button
                    type="reset"
                    className={styles.btn1}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Dialog>
  );
};

export default EditWorksheetModal;
