import {useEffect, useState} from "react"
import { useDispatch,useSelector } from "react-redux";
import {showFormulaBox} from "../../store/actions/editActions"

import "./TextEditor.scss"

import AddIcon from '@mui/icons-material/Add';

const CustomToolbar = ({ table}) => {
  const dispatch = useDispatch();
  const formulaBox = useSelector((state)=> state.texteditor.formulaBox)
  return (
    <div id="toolbar">
      <button className="btn ql-bold"></button>
      <button className="btn ql-italic"></button>
      <button className="btn ql-strike"></button>
      <button className="btn ql-underline"></button>
      <button className="btn ql-script" value="sub"></button>
      <button className="btn ql-script" value="super"></button>
      <button className="btn ql-code"></button>


      <div className="btn">
        <select className=" ql-color">
          <option value="rgb(0, 0, 0)" />
          <option value="rgb(230, 0, 0)" />
          <option value="rgb(255, 153, 0)" />
          <option value="rgb(255, 255, 0)" />
          <option value="rgb(0, 138, 0)" />
          <option value="rgb(0, 102, 204)" />
          <option value="rgb(153, 51, 255)" />
          <option value="rgb(255, 255, 255)" />
          <option value="rgb(250, 204, 204)" />
          <option value="rgb(255, 235, 204)" />
          <option value="rgb(204, 224, 245)" />
          <option value="rgb(235, 214, 255)" />
          <option value="rgb(187, 187, 187)" />
          <option value="rgb(102, 185, 102)" />
        </select>
      </div>


      <div className="btn">
        <select className=" ql-background">
          <option value="rgb(0, 0, 0)" />
          <option value="rgb(230, 0, 0)" />
          <option value="rgb(255, 153, 0)" />
          <option value="rgb(255, 255, 0)" />
          <option value="rgb(0, 138, 0)" />
          <option value="rgb(0, 102, 204)" />
          <option value="rgb(153, 51, 255)" />
          <option value="rgb(255, 255, 255)" />
          <option value="rgb(250, 204, 204)" />
          <option value="rgb(255, 235, 204)" />
          <option value="rgb(204, 224, 245)" />
          <option value="rgb(235, 214, 255)" />
          <option value="rgb(187, 187, 187)" />
          <option value="rgb(102, 185, 102)" />
        </select>
      </div>


      <button className="btn ql-list">list</button>
      <button title="Link" className="btn ql-link"></button>

      <div className="btn">
        <select className=" ql-align">
          <option value=""></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </div>

      <div className="btn-size">
        <select className="ql-size"></select>
      </div>

      <div className="btn-opt">
        <select className="ql-font">
          <option value="monospace" label="monospace"></option>
          <option value="arial" label="arial"></option>
          <option value="Roboto" label="Roboto"></option>
          <option value="Raleway" label="Raleway"></option>
          <option value="Montserrat" label="Montserrat"></option>
          <option value="Lato" label="Lato"></option>
          <option value="Rubik" label="Rubik"></option>
        </select>
      </div>

      { <button className="btn-opt " onClick={() => dispatch(showFormulaBox())}>
        <AddIcon/> add formula
      </button>}
      { !table && <button className="btn-opt ql-inserImage"> <AddIcon/> Insert Image</button>}




      {/*  <button className="btn ql-listImage">
        Study Design
        </button>

        ["Roboto", "Raleway", "Montserrat", "Lato", "Rubik"];
      */}
    </div>
  );
};

export default CustomToolbar;
