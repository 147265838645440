// Render Prop
import { ErrorMessage, Form, Formik } from "formik";
import { Button, CircularProgress, Grid } from "@mui/material";

import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import FormControl from "../Form/FormControl";
import { validationSchema } from "../Form/validation/OTPvalidation";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styles from "./styles.module.scss";
import { otpVerify } from "../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";

const Otpwrapper = () => {
  const dispatch = useDispatch();
  const {load,email,password} = useSelector(state=>state.auth)
  const initialValues = { otp: "" };
  const [OTP,setOTP]=useState("")
  const history = useHistory()
  const handleSubmit=()=>{
    dispatch(otpVerify({otp:OTP,email,password},history));
  }
  return (
    <div style={{ width: "90%" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(otpVerify(OTP,history));
          console.log(values)
        }}
      >
        {(formProps) => (
          <Form>
            <Grid
              className={styles.formCont}
              container
              direction="column"
              rowGap={{ xs: 1.2, sm: 3.6 }}
              justifyContent="center"
              style={{ display: "flex", alignContent: "flex-start" }}
            >
              <Grid item>
                <h1 className={styles.heading}>
                <ArrowBackIosIcon style={{cursor:'pointer'}} onClick={()=>{history.push('/login')}}/> {" "}OTP Verification</h1>
              </Grid>

              <Grid item>
                <div style={{fontSize:"small"}}>
               Enter the OTP sent to {email}
                </div>
              </Grid>
              <Grid item>
                  <FormControl
                    control="segmented"
                    type="otp"
                    name="otp"
                    placeholder="OTP"
                   OTP={OTP}
                   setOTP={setOTP}
                  />
            </Grid>

              <Grid item>
               {load?<CircularProgress/>:<Button
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={OTP.length!==6}
                  className={styles.btn}
                >
                  Verify
                </Button>}
              </Grid>

              <Grid item container alignItems="baseLine">
                <p className={styles.link}>
                  Didn't recieved OTP?{" "}
                  <Link to="/otp" className={styles.fakelink}>
                    Resend OTP
                  </Link>
                </p>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Otpwrapper;
