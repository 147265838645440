import {useEffect} from "react"
import { Switch, Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CustomRoute from "../CustomRoute";
import Content from "../../content/Content"
import { useDispatch } from "react-redux";
import Api from "../../../ApiRequests"
import useSave from "../../../save"
import { fetchPage } from "../../../store/actions/pageActions";
import {fetchContainer} from "../../../store/actions/containerActions";
const Home = ({ routes, reactRouterRoutes }) => {
  const location = window.location.pathname.split('/')[2]
  const dispatch = useDispatch();
  const {worksheet, payload } = useSave();

  useEffect(()=>{

    if(location === worksheet.id){
      var set = setInterval(()=>{
        console.log("saving data in worksheet");
      save();
    },120000)
    }

    return ()=>{
      clearInterval(set);
    }
  },[worksheet.id])

  useEffect(()=>{
  loadSheet(location);

  return ()=>{
    dispatch({type:"WORKSHEET_LOAD"});
  }
},[])


  const loadmetaData = ()=>{
    Api.getWorksheetById(worksheet.id).then(res=>{
      dispatch({type:"LOAD_WORKSHEET",payload:res.data.body.workSheet})
      // dispatch(fetchPage(res.data.body.data.page))
      // dispatch(fetchContainer(res.data.body.data.container))
    })
  }

  const loadSheet=(id)=>{
    Api.getWorksheetById(id).then(res=>{
      dispatch({type:"LOAD_WORKSHEET",payload:res.data.body.workSheet})
      dispatch(fetchPage(res.data.body.data.page))
      dispatch(fetchContainer(res.data.body.data.container))
    })
  }

    const save = async ()=>{
      try{
        let ress = await Api.updateWorksheet(worksheet.id, payload);
        console.log(ress);
      //   toast.success('All changes saved', {
      // position: "top-right",
      // autoClose: 5000,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,
      // });
      loadmetaData();
      } catch (err){
        console.log(err.response);
      //   toast.error(`${err.response.data.message}`, {
      // position: "top-right",
      // autoClose: 5000,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,
      // });
    }
    }

  return (
    <div>
      <Content />
      <Switch>
        {routes.map((route, i) => (
          <CustomRoute key={uuidv4} {...route} />
        ))}
      </Switch>
    </div>
  );
};

export default Home;
