import React from "react";
import InputField from "./FormField/InputField";
import TextAreaField from "./FormField/TextAreaField";
import SelectField from "./FormField/SelectField";
import CheckboxesField from "./FormField/CheckBoxField";
import RadioButtonsField from "./FormField/RadioField";
import SegmentedField from "./FormField/SegmentedField";
import PassswordField from "./FormField/passwordField";

function FormikController(props) {
  const { control, ...rest } = props;
  // console.log(control);
  switch (control) {
    case "input":
      return <InputField {...rest} />;
    case "textArea":
      return <TextAreaField {...rest} />;
    case "select":
      return <SelectField {...rest} />;
    case "radio":
      return <RadioButtonsField {...rest} />;
    case "checkbox":
      return <CheckboxesField {...rest} />;
      case "segmented":
        return <SegmentedField {...rest} />;
    case "password":
      return <PassswordField {...rest}/>
    default:
      return null;
  }
}
export default FormikController;
