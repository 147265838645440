import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import {
  addBox,
  setBoxes,
  setBoxType,
} from "../../store/actions/containerActions";

const availabelBoxes = [[1], [1, 1], [1, 1, 1], [2, 1], [1, 2], [1, 1, 1, 1]];

const BoxTab = ({ selectedPage, selectedContainer, enableAddBox }) => {
  const dispatch = useDispatch();

  return (
    <div style={{ padding: "1rem" }}>
      <hr />
      <h5>Boxes</h5>
      {availabelBoxes.map((boxes, i) => {
        return (
          <div
            className={`container-right-side`}
            onClick={() =>
              dispatch(setBoxes(selectedPage, selectedContainer.index, boxes))
            }
            key={i}
          >
            {boxes.map((box, index, boxes) => {
              return (
                <div className="col" style={{ flex: box }} key={index}>
                  {box} : {boxes.reduce((p, c) => c + p, 0)}
                </div>
              );
            })}
          </div>
        );
      })}
      <hr />
      <Button
        variant="contained"
        color="primary"
        disabled={enableAddBox}
        className="btn-orange btn"
        onClick={() => {
          dispatch(addBox(selectedPage.index, selectedContainer.index));
        }}
      >
        Add Boxes
      </Button>
    </div>
  );
};

export default BoxTab
