import React from 'react';
import { useDispatch } from 'react-redux';
//redux actions
import { deleteBox, setBoxHeight, setBoxText,resetBoxType } from '../../store/actions/containerActions';
import {setTableCeilText} from "../../store/actions/containerActions"
import {clearTable} from "../../store/actions/containerActions"
import { BorderStyle } from '../borderstyle/BorderStyle';
import TableEditor from '../tableeditor/TableEditor';

import "./style.scss"

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

//icons
import DeleteIcon from "@material-ui/icons/Delete";


const EditTableBox = ({ selectedPage, box }) => {

    const dispatch = useDispatch()

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
    };
    
    return (
      <div>

        <Box className="display-flex box-head brd-botm">

          <Button
            variant="outlined"
            onClick={()=>{
              dispatch(resetBoxType(box,false));
              dispatch(clearTable(box, box.table.length, box.table[0].length ));
            }}
          >
            Reset
          </Button>

          <Button
            variant="outlined"
            onClick={() => {
              dispatch(clearTable(box, box.table.length, box.table[0].length ));
            }}
          >
            clear
          </Button>

          <Button
            variant="outlined"
            color="error"
            onClick={() => dispatch(deleteBox(box))}
          >
            {/* <DeleteIcon fontSize="small" /> */}
            Delete Box
          </Button>
        </Box>
        <Box className="head brd-botm" style={{paddingBottom:"0rem"}}>
          <h4>
            Box {box.index + 1} : {box.type}
          </h4>
        </Box>

        <Box className="brd-botm icon-parent" style={{padding:"0rem 1rem 1rem"}}>
          {<TableEditor box={box} selectedPage={selectedPage} />}
          <Box className="zoom-icon-box" onClick={handleOpen}><OpenInFullIcon /></Box>
        </Box>

        <BorderStyle type="container" box={box} />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box className="modal-parent" style={{padding:"0rem 1rem 1rem"}}>
              {<TableEditor box={box} selectedPage={selectedPage} />}
              <Box className="zoom-icon-box" onClick={handleClose}><OpenInFullIcon /></Box>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  };
export default EditTableBox;
