import { useEffect, useState } from "react";

import Nav from "../../Nav";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import FormikController from "../../Form/FormControl";
import { validationSchema } from "../../Form/validation/editProfile";
import Api from "../../../ApiRequests";
import { loadUser } from "../../../store/actions/auth";
import { validationSchemaPass } from "../../Form/validation/changePass";
import { useHistory } from "react-router-dom";
import DeleteAccountModal from "./Dialog/DeleteDialog";

const Settings = () => {
  const location =window.location.pathname.split('/')[2]
  const [link, setLink] = useState(0);
  const [image, setImage] = useState(0);
  const history = useHistory()
  // console.log(location,link)
  useEffect(()=>setLink(parseInt(location)),[location])
  return (
    <div className={styles.wrapper}>
      {/* <Nav/> */}

      <div className={styles.container}>
        <ul>
          <li>
            <p
              className={link === 0 ? styles.selected : ""}
              onClick={() => history.push('/settings/0')}
            >
              {link !== 0 ? (
                <img src="/img/5.svg" alt="" />
              ) : (
                <img src="/img/7.svg" alt="" />
              )}
              Edit Profile
            </p>
          </li>
          <li>
            <p
              className={link === 1 ? styles.selected : ""}
              onClick={() => history.push('/settings/1')}
            >
              {link !== 1 ? (
                <img src="/img/3.svg" alt="" />
              ) : (
                <img src="/img/8.svg" alt="" />
              )}
              Change Password
            </p>
          </li>
          <li>
            <p
              className={link === 2 ? styles.selected : ""}
              onClick={() => history.push('/settings/2')}
            >
              {link !== 2 ? (
                <img src="/img/4.svg" alt="" />
              ) : (
                <img src="/img/6.svg" alt="" />
              )}
              Delete Account
            </p>
          </li>
        </ul>
              {/* {console.log(link)} */}
        {link === 0 ? (
          <EditProfile />
        ) : link === 1 ? (
          <ChangePassword setLink={setLink} />
        ) : (
          <DeleteAccount />
        )}
      </div>
    </div>
  );
};

export const EditProfile = () => {
  const [change, setChange] = useState(0);
  const { currentUser } = useSelector((state) => state.auth);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const handleImage = (e) => {
    // console.log("reached");
    setImage(e.target.files[0]);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
  };
  const dispatch = useDispatch();
  const updateProfile = (values) => {
    const form = new FormData();
    form.append("fullname", values.fullname);
    form.append("institution", values.institution);
    form.append("phone", values.phone);
    form.append("role", values.role);
    if (image) form.append("avatar", image);

    // console.log([...form], values);
    Api.updateProfile(form)
      .then((res) => {
        dispatch(loadUser());
        setChange(0);
      })
      .catch((err) => {});
  };
  const initialValues = {
    fullname: currentUser?.fullname ?? "",
    email: currentUser?.email ?? "",
    institution: currentUser?.institution ?? "",
    phone: currentUser?.phoneNumber ?? "",
    role: currentUser?.role ?? "",
    avatar: currentUser?.avatar ?? "",
  };
  return (
    <div className={styles.rightBox}>
      <h1>Edit Profile</h1>
      <div className={styles.underline}></div>

      {change === 0 ? (
        <>
          <div className={styles.profileBox}>
            <Avatar
              className={styles.userimg}
              src={currentUser?.avatar}
              alt="user"
            ></Avatar>
            <div className={styles.fields}>
              <div className={styles.text}>
                <p className={styles.topic}>Full Name</p>
                <p className={styles.value}>{currentUser?.fullname}</p>
              </div>
              <div className={styles.text}>
                <p className={styles.topic}>Email</p>
                <p className={styles.value}>{currentUser?.email}</p>
              </div>
              <div className={styles.text}>
                <p className={styles.topic}>Institution</p>
                <p className={styles.value}>{currentUser?.institution}</p>
              </div>
              <div className={styles.text}>
                <p className={styles.topic}>Phone No.</p>
                <p className={styles.value}>{currentUser?.phoneNumber}</p>
              </div>
              <div className={styles.text}>
                <p className={styles.topic}>Role</p>
                <p className={styles.value}>{currentUser?.role}</p>
              </div>
          <div className={styles.btns}>
            <button className={styles.btn2} onClick={() => setChange(1)}>
              Edit Profile
            </button>
          </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.profileBox}>
            <label htmlFor="upload-photo" style={{ cursor: "pointer" }}>
              <Avatar
                className={styles.userimg}
                src={imageUrl ? imageUrl : currentUser.avatar}
                name="avatar"
                alt="user"
              >
                <PhotoCameraIcon className={styles.addimage} />
              </Avatar>
            </label>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => updateProfile(values)}
              validationSchema={validationSchema}
            >
              {({ dirty, isValid, values, handleChange, handleBlur }) => (
                <Form>
                  <div className={styles.fields}>
                    <div className={styles.text}>
                      <p className={styles.topic}>Full Name</p>
                      <FormikController
                        id="outlined-textarea"
                        //label="Project Title here..."
                        variant="outlined"
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                        name="fullname"
                        control="input"
                      />
                    </div>
                    <div className={styles.text}>
                      <p className={styles.topic}>Email</p>
                      <FormikController
                        id="outlined-textarea"
                        variant="outlined"
                        disabled
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                        name="email"
                        control="input"
                      />
                    </div>
                    <div className={styles.text}>
                      <p className={styles.topic}>Institution</p>
                      <FormikController
                        id="outlined-textarea"
                        //label="Project Title here..."
                        variant="outlined"
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                        name="institution"
                        control="input"
                      />
                    </div>
                    <div className={styles.text}>
                      <p className={styles.topic}>Phone No.</p>
                      <FormikController
                        id="outlined-textarea"
                        //label="Project Title here..."
                        variant="outlined"
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                        name="phone"
                        control="input"
                      />
                    </div>
                    <div className={styles.text}>
                      <p className={styles.topic}>Role</p>
                      <FormikController
                        id="outlined-textarea"
                        //label="Project Title here..."
                        variant="outlined"
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                        name="role"
                        control="input"
                      />
                    </div>
                  </div>
                  <div className={styles.btns}>
                    {/* {console.log({ dirty, isValid, values })} */}
                    <button
                      className={styles.btn2}
                      type="submit"
                      disabled={!isValid}
                    >
                      Update
                    </button>
                    <button
                      className={styles.btn1}
                      onClick={() => {
                        setImage(null);
                        setChange(0);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <input
              style={{ display: "none" }}
              type="file"
              accept="image/png, image/gif, image/jpeg"
              id="upload-photo"
              onChange={(e) => handleImage(e)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export const ChangePassword = ({ setLink }) => {
  const initialState = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const dispatch = useDispatch();
  const changePassword = (value) => {
    // console.log(value);
    Api.changePassword(value)
      .then((res) => {
        if(res.status===200)
        setLink(0);
      })
      .catch((err) => {
        console.log(err.response);
        dispatch({ type: "ERROR", payload: err.response.data.message });
      });
  };
  const history = useHistory()
  return (
    <div className={styles.rightBox}>
      <h1>Change Password</h1>
      <div className={styles.underline}></div>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchemaPass}
        onSubmit={(values) => changePassword(values)}
      >
        {({ dirty, isValid }) => (
          <Form>
            {" "}
            <div className={styles.password}>
              <div className={styles.text}>
                <label className={styles.topic}>Old Password</label>
                <FormikController
                  id="outlined-textarea"
                  //label="Project Title here..."
                  variant="outlined"
                  style={{ border: "1px solid #FFA737" }}
                  className={styles.input}
                  name="currentPassword"
                  control="password"
                ></FormikController>
              </div>
              <div className={styles.text} style={{ marginTop: "5px" }}>
                <label className={styles.topic}>New Password</label>
                <FormikController
                  id="outlined-textarea"
                  //label="Project Title here..."
                  variant="outlined"
                  style={{ border: "1px solid #FFA737" }}
                  className={styles.input}
                  name="newPassword"
                  control="password"
                />
              </div>
              <div className={styles.text} style={{ marginTop: "5px" }}>
                <label className={styles.topic}>Confirm Password</label>
                <FormikController
                  id="outlined-textarea"
                  //label="Project Title here..."
                  variant="outlined"
                  style={{ border: "1px solid #FFA737" }}
                  className={styles.input}
                  name="confirmPassword"
                  control="password"
                />
              </div>
            <div className={styles.btns}  style={{ marginTop: "5px" ,width:"60%"}}>
              <button className={styles.btn2} type="submit">
                Change Password
              </button>
              <button type="reset"className={styles.btn1} onClick={() => history.push('/settings/0')}>
                Cancel
              </button>
            </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const DeleteAccount = () => {
  const history = useHistory()
  const [open,setOpen] = useState(false)
  return (
    <div className={styles.rightBox}>
      <h1>Delete Account</h1>
      <div className={styles.underline}></div>
      <p style={{fontSize:"small"}}>
        Are you sure, you want to delete this account ? Please Note that Once a
        Account is Deleted, it cannot be retrieved back. All Data, Worksheet,
        Logo, Images uploaded cannot be restored back.
      </p>
      <div className={styles.btns}>
        <button className={styles.btn2} onClick={()=>history.push('/settings/0')}>Cancel</button>
        <button className={styles.btn1} onClick={()=>setOpen(true)}>Delete My Account</button>
      </div>
      <DeleteAccountModal open={open} setOpen={setOpen}/>
    </div>
  );
};

export default Settings;
