import "./style.scss";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  deleteBox,
  setBoxHeight,
  setBoxGraph,
  resetBoxType
} from "../../store/actions/containerActions";
import { BorderStyle } from "../borderstyle/BorderStyle";

//icons
import DeleteIcon from "@material-ui/icons/Delete";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const EditGraph = ({ selectedPage, box }) => {
  const dispatch = useDispatch();

  const _xvn = useRef();
  const _xvp = useRef();

  const _yvn = useRef();
  const _yvp = useRef();

  const _xi = useRef();
  const _yi = useRef();

  const draw = () => {


    const xi = parseInt(_xi.current.value);
    const yi = parseInt(_yi.current.value);

    const xvn = parseInt(_xvn.current.value);
    const xvp = parseInt(_xvp.current.value);

    const yvn = parseInt(_yvn.current.value);
    const yvp = parseInt(_yvp.current.value);

     dispatch(setBoxGraph(box, {xi , yi ,  xvn , xvp , yvn , yvp}))


  };
  return (
    <div>

      <Box className="display-flex box-head brd-botm">

        <Button
          variant="outlined"
          onClick={()=>{
            dispatch(resetBoxType(box,false))
            dispatch(setBoxGraph(box, ""))
          }}
        >
          Reset
        </Button>

        <Button
          variant="outlined"
          onClick={()=>{
            dispatch(setBoxGraph(box, ""))
          }}
        >
          clear
        </Button>

        <Button
          variant="outlined"
          color="error"
          onClick={() => dispatch(deleteBox(box))}
        >
          {/* <DeleteIcon fontSize="small" /> */}
          Delete Box
        </Button>
      </Box>
      <Box className="head brd-botm">
        <h4>
          Box {box.index + 1} : {box.type}
        </h4>
      </Box>


      <Box className="brd-botm" style={{padding:"1rem"}}>
        <div className="grph-cont">
          <div>
            <p>Horizontal Values</p>

            <div className="display-flex">
              <div className="grph-sec">
                <label> x - </label>
                <input
                  className="graph-input"
                  ref={_xvn}
                  type="number"
                  min={1}
                  defaultValue={1}
                  placeholder="x-"
                  onChange={draw}
                />
              </div>
              <div className="grph-sec">
                <label> x + </label>
                <input
                  className="graph-input"
                  ref={_xvp}
                  type="number"
                  min={1}
                  defaultValue={1}
                  onChange={draw}
                />
              </div>
            </div>

          </div>

          <div >
            <p>Horizontal Interval</p>
            <div className="grph-sec">
              <label>x i </label>
              <input
                className="graph-input"
                ref={_xi}
                type="number"
                min={1}
                defaultValue={1}
                onChange={draw}
              />
            </div>
          </div>
        </div>

        <div className="grph-cont">
          <div>
            <p>Vertical Values</p>

            <div className="display-flex">
              <div className="grph-sec">
                <label>y - </label>
                <input
                  className="graph-input"
                  ref={_yvn}
                  type="number"
                  min={1}
                  defaultValue={1}
                  onChange={draw}
                />
              </div>
              <div className="grph-sec">
                <label>y + </label>
                <input
                  className="graph-input"
                  ref={_yvp}
                  type="number"
                  min={1}
                  defaultValue={1}
                  onChange={draw}
                />
              </div>
            </div>
          </div>

          <div >
            <p>Vertical Interval</p>
            <div className="grph-sec">
              <label> y i </label>
              <input
                className="graph-input"
                ref={_yi}
                type="number"
                min={1}
                defaultValue={1}
                onChange={draw}
              />
            </div>
          </div>
        </div>
      </Box>



      <BorderStyle type="container" box={box} />
    </div>
  );
};
export default EditGraph;
