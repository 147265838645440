import React, { useEffect, useRef } from 'react'

const Graph = ({box, _height , _width}) => {

   const graphRef = useRef()

   useEffect(()=>{
    //  console.log(box)
    const {xi , yi ,  xvn , xvp , yvn , yvp} = box.graph;

    const canvas = graphRef.current
    const context = canvas.getContext("2d");
    const width = parseInt(_width);
    const height = parseInt(_height);
    context.clearRect(0, 0, width , height );

    context.beginPath();
    context.moveTo(0, height / 2);
    context.lineTo(width, height / 2);

    context.stroke();

    context.beginPath();
    context.moveTo(width / 2, 0);
    context.lineTo(width / 2, height);

    context.stroke();

    const maxX = Math.max(xvn, xvp);
    const xStep = width / 2 / maxX;
    // console.log(xStep);

    let xpc = 0;
    for (let i = 0; i < xvp + 1; i = xi + i) {
      context.beginPath();
      context.moveTo(width / 2 + i * xStep, height / 2 - 5);
      context.lineTo(width / 2 + i * xStep, height / 2 + 5);
      context.fillText(`${xpc}`, width / 2 + i * xStep, height / 2 + 11);
      context.stroke();
      xpc++;
    }

    let xnc = 0;
    for (let i = 0; i < xvn + 1; i = xi + i) {
      context.beginPath();
      context.moveTo(width / 2 - i * xStep, height / 2 - 5);
      context.lineTo(width / 2 - i * xStep, height / 2 + 5);
      if (xnc !== 0)
        context.fillText(`${xnc}`, width / 2 - i * xStep, height / 2 + 11);
      context.stroke();
      xnc++;
    }

    const maxY = Math.max(yvn, yvp);
    const yStep = width / 2 / maxY;
    // console.log(yStep);

    let ypc = 0;
    for (let i = 0; i < yvp + 1; i = yi + i) {
      context.beginPath();
      context.moveTo(width / 2 - 5, height / 2 - i * yStep);
      context.lineTo(width / 2 + 5, height / 2 - i * yStep);
      if (ypc !== 0)
        context.fillText(`${ypc}`, width / 2 + 5, height / 2 - i * yStep);
      context.stroke();
      ypc++;
    }

    let ync = 0;
    for (let i = 0; i < yvn + 1; i = yi + i) {
      context.beginPath();
      context.moveTo(width / 2 - 5, height / 2 + i * yStep);
      context.lineTo(width / 2 - 5, height / 2 + i * yStep);
      if (ync !== 0)
        context.fillText(`${ync}`, width / 2 - 5, height / 2 + i * yStep);
      context.stroke();
      ync++;
    }
  }, [box.graph])

  return (
    <div>
         <canvas ref={graphRef} id={`graph-box-canva-${box.index}`} width={_width} height={_height}></canvas>
    </div>
  )
}

export default Graph
