import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteBox, setBoxHeight, setBoxText,resetBoxType } from '../../store/actions/containerActions';
import { BorderStyle } from '../borderstyle/BorderStyle';

import "./style.scss"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

//icons
import DeleteIcon from "@material-ui/icons/Delete";


const EditBlankSpace = ({ selectedPage, box }) => {

    const dispatch = useDispatch();



    return (
      <div>

        <Box className="display-flex box-head brd-botm">

          <Button
            variant="outlined"
            onClick={()=>{
              dispatch(resetBoxType(box,false))
            }}
          >
            Reset
          </Button>

          {/* <Button
            variant="outlined"
            onClick={() => {
              dispatch(setBoxText(box, ""));
              dispatch(clearTxtEditor());
            }}
            >
            clear
          </Button> */}

          <Button
            variant="outlined"
            color="error"
            onClick={() => dispatch(deleteBox(box))}
          >
            {/* <DeleteIcon fontSize="small" /> */}
            Delete Box
          </Button>
        </Box>
        <Box className="head brd-botm" style={{paddingBottom:"0rem"}}>
          <h4>
            Box {box.index + 1} : {box.type}
          </h4>
        </Box>

        <BorderStyle type="container" box={box} />
      </div>
    );
  };
export default EditBlankSpace;
