// Render Prop
import { useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { Button, CircularProgress, Grid } from "@mui/material";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import FormControl from "../Form/FormControl";
import { validationSchema } from "../Form/validation/editProfile";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import styles from "./styles.module.scss";
import { useSelector ,useDispatch} from "react-redux";
import {useHistory} from "react-router-dom"
import Api from "../../ApiRequests"
const Profile = ({ onSignUpSubmit}) => {
  const initialValues={
    fullname:"",
    phone:"",
    role:"",
    institution:""

  }
  const { load } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const history = useHistory()
  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // console.log(values)
          const form = new FormData()
          form.append('fullname',values.fullname)
          form.append('phone',values.phone)
          form.append('role',values.role)
          form.append('institution',values.institution)
          dispatch({type:"LOADING"})
          Api.updateProfile(form).then(()=>{
            dispatch({type:"LOADED"})
            history.push('/dashboard/All')
          }).catch(err=>{
            dispatch({type:"LOADED"})
            dispatch({type:"ERROR",payload:err.response.message})
          })

        }}
      >
        {(formProps) => (
          <Form>
            <Grid
              className={styles.formCont}
              container
              direction="column"
              rowGap={{ xs: 2, sm: 3.6 }}
              justifyContent="center"
              style={{display:"flex",alignContent:"flex-start"}}
            >
              <Grid item>
                <div className={styles.inputbox}>
                  <img
                    src="/assets/user.svg"
                    style={{ width: "1rem" }}
                    alt=""
                  />
                  <FormControl
                    control="input"
                    type="text"
                    name="fullname"
                    placeholder="Full Name"
                    errorout={true}
                  />
                </div>
                <ErrorMessage
                  component="div"
                  style={{
                    position: "absolute",
                    color: "red",
                    fontSize: "xx-small",
                  }}
                  name="fullname"
                />
              </Grid>
              <Grid item style={{width:"90%"}}>
                <div className={styles.inputbox}>
                  <LocalPhoneIcon style={{ fontSize: "1rem", color: "#79747E" }} />
                  <FormControl
                    control="input"
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    errorout={true}
                  />
                </div>
                <ErrorMessage
                  component="div"
                  style={{
                    position: "absolute",
                    color: "red",
                    fontSize: "xx-small",
                  }}
                  name="phone"
                />
              </Grid>
              <Grid item style={{width:"90%"}}>
                <div className={styles.inputbox}>
                  <BusinessCenterIcon style={{ fontSize: "1rem", color: "#79747E" }} />
                  <FormControl
                    control="input"
                    type="text"
                    name="role"
                    placeholder="Profession"
                    errorout={true}
                  />
                </div>
                <ErrorMessage
                  component="div"
                  style={{
                    position: "absolute",
                    color: "red",
                    fontSize: "xx-small",
                  }}
                  name="role"
                />
              </Grid>
              

              <Grid item>
                <div className={styles.inputbox}>
                  <img
                    src="/assets/code.svg"
                    style={{ width: "1rem" }}
                    alt=""
                  />
                  <FormControl
                    control="input"
                    type="text"
                    name="institution"
                    placeholder="Institution"
                    errorout={true}
                  />
                </div>
                <ErrorMessage
                  component="div"
                  style={{
                    position: "absolute",
                    color: "red",
                    fontSize: "xx-small",
                  }}
                  name="institution"
                />
              </Grid>
              <Grid item>
                {load ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    type="Submit"
                    disabled={!formProps.dirty || !formProps.isValid}
                    // onClick={formProps.handleSubmit}
                    // disabled={formProps.isSubmitting}
                    className={styles.btn}
                  >
                    Create Profile
                  </Button>
                )}
            </Grid>
            </Grid>
            
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Profile;
