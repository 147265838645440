import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CreateTemplate from "../Dialog/CreateTemplate"
import { List, ListItem, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BackspaceIcon from '@mui/icons-material/Backspace';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import useSave from "../../save"
import { toast } from 'react-toastify';
import Api from "../../ApiRequests"
import {useHistory} from "react-router-dom"
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Delete } from "@material-ui/icons";

export default function MenuList({anchorEl,setAnchorEl}) {
  const history = useHistory();
  const {worksheet, payload } = useSave();
  const [open,setOpen] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleClose = () => {
    setAnchorEl(null);
  };


  const saveandClose = async ()=>{
    try{
      let ress = await toast.promise(
        Api.updateWorksheet(worksheet.id, payload),
        {
          pending: 'Saving worksheet',
          success: 'Worksheet saved',
          error: 'Something went wrong!'
        }
      );
      console.log(ress);
    history.push("/dashboard/All");
    } catch (err){
      console.log(err.response);
    };
  }

  const deleteWorksheet = () => {
    Api.deleteSheet(worksheet.id)
      .then((res) => {
        toast.success('Worksheet deleted', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
      handleCloseModal();
      history.push("/dashboard/All");
      })
      .catch((err) => {
      console.log(err);
      toast.error(`${err.response.data.message}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
      });
  };


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: 550,
    boxShadow: 24,
    p: 4,
  };


  return (
    <div>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            border: "1px solid #007AFF",
            marginTop: "20px",
          },
        }}
      >
        <List>
          <ListItem button style={{ padding: "4px 8px" }} onClick={()=>{setOpen(true);handleClose()}}><NoteAddIcon style={{marginRight:"6px"}}/>Create Tempelate</ListItem>
          <ListItem button style={{ padding: "4px 8px" }} onClick={()=>{handleClose();handleOpenModal();}}><DeleteIcon style={{marginRight:"7px"}}/>Delete</ListItem>
          <ListItem button style={{ padding: "4px 8px" }} onClick={()=>{handleClose(); saveandClose()}}><BackspaceIcon style={{marginRight:"7px"}}/>Save and Close</ListItem>
        </List>
      </Menu>
      <CreateTemplate open={open} setOpen={setOpen}/>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box style={{display:"flex", justifyContent:"end", cursor:"pointer"}} onClick={handleCloseModal}>
            <CloseIcon/>
          </Box>
          <Box>
            <Box style={{display:"flex", alignItems:"center"}}>
              <Delete/>
              <h3 style={{marginLeft:"10px"}}>Delete Worksheet</h3>
            </Box>
            <Box>
              <p>Are You sure want to delete the worksheet?</p>
            </Box>
            <Box style={{display:"flex", justifyContent:"end"}}>
              <Button variant="outlined" style={{margin:"10px"}} onClick={handleCloseModal}>
                cancel
              </Button>
              <Button variant="outlined" color="error" style={{margin:"10px"}} onClick={deleteWorksheet}>
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
