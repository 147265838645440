import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Container from "../container/Container";
import Footer from "../footer/Footer";
import Header from "../header/Header";

import "react-grid-layout/css/styles.css";

import "./Pagecontent.scss";

import { calculContainersHeight } from "../../utils/container";

import { Droppable, Draggable } from "react-beautiful-dnd";

//icons

import DragIcon from "@material-ui/icons/OpenWith";
import DeleteIcon from "@material-ui/icons/Delete";

import { deleteContainer } from "../../store/actions/containerActions";

import { useDispatch } from "react-redux";
import PersonnelNote from "../personalnote/PersonnelNote";
import { selectPage, addPerNote, deletePage } from "../../store/actions/pageActions";
import { calculContainersInPage } from "../../utils/container";

const grid = 0;

const Page2 = ({ page, index, containers_in_page, preview }) => {
  const dispatch = useDispatch();
  const containers = useSelector((state) => state.container.containers);
  const pages = useSelector((state) => state.page.pages);

  const width = page.width;
  const height = page.height;
  const pageContentHeight = page.pageContentHeight;

  const { containers_in_page: containers_in_next_page } =
    calculContainersInPage(pages, index + 1, containers);

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "transparent",
    padding: grid,
    width: "100%",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "transparent",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const [per_note, setPerNote] = useState(false);
  const [per_note_height, setPerNoteHeight] = useState(0);
  const [is_auto_personnel_note, setIsAutoPersonnelAuto] = useState(false);

  // useEffect(() => {
  //   if (
  //     containers_in_next_page &&
  //     containers_in_page &&
  //     page.pageContentHeight - calculContainersHeight(containers_in_page) >
  //       50 &&
  //     containers_in_next_page.length > 0
  //   ) {
  //     const height =
  //       page.pageContentHeight - calculContainersHeight(containers_in_page);
  //     dispatch(addPerNote(page, " ", height, true));
  //   }
  // }, [containers_in_next_page, containers_in_page, page.pageContentHeight]);

  useEffect(() => {
    const containersHeight = calculContainersHeight(containers_in_page);

    /*

    if (
      pageContentHeight - containersHeight > 60 &&
      pageContentHeight - containersHeight < 240 &&
      !containers_in_page[containers_in_page.length - 1].hasAddButton
    ) {
      setPerNote(true);
      setPerNoteHeight(pageContentHeight - containersHeight);

    } else {
      setPerNote(false);
    }
    */
  }, [containers, page]);

  return (
    <div className="main-page">
      {index!==0 &&
        (<div className="delete-page">
          <DeleteIcon
            onClick={()=>dispatch(deletePage(index))}
          />
        </div>)}
      <div
        className="page"
        style={{
          borderWidth: page.style.borderStyle !== "none" ? 4 : 1,
          width,
          height,
          ...page.style,
        }}
        onClick={() => dispatch(selectPage(index))}
      >
        {index !== 0 && (

          <div className="header-page">
            <Header index={index} page={page} />
          </div>
        )}
        <div
          className="page-content"
          style={{
            height: pageContentHeight,
            minHeight: pageContentHeight,
            maxHeight: pageContentHeight,
          }}
        >
          <Droppable
            droppableId={page.id + "page_number" + index}
            key={page.id + "page_number" + index}
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {containers_in_page &&
                  containers_in_page.map(
                    (container, containerIndex, _containers_in_page) => {
                      return (
                        <Draggable
                          key={container.id + "container_number" + containerIndex}
                          draggableId={
                            container.id + "container_number" + containerIndex
                          }
                          id={container.id + "container_number" + containerIndex}
                          index={containerIndex}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                ),
                              }}
                            >
                              <div>
                                {container.selected && !preview && (
                                  <div className="button-container">
                                    <button
                                      {...provided.dragHandleProps}
                                      className="drag-btn"
                                    >
                                      <DragIcon fontSize="small" />
                                    </button>

                                    {container.id!=='container-with-header'&&<button
                                      style={{
                                        zIndex: container.selected ? 999 : 1,
                                        cursor: "pointer",
                                      }}
                                      className="drag-btn delete"
                                      onClick={() =>{

                                        dispatch(deleteContainer(containerIndex))}
                                      }
                                                                             >
                                      <DeleteIcon fontSize="small" />
                                    </button>}
                                  </div>
                                )}
                                <Container
                                  container={container}
                                  preview={preview}
                                  containerIndex={container.index}
                                  pageIndex={index}
                                  page={page}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  )}
              </div>
            )}
          </Droppable>
          {
            <div
              style={{
                marginTop: "auto",
              }}
            >
              <PersonnelNote page={page} pageIndex={index} />
            </div>
          }

          {/* {containers_in_next_page &&
            containers_in_page &&
            page.pageContentHeight - calculContainersHeight(containers_in_page) >
            50 &&
            containers_in_next_page.length > 0 ? (
            <div
            style={{
              marginTop: "auto",
            }}
            >
            <PersonnelNote
              page={page}
              pageIndex={index}
              height={
            page.pageContentHeight -
            calculContainersHeight(containers_in_page)
              }
            />
            </div>
            ) : null}
            {containers_in_next_page &&
            containers_in_page &&
          page.pageContentHeight - calculContainersHeight(containers_in_page)} */}
        </div>

        <Footer index={index} page={page} />
      </div>
    </div>
  );
};

export default Page2;
