import { Grid } from "@material-ui/core";
import React, { useRef,useEffect } from "react";
import { useSelector } from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {  Box } from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";

import Api from "../../ApiRequests"
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import "./style.scss"
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

const UploadsFormTab = () => {

  const [images, setimages] = useState([]);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [preview, setpreview] = useState({
    view: false,
    url: "",
    file: undefined
  });
  const Input = styled('input')({
  display: 'none',
});

const style = {
position: 'absolute',
top: '50%',
left: '50%',
transform: 'translate(-50%, -50%)',
bgcolor: 'background.paper',
boxShadow: 24,
p: 4,
};

const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

  useEffect(()=>{
    getImage();
  },[])

  const getImage = async()=>{
    setloading(true);
    try{
      const res = await Api.getImage();
      setimages(res.data.body.results)
      setloading(false);
    } catch (err){
      console.log(err.response);
    }
  }

  useEffect(()=>{
    if(preview.file){
      const objectUrl = URL.createObjectURL(preview.file);
      setpreview({
        view: true,
        url: objectUrl,
        file: preview.file
      })
    }
  }, [preview.file])


  const uploadImage = async (e)=>{
    e.preventDefault();

    const caption = document.getElementById( "caption" ).value
    var formData = new FormData();
    formData.append("image", preview.file);
    formData.append("caption", caption)

    try{
      const res = await Api.uploadImage(formData);
      toast.success('Image Uploaded', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

    getImage();

    handleClose();
    }catch(err){
      console.log(err.response);
      toast.error(`${err.response.data.message}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
    }
  clear();
  }

  const clear = ()=>{
    document.getElementById( "caption" ).value= ""
    setpreview({
      view: false,
      url: "",
      file: undefined
    })
  }

  const previewfun = (e)=>{
    const file = e.target.files[0]
    setpreview({
      view: false,
      url: "",
      file: file
    })
  }



  return (
  <div>

    <Box style={{padding:"1rem"}} className="img-box-upload">
      {loading ? <Box className="load-box" >
        <CircularProgress color="inherit"/>
      </Box> :<Grid container  spacing={2} >
        {images.map((image, index) => {

          return (
            <Grid
              key={index}
              style={{ height: 170, padding: 8  }}
              item
              xs={6}
            >
              <div style={{
                height: " 100%",
                 backgroundSize: "100% 154px",
                backgroundImage: `url(${image.imageurl})`,
                backgroundRepeat : "no-repeat",
                borderRadius : 16
              }}>
                {/* <_Menu image={_image} index={i} /> */}
              </div>
            </Grid>
          );
        })}
      </Grid>}
    </Box>

    <Box style={{display : "flex" , justifyContent : "flex-end", margin:"1rem"}}>
      <Button variant="contained" component="span" onClick={handleOpen}>
        Upload image
      </Button>
    </Box>


    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{minWidth:500}}>
        <Box style={{display:"flex", justifyContent:"end", cursor:"pointer"}} onClick={handleClose}>
          <CloseIcon/>
        </Box>
        <div>
          <h2>Upload image</h2>
        </div>

        {preview.view && <Box className="preview-icon">
          <img src={preview.url}/>
        </Box>}
        <form id="Formimage">
          <Box style={{marginBottom:"1rem"}}>
            <label htmlFor="Iconfile">
              <Input accept="image/*" id="Iconfile" type="file" required onChange={previewfun}/>
              <Button variant="contained" component="span">
                Choose Image
              </Button>
            </label>
            {preview.view && <Box component="span" style={{marginLeft:"1rem"}}>
              <Button variant="outlined" component="span" onClick={clear} type="button">
                Clear image
              </Button>
            </Box>}
          </Box>

          <Box style={{marginBottom:"1rem"}}>
            <TextField id="caption" fullWidth placeholder="Caption" required name="caption" variant="outlined" />
          </Box>

          <Box>
            <Button variant="contained" onClick={uploadImage}>
              Upload
            </Button>
          </Box>
        </form>

      </Box>
    </Modal>

  </div>
  );
};

const _Menu = ({ image, index }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;

  const _delete = (index) => {
    // dispatch(deleteImage(index));
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        size="small"
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: "black" }} />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            _delete(index);
            handleClose();
          }}
        >
          <DeleteIcon style={{ color: "black" }} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UploadsFormTab;
