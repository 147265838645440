import {
  FormControl,
  InputLabel,
  List,
  ListItem,
  Paper,
  Select,
  MenuItem,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addorUpdateContainer,
  setContainerName,
  setContainerCategory,
  saveAsContainerTemplate,
  setTemplate,
} from "../../store/actions/containerActions";
import { BorderStyle } from "../borderstyle/BorderStyle";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useSelector } from "react-redux";
import { Autocomplete, Box } from "@mui/material";
import "./ContainerTab.scss";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SearchIcon from '@mui/icons-material/Search';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import "./ContainerTab.scss"

import { toast } from 'react-toastify';
//Api
import Api from "../../ApiRequests"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
/**
 * Container tab component which renders at the right panel
 * @param  selectedPage the page selected variable
 * @param container the container selected variable
 * @returns
 */
const ContainerTab = ({ selectedPage, container }) => {

  const [name, setName] = useState(container.name);
  const [containerCategory, setContCategory] = useState(container.category);
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabTemp, setActiveTabTemp] = useState(0);
  const dispatch = useDispatch();
  const containers = useSelector((state) => state.container.containers);
  const [isNewContainer, setIsNewContainer] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingTemp, setloadingTemp] = useState(false);
  const [catId, setcatid] = useState();
  const [openCat, setOpenCat] = React.useState(false);
  const [templates, settemplates] = React.useState([]);
  const [selectedCat, setselectedCat] = useState();
  const [tempId, settempid] = useState();

const [openCatZoom, setopenCatZoom]= useState(false);
const [openTempZoom, setopenTempZoom]= useState(false);

 const handleOpenCat = () => setOpenCat(true);
 const handleCloseCat = () => setOpenCat(false);

 const handleOpenCatZoom = () => setopenCatZoom(true);
 const handleCloseCatZoom = () => setopenCatZoom(false);

 const handleOpenTempZoom = () => setopenTempZoom(true);
 const handleCloseTempZoom = () => setopenTempZoom(false);

  const handleChange = (e) => {
    setName(e.target.value);
  };


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (name && containerCategory) {
      const containerObj = container;
      containerObj.name = name;
      containerObj.category = containerCategory;
      dispatch(addorUpdateContainer(selectedPage.index, containerObj, catId));
    } else {
      setOpenSnackbar(true);
    }
  };


  const search = (e) => {
    const keyword = e.target.value;
    const Data = JSON.parse(localStorage.getItem("categoryofcontainer"));

    if (keyword.trim() !== "") {
      const result = Data.filter((category) => {
        return category.name.toLowerCase().includes(keyword.toLowerCase());
      });
      setCategories(result);
    } else {
      setCategories(Data);
    }
  };

  useEffect(() => {
    setName(container.name);
  }, [container.name]);

  useEffect(() => {
    setContCategory(container.category);
  }, [container.category]);

  const handleCategoryChange = (value, id) => {
    setContCategory(value);
    setcatid(id);
  };

  useEffect(() => {
    setIsNewContainer(container.index > containers.length - 2);
  }, [container, containers]);

  const handleOnBorderChange = (key, value) => {
    container[key] = value;
  };

  useEffect(()=>{
    if(activeTab === 0)
    getcategoryofcontainer("global");

    else
    getcategoryofcontainer("myown")

  },[activeTab])

  useEffect(()=>{
    if(activeTabTemp === 0)
    getTemplates("global", selectedCat)

    else
    getTemplates("myown", selectedCat)

  },[activeTabTemp, selectedCat])

  const getcategoryofcontainer= async (type)=>{
        try{
          setloading(true);
          const res = await Api.CategoryContainer(type)
          setCategories(res.data.body.results)
           localStorage.setItem('categoryofcontainer', JSON.stringify(res.data.body.results));
          setloading(false);
        } catch (err){
          setloading(false);
          console.log(err.response);
        }
  }

  const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const styleZoom = {
position: 'absolute',
top: '50%',
left: '50%',
transform: 'translate(-50%, -50%)',
bgcolor: 'background.paper',
boxShadow: 24,
p: 4,
width: 550,
};

const addcategory = async (e)=>{
  e.preventDefault();
  const form = document.getElementById( "FormCat" )
  var formData = new FormData(form);

  let payload = {
    "categoryName": formData.get('categoryName')
  }
try{
  const res = await Api.createCategoryContainer(payload);
  toast.success('Category added', {
position: "top-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
});

if(activeTab === 0)
getcategoryofcontainer("global")
else
getcategoryofcontainer("myown")

  handleCloseCat();
} catch ( err){
  console.log(err.response);
  toast.error(`${err.response.data.message}`, {
position: "top-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
});
}
form.reset();
}

const getTemplates = async (type, id)=>{
  try{
    setloadingTemp(true);
    const res = await Api.getTemplates(type, id);
    settemplates(res.data.body.results);
    setloadingTemp(false);
  }catch(err){
    console.log(err.response);
    setloadingTemp(false);
  }
}



const usetemplate = async ()=>{
  if(!tempId) return alert("no")

  try{
    const res = await Api.getTemplateData(tempId);
    dispatch(setTemplate(res.data.body.bigJson));
  }catch(err){
    console.log(err.response);
  }
}

  return (
    <Paper>
      <form onSubmit={handleFormSubmit}>
        <Box className="cont-head brd-botm" style={{paddingBottom: "0"}}>
          <h4>{container.index} Container</h4>
        </Box>

        <Box className="cont-head">
          <h4>Name of the container</h4>
        </Box>
        <Box style={{padding:"0rem 2rem 1rem"}} className="justify-content-center align-items-center display-flex">
          <TextField
            type="text"
            variant="outlined"
            required
            multiline
            value={name}
            placeholder="Name...."
            onChange={handleChange}
            size="small"
            fullWidth
          />
        </Box>

        <Box className="cont-head">
          <h4>Category</h4>
        </Box>

        <Box style={{padding:"0rem 1.5rem"}}>
          <Tabs>
            <TabList style={{ marginBottom: 0, border: "0px" }}>
              <Tab
                style={{
                  backgroundColor: activeTab === 0 ? "orange" : "white",
                  borderColor: "#fff",
                  borderBottom: "1px solid #aaa",
                  color: activeTab === 0 ? "white" : "rgba(0,0,0,0.4)",
                  borderTopLeftRadius:"12px",
                  borderTopRightRadius:"12px"
                }}
                onClick={() => setActiveTab(0)}
              >
                Global
              </Tab>
              <Tab
                style={{
                  backgroundColor: activeTab === 1 ? "orange" : "white",
                  borderColor: "#fff",
                  borderBottom: "1px solid #aaa",
                  color: activeTab === 1 ? "white" : "rgba(0,0,0,0.4)",
                  borderTopLeftRadius:"12px",
                  borderTopRightRadius:"12px"
                }}
                onClick={() => setActiveTab(1)}
              >
                Private
              </Tab>
            </TabList>

            <TabPanel
              style={{ border: "1px solid orange" , position: "relative", top:"0" }}
            >
              <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
                <div className="search-comp display-flex">
                  <SearchIcon/>
                  <input type="text" placeholder="Search" onChange={search} />
                </div>
              </Box>
              {loading ? <Box className="load-box" >
                <CircularProgress color="inherit"/>
              </Box> : <List className="cat-list">
                {categories.map((category, index) => {
                  return (
                    <ListItem
                      key={`container-tab-list-item-${index}`}
                      button
                      selected={containerCategory === category.name}
                      onClick={() => {
                        handleCategoryChange(category.name,category.id );
                        setselectedCat(category.id)
                      }}
                    >
                      {category.name}
                    </ListItem>
                  );
                })}
              </List>}
              <Box className="zoom-icon" onClick={handleOpenCatZoom}><OpenInFullIcon /></Box>
            </TabPanel>
            <TabPanel
              style={{ border: "1px solid orange" , position: "relative", top:"0" }}
            >
              <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
                <div className="search-comp display-flex">
                  <SearchIcon/>
                  <input type="text" placeholder="Search"  onChange={search}/>
                </div>
              </Box>
              {loading ? <Box className="load-box" >
                <CircularProgress color="inherit"/>
              </Box> : <List className="cat-list">
                {categories.map((category, index) => {
                  return (
                    <ListItem
                      key={`container-tab-list-item-${index}`}
                      button
                      selected={containerCategory === category.name}
                      onClick={() => {
                        handleCategoryChange(category.name,category.id );
                        setselectedCat(category.id);
                      }}
                    >
                      {category.name}
                    </ListItem>
                  );
                })}
              </List>}
              <Box className="display-flex justify-content-center" style={{padding: " 1rem"}}>
                <Button className="fullwidth" onClick={handleOpenCat} >Create Category</Button>
              </Box>
              <Box className="zoom-icon" onClick={handleOpenCatZoom}><OpenInFullIcon /></Box>
            </TabPanel>
          </Tabs>
        </Box>
        <Box style={{padding:"1rem"}} className="brd-botm">
          <Button className="fullwidth" variant="contained" color="primary" type="submit">Add container</Button>
        </Box>
      </form>

      {container && (
        <BorderStyle
          type="container"
          container={container}
          isNewContainer={isNewContainer}
          onBorderChage={handleOnBorderChange}
        />
      )}

      <Box className="cont-head brd-top">
        <h4>Template</h4>
      </Box>

      <Box style={{padding:"1rem"}}>
        <Tabs>
          <TabList style={{ marginBottom: 0 }}>
            <Tab
              style={{
                backgroundColor: activeTabTemp === 0 ? "orange" : "white",
                borderColor: "#fff",
                borderBottom: "1px solid #aaa",
                color: activeTabTemp === 0 ? "white" : "rgba(0,0,0,0.4)",
                borderTopLeftRadius:"12px",
                borderTopRightRadius:"12px"
              }}
              onClick={() => setActiveTabTemp(0)}
            >
              Global
            </Tab>
            <Tab
              style={{
                backgroundColor: activeTabTemp === 1 ? "orange" : "white",
                borderColor: "#fff",
                borderBottom: "1px solid #aaa",
                color: activeTabTemp === 1 ? "white" : "rgba(0,0,0,0.4)",
                borderTopLeftRadius:"12px",
                borderTopRightRadius:"12px"
              }}
              onClick={() => setActiveTabTemp(1)}
            >
              Private
            </Tab>
          </TabList>

          <TabPanel
            style={{ border:  "1px solid orange" , position: "relative", top:"0" }}
          >
            {/* <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
              <div className="search-comp display-flex">
                <SearchIcon/>
                <input type="text" placeholder="Search"/>
              </div>
            </Box> */}

            {loadingTemp ? <Box className="load-box" >
              <CircularProgress color="inherit"/>
            </Box> : <List className={templates.length === 0 ? "no-list" : "cat-list"}>
              {templates.length === 0 ? <p>No templates found </p> : templates.map((template, index) => {
                return (
                  <ListItem
                    key={`container-tab-list-item-${index}`}
                    button
                    selected={template.id === tempId}
                    onClick={()=> settempid(template.id)}
                  >
                    {template.name}
                  </ListItem>
                );
              })}
            </List>}
            <Box className="zoom-icon" onClick={handleOpenTempZoom}><OpenInFullIcon /></Box>
          </TabPanel>
          <TabPanel
            style={{ border: "1px solid orange" , position: "relative", top:"0" }}
          >
            {/* <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
              <div className="search-comp display-flex">
                <SearchIcon/>
                <input type="text" placeholder="Search"/>
              </div>
            </Box> */}

            {loadingTemp ? <Box className="load-box" >
              <CircularProgress color="inherit"/>
            </Box> : <List className={templates.length === 0 ? "no-list" : "cat-list"} >
              {templates.length === 0 ? <p>No templates found </p> : templates.map((template, index) => {
                return (
                  <ListItem
                    key={`container-tab-list-item-${index}`}
                    button
                    selected={template.id === tempId}
                    onClick={()=> settempid(template.id)}
                  >
                    {template.name}
                  </ListItem>
                );
              })}
            </List>}
            <Box className="zoom-icon" onClick={handleOpenTempZoom}><OpenInFullIcon /></Box>
          </TabPanel>
        </Tabs>
      </Box>

      <Box style={{padding:"1rem"}} className="brd-botm">
        <Button className="fullwidth" variant="contained" color="primary" onClick={usetemplate}>Use this template</Button>
      </Box>

      <Modal
        open={openCat}
        onClose={handleCloseCat}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{width:500}}>
          <Box style={{display:"flex", justifyContent:"end", cursor:"pointer"}} onClick={handleCloseCat}>
            <CloseIcon/>
          </Box>
          <div>
            <h2>Create Category</h2>
          </div>
          <form onSubmit={addcategory} id="FormCat">
            <Box style={{marginBottom:"1rem"}}>
              <TextField fullWidth placeholder="Name of the category" required name="categoryName" variant="outlined" />
            </Box>

            <Box>
              <Button variant="contained" type="submit">
                Create
              </Button>
            </Box>
          </form>

        </Box>
      </Modal>



      {openCatZoom && <Modal
        open={openCatZoom}
        onClose={handleCloseCatZoom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
                      >
        <Box sx={styleZoom}>
          <Box style={{padding:"0rem 1.5rem"}}>
            <Tabs>
              <TabList style={{ marginBottom: 0 }}>
                <Tab
                  style={{
                    backgroundColor: activeTab === 0 ? "orange" : "white",
                    borderColor: "#fff",
                    borderBottom: "1px solid #aaa",
                    color: activeTab === 0 ? "white" : "rgba(0,0,0,0.4)",
                    borderTopLeftRadius:"12px",
                    borderTopRightRadius:"12px"
                  }}
                  onClick={() => setActiveTab(0)}
                >
                  Global
                </Tab>
                <Tab
                  style={{
                    backgroundColor: activeTab === 1 ? "orange" : "white",
                    borderColor: "#fff",
                    borderBottom: "1px solid #aaa",
                    color: activeTab === 1 ? "white" : "rgba(0,0,0,0.4)",
                    borderTopLeftRadius:"12px",
                    borderTopRightRadius:"12px"
                  }}
                  onClick={() => setActiveTab(1)}
                >
                  Private
                </Tab>
              </TabList>

              <TabPanel
                style={{ border: "1px solid orange" , position: "relative", top:"0" }}
              >
                <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
                  <div className="search-comp display-flex">
                    <SearchIcon/>
                    <input type="text" placeholder="Search" onChange={search} />
                  </div>
                </Box>
                {loading ? <Box className="load-box" >
                  <CircularProgress color="inherit"/>
                </Box> : <List className="cat-list">
                  {categories.map((category, index) => {
                    return (
                      <ListItem
                        key={`container-tab-list-item-${index}`}
                        button
                        selected={containerCategory === category.name}
                        onClick={() => {
                          handleCategoryChange(category.name,category.id );
                          setselectedCat(category.id)
                        }}
                      >
                        {category.name}
                      </ListItem>
                    );
                  })}
                </List>}
                <Box className="zoom-icon" onClick={handleCloseCatZoom}><CloseFullscreenIcon /></Box>
              </TabPanel>
              <TabPanel
                style={{ border: "1px solid orange" , position: "relative", top:"0" }}
              >
                <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
                  <div className="search-comp display-flex">
                    <SearchIcon/>
                    <input type="text" placeholder="Search"  onChange={search}/>
                  </div>
                </Box>
                {loading ? <Box className="load-box" >
                  <CircularProgress color="inherit"/>
                </Box> : <List className="cat-list">
                  {categories.map((category, index) => {
                    return (
                      <ListItem
                        key={`container-tab-list-item-${index}`}
                        button
                        selected={containerCategory === category.name}
                        onClick={() => {
                          handleCategoryChange(category.name,category.id );
                          setselectedCat(category.id);
                        }}
                      >
                        {category.name}
                      </ListItem>
                    );
                  })}
                </List>}
                <Box className="display-flex justify-content-center" style={{padding: " 1rem"}}>
                  <Button className="fullwidth" onClick={handleOpenCat} >Create Category</Button>
                </Box>
                <Box className="zoom-icon" onClick={handleCloseCatZoom}><CloseFullscreenIcon /></Box>
              </TabPanel>
            </Tabs>
          </Box>
        </Box>
      </Modal>}

      {openTempZoom && <Modal
        open={openTempZoom}
        onClose={handleCloseTempZoom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
                       >
        <Box sx={styleZoom}>
          <Box style={{padding:"1rem"}}>
            <Tabs>
              <TabList style={{ marginBottom: 0 }}>
                <Tab
                  style={{
                    backgroundColor: activeTabTemp === 0 ? "orange" : "white",
                    borderColor: "#fff",
                    borderBottom: "1px solid #aaa",
                    color: activeTabTemp === 0 ? "white" : "rgba(0,0,0,0.4)",
                    borderTopLeftRadius:"12px",
                    borderTopRightRadius:"12px"
                  }}
                  onClick={() => setActiveTabTemp(0)}
                >
                  Global
                </Tab>
                <Tab
                  style={{
                    backgroundColor: activeTabTemp === 1 ? "orange" : "white",
                    borderColor: "#fff",
                    borderBottom: "1px solid #aaa",
                    color: activeTabTemp === 1 ? "white" : "rgba(0,0,0,0.4)",
                    borderTopLeftRadius:"12px",
                    borderTopRightRadius:"12px"
                  }}
                  onClick={() => setActiveTabTemp(1)}
                >
                  Private
                </Tab>
              </TabList>

              <TabPanel
                style={{ border:  "1px solid orange" , position: "relative", top:"0" }}
              >
                <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
                  <div className="search-comp display-flex">
                    <SearchIcon/>
                    <input type="text" placeholder="Search"/>
                  </div>
                </Box>

                {loadingTemp ? <Box className="load-box" >
                  <CircularProgress color="inherit"/>
                </Box> : <List className={templates.length === 0 ? "no-list" : "cat-list"}>
                  {templates.length === 0 ? <p>No templates found </p> : templates.map((template, index) => {
                    return (
                      <ListItem
                        key={`container-tab-list-item-${index}`}
                        button
                        selected={template.id === tempId}
                        onClick={()=> settempid(template.id)}
                      >
                        {template.name}
                      </ListItem>
                    );
                  })}
                </List>}
                <Box className="zoom-icon" onClick={handleCloseTempZoom}><CloseFullscreenIcon /></Box>
              </TabPanel>
              <TabPanel
                style={{ border: "1px solid orange" , position: "relative", top:"0" }}
              >
                <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
                  <div className="search-comp display-flex">
                    <SearchIcon/>
                    <input type="text" placeholder="Search"/>
                  </div>
                </Box>

                {loadingTemp ? <Box className="load-box" >
                  <CircularProgress color="inherit"/>
                </Box> : <List className={templates.length === 0 ? "no-list" : "cat-list"} >
                  {templates.length === 0 ? <p>No templates found </p> : templates.map((template, index) => {
                    return (
                      <ListItem
                        key={`container-tab-list-item-${index}`}
                        button
                        selected={template.id === tempId}
                        onClick={()=> settempid(template.id)}
                      >
                        {template.name}
                      </ListItem>
                    );
                  })}
                </List>}
                <Box className="zoom-icon" onClick={handleCloseTempZoom}><CloseFullscreenIcon /></Box>
              </TabPanel>
            </Tabs>
          </Box>
        </Box>
      </Modal>}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          Name and Category are required fields
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ContainerTab;
