import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Sideleft.scss";
import { addPage } from "../../store/actions/pageActions";
import { selectContainer } from "../../store/actions/containerActions";
// import { Button } from "@material-ui/core";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { KeyboardArrowUp } from "@mui/icons-material";
import { Collapse, IconButton } from "@mui/material";
import { Search, SearchRounded } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Cancel";

import Skeleton from '@mui/material/Skeleton';

const Sideleft = () => {
  const categories = JSON.parse(localStorage.getItem("categoryofcontainer"));

  const loadingData =  useSelector((state)=> state.worksheet.load)

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [_containers, setContainers] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const handleContainers=()=>{
    if (category.trim() !== "" || name.trim() !== "") {
      if (category.trim() !== "" && name.trim() !== "") {
        setContainers(
          containers.filter((c) => {
            if (c.category && c.name) {
              return (
                c.category.indexOf(category) > -1 && c.name.indexOf(name) > -1
              );
            } else if (c.category) {
              return c.category.indexOf(category) > -1;
            } else if(c.name){
              return c.name.indexOf(name) > -1;
            }
          })
        );
      } else if (category.trim() !== "") {
        setContainers(
          containers.filter((c) => {
            if (c.category) return c.category.indexOf(category) > -1;
          })
        );
      } else if (name.trim() !== "") {
        setContainers(
          containers.filter((c) => {
            if (c.name) return c.name.indexOf(name) > -1;
          })
        );
      }
    } else setContainers(containers);
  }

  const dispatch = useDispatch();
  const containers = useSelector((state) => state.container.containers);

  useEffect(() => {
    handleContainers()
  }, [containers,name,category]);
  return (
    <div className="sideleft">
      {loadingData ? <Skeleton variant="rectangular" animation="wave" style={{height: "100vh"}}/>
      : <>
        <div className="header">
          <FilterListIcon />
          <h4>Filter</h4>
          {!showFilter && (
            <KeyboardArrowDownIcon onClick={() => setShowFilter(!showFilter)} />
          )}
          {showFilter && (
            <KeyboardArrowUp onClick={() => setShowFilter(!showFilter)} />
          )}
        </div>
        <Collapse in={showFilter} className="filterBox">
          <div className="searchContainer">
            <Search color="disabled" className="searchIcon" />
            <input
              type="text"
              value={name}
              onChange={(e)=>setName(e.target.value)}
              className="search"
              placeholder="Search Name"
              style={{ border: "none" }}
            />
            {name.length > 0 && (
              <IconButton onClick={() => {setName("");}} size="small">
                <CloseIcon style={{ color: "#ddd" }} fontSize="small" />
              </IconButton>
            )}
          </div>

          <div className="category">
            {/* <input
              type="text"
              placholder="container Category"
              value={category}
              onChange={handleCategorySearch}
            /> */}
            <h5>Category</h5>
            <select
              className="dropDown"
              value={category}
              onChange={(e)=>setCategory(e.target.value)}
            >
              {categories && categories.map((item) => (
                <option key={item.id} value={item.name}>{item.name}</option>
              ))}
            </select>
          </div>
        </Collapse>

        <br />

        {_containers.map((container, index) => {
          return (
            !container.hasAddButton && (
              <div key={index} className="Results">
                <a
                  href={`#${container.id}`}
                  onClick={(e) => {
                    dispatch(selectContainer(container.id));
                  }}
                  className={container.selected ? "Selected" : "Others"}
                >
                  {container.name ? (container.name.length < 7 ? container.name : `${container.name.substring(0,7)}...`) : "Conatiner name"}
                  {" < "}
                  {container.category ? (container.category.length < 15 ? container.category : `${container.category.substring(0,15)}...`) : "Category"}
                  {" > "}
                </a>
                <br />
              </div>
            )
          );
        })}
      </>}
    </div>
  );
};

export default Sideleft;
