import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import Skeleton from '@mui/material/Skeleton';
import { useSelector, shallowEqual } from "react-redux";
import {
  reorderContainers,
  reorderContainers1,
} from "../../store/actions/containerActions";

import Pagecontent from "../Pagecontent/Pagecontent";
import { resetServerContext } from "react-beautiful-dnd";

import "./Pages.scss";
import { calculContainersInPage } from "../../utils/container";
resetServerContext();
const Pages = ({ preview }) => {
  const pages = useSelector((state) => state.page.pages);
  const containers = useSelector((state) => state.container.containers);
  const loadingData =  useSelector((state)=> state.worksheet.load)
  const dispatch = useDispatch();

  const onDragEnd = (result) => {
    // console.log(result, "dskfvksbfjksdkjfjsdkjfj");
    if (!result.destination) {
      return;
    }

    const index_page_source = result.source.droppableId.split("page_number")[1];
    const index_page_destination =
      result.destination.droppableId.split("page_number")[1];
    // console.log(index_page_source, index_page_destination, result);
    dispatch(
      reorderContainers(
        calculContainersInPage(pages, index_page_source, containers)
          .containers_in_page[result.source.index].index,
        calculContainersInPage(pages, index_page_destination, containers)
          .containers_in_page[result.destination.index].index,
        index_page_source,
        index_page_destination
      )
    );
  };

  return (
    <div className="pages" id="pages">
      <DragDropContext onDragEnd={onDragEnd}>
        {pages.map((page, index, pages) => {
          const { containers_in_page } = calculContainersInPage(
            pages,
            index,
            containers
          );
          let boxshadow = page.selected
            ? "1px 1px 10px black"
            : "1px 1px 5px gray";
          if (preview) {
            boxshadow = null;
          }
          return (
            <div
              key={index}
              style={{
                width: page.width + 32,
                height: page.height + 32,
                padding: 16,
                backgroundColor: "white",
                marginBottom: preview ? 0 : 16,
                boxShadow: boxshadow,
              }}
            >


              { loadingData ?  <Skeleton variant="rectangular" animation="wave" style={{height: "100%"}} />
                :  <Pagecontent
                  preview={preview}
                  page={page}
                  index={index}
                  containers_in_page={containers_in_page}
                   />
                }
            </div>
          );
        })}
      </DragDropContext>
    </div>
  );
};

export default Pages;
