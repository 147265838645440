import React from "react";
import { initialValues } from "./constants";
import LoginWrapper from "../../FormWrapper/Login.jsx";
import { useDispatch } from "react-redux";
import { Login } from "../../../store/actions/auth";
import { useHistory } from "react-router-dom";

export default function SignInPage({routes}) {
  const history = useHistory()
  const dispatch = useDispatch()
  let onSignInSubmit = (values,loading) => {
  // console.log(values)
  dispatch(Login(values,history))
  };

  return (
    <LoginWrapper
      onSignInSubmit={onSignInSubmit}
      initialValues={initialValues}
    />
  );
}
