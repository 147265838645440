import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import "./App.scss";
import React from "react";
import routes from "./utils/routes";
import { Router, Switch, Redirect } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CustomRoute from "./components/pages/CustomRoute";
import { createBrowserHistory } from "history";
import { MathJaxContext } from "better-react-mathjax";
import { Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@mui/material/Alert";

import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// disable all react-beautiful-dnd development warnings
window["__react-beautiful-dnd-disable-dev-warnings"] = true;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const history = createBrowserHistory();
function App() {
  const { errorMessage,successMessage } = useSelector((state) => state.auth)

const dispatch = useDispatch();



  useEffect(() => {
    if (errorMessage) {
      setMessage(errorMessage);
      setSeverity("error");
      setOpen(true);
    }
    if (successMessage) {
      setMessage(successMessage);
      setSeverity("success");
      setOpen(true);
    }
  }, [errorMessage,successMessage]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  return (
    <div className="App">
      <ToastContainer />
      <MathJaxContext>
        <Router history={history}>
          <Switch>
            <Redirect exact from="/" to="/dashboard/All" />
            {routes.map((route) => {
              return <CustomRoute key={uuidv4()} {...route} />;
            })}
          </Switch>
        </Router>
      </MathJaxContext>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={() => {
          setOpen(false);
          dispatch({ type: "ERROR", payload: null });
          dispatch({ type: "SUCCESS", payload: null });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={() => {
            setOpen(false);
            dispatch({ type: "ERROR", payload: null });
            dispatch({ type: "SUCCESS", payload: null });
          }}
          severity={severity}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
