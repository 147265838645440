import React from "react";
import { Field, ErrorMessage } from "formik";
import ReactDOM from 'react-dom';

function InputField(props) {
  const { name, label,errorout, ...rest} = props;
  // const [value,setValue] = React.useState("")
  React.useEffect(()=>{
    //  console.log()
    document.querySelector(`input[name=${name}]`).click()
     // console.log(document.querySelector(`input[name=${name}]`).value)
  })
  // console.log(this)
  // console.log(ReactDOM.findDOMNode(this?.refs?.title)?.querySelector('input'))
  return (
    <div style={{width:"100%"}}>
      <Field
        style={{
          border: "none",
          outline: "none",
          width:"100%"
        }}
        variant="outlined"
        name={name}
        {...rest}
        // value={value}
        // onChange={(e)=>setValue(e.target.value)}
        // ref='title'
        autoComplete={name}

      />
      {!errorout&&<ErrorMessage component="div" style={{color:"red",fontSize:"xx-small",position:"absolute"}} name={name} />}
    </div>
  );
}
export default InputField;
