import { useSelector } from "react-redux";

const useSave = ()=>{
  const page = useSelector((state) => state.page);
  const container = useSelector((state) => state.container);
  const worksheet = useSelector((state) => state.worksheet);

  const payload = {
    dummyData: {
      page: page,
      container: container
    }
  }

  return {worksheet, payload}
}


export default useSave;
