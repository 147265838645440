import React from "react";
import { useRef, useEffect } from "react";

import { useState } from "react";
import TextEditor from '../texteditor/TextEditor';

import {setEditior} from "../../store/actions/editActions"
import {setTableCeilText , setNbTableRows , setNbTableColumns , setBoxHeight} from "../../store/actions/containerActions"
import { useDispatch, useSelector  } from "react-redux";

import "./style.scss"


import Box from '@mui/material/Box';

const TableEditor = ({ box  ,  selectedPage}) => {
    const dispatch = useDispatch()
    const height = 120;

    useEffect(()=>{
      dispatch(setEditior(selectedCeil.text || ""))
    },[])


  const pageContentHeight = selectedPage.pageContentHeight;
  const tableRef = useRef();



  const [selectedCeil, setSelectedCeil] = useState({});


  const setData = (html)=>{
      dispatch(setTableCeilText(box ,  selectedCeil , html));


    const rowHeight = height / 3;

    const contentHeight = document.getElementById(box.id).offsetHeight;

    if (box.h > pageContentHeight / rowHeight - 0.3) {
      //alert('error')
      return;
    }

    if (box.h * rowHeight < contentHeight) {
      document.getElementById(box.id).parentElement.parentElement.style.height =
        document.getElementById(box.id).offsetHeight + "px";
      dispatch(setBoxHeight(selectedPage, box, contentHeight / rowHeight));
    }

  }
  return (
    <div>

      <Box className="head" style={{padding:"0rem 1rem"}}>
        <h5>Table Size</h5>
      </Box>

      <Box className="table-edit">
        <Box>
          <p>Number Of columns</p>
          <div className="input">
            <input
              type="number"
              min={1}
              value={box.nbColumns}
              onChange={(e) => { dispatch(setNbTableColumns(box, parseInt(e.target.value || 0))) }}
            />
          </div>

          <p>Number Of rows</p>

          <div className="input">
            <input
              type="number"
              min={1}
              value={box.nbRows}
              onChange={(e) => dispatch(setNbTableRows(box, parseInt(e.target.value || 0)))}
            />
          </div>

        </Box>

        <Box className="table-show">
          {<table ref={tableRef} style={{ width: "100%" }}>
            {box.table.map((row, i) => {
              return (
                <tr key={`sideright-table-tr-${i}`}>
                  {row.map((ceil, j) => {
                    return (
                      <td
                        onClick={()=>{
                          setSelectedCeil({i  , j , ceil})
                          dispatch(setEditior(box.table[i][j].text))
                        }}
                        key={`sideright-table-td-${j}`}
                        style={{
                            cursor : "pointer",
                          border: `solid ${selectedCeil. i == i && selectedCeil.j === j ? "green 3px" :  "black 1.5px"}`,

                          minWidth: `${
                            tableRef.current
                              ? tableRef.current.offsetHeight / box.nbColumns
                              : 50
                          }px`,
                        }}
                      ></td>
                    );
                  })}
                </tr>
              );
            })}
          </table>}
        </Box>

        {<TextEditor  disable={selectedCeil.text !== undefined} setData={setData} table={true}/>}
      </Box>






    </div>
  );
};

export default TableEditor;
