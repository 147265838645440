import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  deleteBox,
  setBoxHeight,
  setBoxText,
  resetBoxType
} from "../../store/actions/containerActions";

import {setEditior, clearTxtEditor} from "../../store/actions/editActions"
import { BorderStyle } from "../borderstyle/BorderStyle";
import TextEditor from "../texteditor/TextEditor";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

//icons
import DeleteIcon from "@material-ui/icons/Delete";

import "./style.scss"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

const EditTextBox = ({ selectedPage, box }) => {
  const height = 120;
  const dispatch = useDispatch();
  const [error, seterror] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(()=>{
    dispatch(setEditior(box.text))
  },[box])

  const pageContentHeight = selectedPage.pageContentHeight;

  const setData = (html) => {
    const contentHeight = document.getElementById(box.id).offsetHeight;
    const rowHeight = height / 3;
    dispatch(setBoxText(box, html));

    if (box.h * rowHeight > contentHeight) {
    seterror(false);
  } else {
    seterror(true);
  }



    if (box.h > pageContentHeight / rowHeight - 0.3) {
      //alert('error')
      return;
    }

    if (box.h * rowHeight < contentHeight) {
      document.getElementById(box.id).parentElement.parentElement.style.height =
        document.getElementById(box.id).offsetHeight + "px";
      dispatch(setBoxHeight(selectedPage, box, contentHeight / rowHeight));
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>

      <Box className="display-flex box-head brd-botm">

        <Button
          variant="outlined"
          onClick={()=>{
            dispatch(resetBoxType(box,false));
            dispatch(setBoxText(box, ""));
          }}
        >
          Reset
        </Button>

        <Button
          variant="outlined"
          onClick={() => {
            dispatch(setBoxText(box, ""));
            dispatch(clearTxtEditor());
          }}
        >
          clear
        </Button>

        <Button
          variant="outlined"
          color="error"
          onClick={() => dispatch(deleteBox(box))}
        >
          {/* <DeleteIcon fontSize="small" /> */}
          Delete Box
        </Button>
      </Box>
      <Box className="head">
        <h4>
          Box {box.index + 1} : {box.type}
        </h4>
      </Box>
      {error && <p style={{color:"red"}}>*Please increase the box height to accommodate the Input</p>}
      <Box className="brd-botm icon-parent" style={{padding:"0rem 1rem 1rem"}}>
        {<TextEditor setData={setData} />}
        {/* <Box className="zoom-icon-box" onClick={handleOpen}><OpenInFullIcon /></Box> */}
      </Box>

      <BorderStyle type="container" box={box} />


      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
          <Box className="bjbjhkbj" style={{padding:"0rem 1rem 1rem"}}>

        <Box className="zoom-icon-box" onClick={handleClose}><OpenInFullIcon /></Box>
          </Box>
        </Box>
      </Modal>  */}

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Set backup account</DialogTitle>
        <Box style={{padding:"0rem 1rem 1rem"}}>
          {<TextEditor setData={setData} />}
          <Box className="zoom-icon-box" onClick={handleClose}><CloseFullscreenIcon /></Box>
        </Box>
      </Dialog>
    </div>
  );
};
export default EditTextBox;
