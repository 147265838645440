import React, { useEffect, useState } from "react";

//redux
import { connect } from "react-redux";
import {setEditior} from "../../store/actions/editActions"

import ReactQuill, { Quill } from "react-quill";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module-react";

import ImageCompress from "quill-image-compress";
import "./TextEditor.scss";

import AddIcon from '@mui/icons-material/Add';

import CustomToolbar from "./CustomToolbar"

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);

Quill.register("modules/imageDrop", ImageDrop);

var Font = Quill.import("formats/font");
Font.whitelist = ["monospace", "arial","Roboto", "Raleway", "Montserrat", "Lato", "Rubik"];
Quill.register(Font, true);

function insertHeart() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "♥");
  this.quill.setSelection(cursorPosition + 1);
}

class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.editor = React.createRef();
  }



  handleChange = (html) => {
    this.props.setEditior(html);
    this.props.setData(html);
    // console.log(html==='<p><br></p>')
    if(this.props.state.showFormulaBox)
    {
      if(html==='<p><br></p>')
      this.props.setLength(0)
      else
      this.props.setLength(this.editor.current.unprivilegedEditor.getLength()-1)
    }
  };
  checkCharacterCount = (event,html) =>{
    if(this.props.state.showFormulaBox)
    {
      if (this.editor.current.unprivilegedEditor.getLength() > 2000 && event.key !== 'Backspace')
      event.preventDefault();
    }
  }
  handleBoxSizing() {}

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  insertToEditor = (url) => {
    const quill = this.editor.current.getEditor();
    const range = quill.getSelection().index;
    quill.insertEmbed(range, "image", url);
    quill.formatText(range, 1, "width", 500);
    quill.setSelection(range + 1);
  };

  listImage = () => {
    const quill = this.editor.current.getEditor();
    const range = quill.getSelection().index;
    // quill.formatLine(range - 1, 'list', 'bullet');
    var data = `<ul class="image" ><li class="image">test</li></ul>`;
    quill.clipboard.dangerouslyPasteHTML(data);
  };

  modules = {
    toolbar: {
      container: `#toolbar`,
      handlers: {
        insertHeart: insertHeart,
        inserImage: () => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.click();

          // Listen upload local image and save to server
          input.onchange = () => {
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
              this.toBase64(file).then((url) => {
                // this.props.addImage(url);
                this.insertToEditor(url);
              });
            } else {
              console.warn("You could only upload images.");
            }
          };
        },
        listImage: this.listImage,
      },
    },
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
    imageCompress: {
      quality: 1, // default
      maxWidth: 200, // default
      maxHeight: 200, // default
      debug: true, // default
      suppressErrorLogging: false, // default
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  static formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code",
    "background",
    "script",
    "width",
    "align",
    "link",
    "direction",
    "height",
  ];

  addText = (t) => {
    const quill = this.editor.current.getEditor();
    const format = quill.getFormat();
    const range = quill.getSelection();
    const text = `\\(` + t + `\\)`;

    quill.insertText(range, text, format);
  };

  uploadFile = () => {
    return new Promise((resolve, reject) => {});
  };


  render() {
    return (<>
      <div className="text-editor">
        <CustomToolbar table={this.props.table || false}/>

        {this.props.state.formulaBox && (
          <div className="formulabox">
            <button className="btn-frmula" onClick={() => this.addText("Sin(x)")}>Sin</button>
            <button className="btn-frmula" onClick={() => this.addText("Cos(x)")}>Cos</button>
            <button className="btn-frmula" onClick={() => this.addText("Tan(x)")}>Tan</button>
            <button className="btn-frmula" onClick={() => this.addText("Hyp(x)")}>Hyp</button>
            <button className="btn-frmula" onClick={() => this.addText("(")}>(</button>
            <button className="btn-frmula" onClick={() => this.addText(")")}>)</button>
            <button className="btn-frmula" onClick={() => this.addText("log(x)")}>log</button>
            <button className="btn-frmula" onClick={() => this.addText("√ ")}>&radic;</button>
            <button className="btn-frmula" onClick={() => this.addText("π")}>&#x3C0;</button>
            <button className="btn-frmula" onClick={() => this.addText("e")}>e</button>
            <button className="btn-frmula" onClick={() => this.addText("%")}>%</button>
          </div>
        )}

        <ReactQuill
          ref={this.editor}
          onKeyDown={this.checkCharacterCount}
          value={this.props.state.editorHtml}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          modules={this.modules}
          formats={TextEditor.formats}
          className="quill-edit"
        />

      </div>
    </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // addImage: (url) => {
    //   dispatch(addImage(url));
    // },
    setEditior: (payload) => {
      dispatch(setEditior(payload));
    },
  };
};

const mapStateToProps  = (state) => ({
  state:state.texteditor
});

export default connect(mapStateToProps , mapDispatchToProps)(TextEditor);
