import { v4 as uuidv4 } from "uuid";

const initState = {
  activeTab: "container",
  pages: [
    {
      per_notes: [],
      id: uuidv4(),
      style: {
        borderStyle: "none",
        backgroundColor:"transparent",
        borderColor:"transparent"
      },
      height: 1122 - 32,
      width: 794 - 32,
      pageContentHeight: 1020,
      selected: true,
      footerHeight: 70,
      headerHeight: 0,
      index: 0,
      heightLeft: 1020,
    },
  ],
};

const pageReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_PAGE":
      return {
        ...state,
        pages: [
          ...action.payload,
          {
            per_notes: [],
            id: uuidv4(),
            style: {
              borderStyle: "none"
            },
            height: 1122 - 32,
            width: 794 - 32,
            pageContentHeight: 885,
            selected: true,
            footerHeight: 70,
            headerHeight: 135,
            index: state.pages.length,
            heightLeft: 885,
          },
        ],
      };
    case "DELETE_PAGE":
      console.log(state.pages, action.payload);
      const page = state.pages
        .map((page, index) => {
          if (action.payload - 1 === index) page.selected = true;
          else page.selected = false;
          return page;
        })
        .filter((page, index) => index !== action.payload);
      console.log(page);

      return { ...state, pages: page };

    case "ACTIVE_TAB":
      return { ...state, activeTab: action.payload };

    case "DELETE_PER_NOTE": {
      return { ...state, pages: action.payload };
    }

    case "SELECT_PAGE":
      console.log(action.payload);
      return { ...state, pages: action.payload };

    case "SET_PAGE_STYLE":
      return { ...state, pages: action.payload };
    case "ADD_PER_NOTE":
      return { ...state, pages: action.payload };

    case "CHANGE_HEIGHT":
      return { ...state, pages: [...action.payload] };

    case "LOAD_PAGE":
    return { ...action.payload }
    default:
      return state;
  }
};

export default pageReducer;
