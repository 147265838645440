
import { combineReducers } from 'redux';


import containerReducer from './containerReducer';
import pageReducer from './pageReducer';
import {TextEditorReducer} from "./EditReducer"
import authReducer from './auth';
import worksheetReducer from './worksheet';

const rootReducer = combineReducers({
  page: pageReducer,
  container: containerReducer,
  texteditor:TextEditorReducer,
  auth:authReducer,
  worksheet:worksheetReducer

});

export default rootReducer;
