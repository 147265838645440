import React from "react";
import { useDispatch } from "react-redux";
import { deletePerNote } from "../../store/actions/pageActions";
import "./PersonnelNote.scss";
const PersonnelNote = ({ page, height, pageIndex }) => {
  return (
    <>
      {page.per_notes?.map((per_note, index) => {
        return (
          <div
            className={`page-${page.index}-perNote-${index} perNote`}
            id={`page-${page.index}-perNote-${index}`}
            style={{
              height: `${per_note.isAuto ? per_note.height + "px" : "auto"}`,
              border: "1px solid black",
              borderLeft: "4px double black",
            }}
            key={index}
          >
            <h3 style={{ padding: 0, margin: 0 }}>{per_note.text}</h3>
          </div>
        );
      })}
    </>
  );
};

export default PersonnelNote;
