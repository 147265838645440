import React from "react";
import "./Footer.scss";

const Footer = ({ index, page }) => {
  // console.log(page.style.icon);
  return (
    <div
      className="footer"
      style={{
        height: page.footerHeight,
        minHeight: page.footerHeight,
        maxHeight: page.footerHeight,
      }}
    >
      <div className="footer-index">
        <h3 style={{ color: "#222" }}>{index + 1}</h3>
      </div>
      {page.style.icon && <div classname="footer-img"> <img src={page.style.icon}/></div>}
    </div>
  );
};

export default Footer;
