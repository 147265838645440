import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.scss";
// import Api from "../../../../ApiRequests";
import { Delete } from "@material-ui/icons";
import { Form, Formik } from "formik";
import { validationSchemaPass } from "../../../Form/validation/resetPassword";
import FormikController from "../../../Form/FormControl";
import { Button } from "@mui/material";

const DeleteAccountModal = ({
  open,
  setOpen,
  setCreated,
  deleteWorksheet,
  id,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const initialState = {
    email:"na@gm.com",
    newPassword: "",
    confirmPassword: "",
  };

  return (
    <Dialog maxWidth="sm" onClose={handleClose} open={open} fullWidth={true}>
      <div className={styles.modal}>
        <h3 className={styles.heading} style={{ display: "flex" }}>
          <Delete />
          {"  "}Delete Account
        </h3>
        <div className={styles.underline}></div>
        <div style={{ margin: "1rem 15%" }}>
          Enter Your Password to proceed
        </div>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchemaPass}
          onSubmit={(values) => console.log(values)}
        >
          {({ dirty, isValid }) => (
            <Form style={{ margin: "0 15%" }}>
              {" "}
              <div className={styles.password}>
                <div className={styles.text} style={{ marginTop: "5px" }}>
                  <label className={styles.topic}>Password</label>
                  <FormikController
                    id="outlined-textarea"
                    //label="Project Title here..."
                    variant="outlined"
                    style={{ border: "1px solid #FFA737" }}
                    className={styles.input}
                    name="newPassword"
                    control="password"
                  />
                </div>
                <div className={styles.text} style={{ marginTop: "10px" }}>
                  <label className={styles.topic}>Confirm Password</label>
                  <FormikController
                    id="outlined-textarea"
                    //label="Project Title here..."
                    variant="outlined"
                    style={{ border: "1px solid #FFA737" }}
                    className={styles.input}
                    name="confirmPassword"
                    control="password"
                  />
                <div className={styles.btns}>
                  {/* {   console.log(isValid,dirty,values)} */}
                  <Button
                    disabled={!isValid||!dirty}
                    type="submit"
                    className={styles.btn2}
                    variant="contained"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Delete
                  </Button>
                  <button
                    type="reset"
                    className={styles.btn1}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <div></div>
      </div>
    </Dialog>
  );
};

export default DeleteAccountModal;
