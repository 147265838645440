//For TextEditor

export const setEditior = (payload)=>{
  return{
    type:"change",
    payload:payload
  }
}

export const showFormulaBox =()=> {
  return {
    type:"showFormulaBox"
  }
}

export const clearTxtEditor =()=>{
  return {
    type:"clear_Txt_Editor"
  }
}
