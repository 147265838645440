import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { calculContainersInPage } from "../../utils/container";
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";
import ReactDOMServer from "react-dom/server";
import Pages from "../pages/Pages";
import rootReducer from "../../store/reducers/rootReducer";
import { Preview } from "@mui/icons-material";


const PreviewPdf = () => {
  const [_pages, _setPages] = useState([]);
  const pages = useSelector((state) => state.page.pages);
  const containers = useSelector((state) => state.container.containers);
  const [_html, _setHtml] = useState("");

  useEffect(() => {
    const _pages = pages.map((page, index) => {
      page.containers =
        calculContainersInPage(pages, index, containers).containers_in_page ||
        [];
      return page;
    });

    _setPages(_pages);

    let html = "";

    _pages.map((page, index) => {
      html += `

      <div style="height : ${page.height + 32}px; width : ${
        page.width + 32
      }px; padding :16px; background-color : white;print-color-adjust: exact;">
      <div class="page" style="${setStyle(
        page.style
      )}; display: flex; flex-direction : column;">

        ${setHeader(index)}

        ${setContent(page, index)}

        </div>


        </div>`;
    });

    _setHtml(html);

    setTimeout(() => {
      const allCSS = [...document.styleSheets]
        .map((styleSheet) => {
          try {
            return [...styleSheet.cssRules]
              .map((rule) => rule.cssText)
              .join("");
          } catch (e) {
            console.log(
              "Access to stylesheet %s is denied. Ignoring...",
              styleSheet.href
            );
          }
        })
        .filter(Boolean)
        .join("\n");

      const prtContent = document.getElementById("pages");
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(
        `  <style>${allCSS}</style> ${prtContent.innerHTML}`
      );
      WinPrint.document.close();
      WinPrint.focus();
      // WinPrint.print();
      // WinPrint.close();
    }, 3000);

    setTimeout(() => {
      // pdfWindow.document.close();
      // pdfWindow.print();
      // pdfWindow.close();
    }, 3000);
  }, []);

  const setHeader = (index) => {
    if (index === 0)
      return `<div class='header'>

      <p class="header-title">INSTITUTION NAME</p>


      <p class="header-sous-title">Tutor : John Doe</p>
      <p class="header-text">Cell | +91-8858150032 | Email | johdoe@loren.com | Website | www.loren.com </p>
      <p class="header-text">Semester 1: Physics 3⁄4...</p>


    </div>`;
    return ` <div  class='header-2' >

      <p class="header-title-2">INSTITUTION NAME</p>


 <div >

 <p  class="header-text"><b>Tutor</b> : John Doe</p>
 <p  class="header-text"><b>Website</b> : www.loren.com</p>
 <p  class="header-text"><b>Phone</b> : +91-8858150032</p>
 <p  class="header-text"><b>Email</b> : johdoe@loren.com </p>

   <p  class="header-text"><b>Note By</b> : Ravi Sharma</p>
 </div>

      </div>`;
  };

  const setTable = (box) => {
    let html = "";
    box.table.map((row, i) => {
      html += "<tr>";

      row.map((ceil, j) => {
        html += `<td style="cursor :pointer; width : ${
          (box.h * (120 / 3)) / box.nbColumns
        }" >
          <div class="ql-editor" style="padding : 4px;">${ceil.text}</div>
          </td>`;
      });
      html += "</tr>";
    });
    return html;
  };
  const setBoxContent = (box) => {
    let html = "";

    switch (box.type) {
      case "math":
        const mathjax = (
          <MathJax
            math={String.raw`${"" + box.text + ""}`}
            style={{ whiteSpace: "pre-line" }}
          />
        );

        setTimeout(() => {
          const htmlString = ReactDOMServer.renderToStaticMarkup(mathjax);
          console.log(htmlString);
        }, 3000);

        html += `<div class="ql-editor">${box.text}</div>`;
        break;

      case "text":
        html += ` <div class="ql-editor">${box.text}</div>`;
        break;

      case "image":
        html += `
          <div
          style="height : ${box.h * (120 / 3)}px;

          position : relative
          "
          >
          <img src="${box.image}" style="width : 100%;height : ${
          box.h * (120 / 3)
        }px;" />
          <div
          style="position : absolute; bottom : 0; display : flex ; justify-content : center; align-items : center ; width :100%;"
          >
          <span style="color :lightgray;">${box.caption}</span>
          </div>

          </div>

         `;
        break;

      case "line":
        html += `<div style="min-height : ${
          box.h * (120 / 3)
        }px ; display : flex ; flex-direction: column; justify-content : center ; padding-left : 25px; padding-right : 25px">
        ${Array(box.nbLines)
          .fill(null)
          .map(
            (line) => `<hr style="margin-bottom : 16px;border-style: solid;
          border-color: black;color: black;background-color :black" />`
          )
          .join("")}
        </div>`;
        break;

      case "table":
        html += `<table style="width : 100%; height: 100%">
        ${setTable(box)}
        </table>`;

        break;

      default:
        break;
    }

    return html;
  };
  const setBoxes = (boxes, width) => {
    let html = "";
    boxes.map((box) => {
      html += `<div style="position:absolute;height:${
        box.h * (120 / 3)
      }px;background-color:${
        box.backgroundColor || "transparent"
      };border-style:${box.borderStyle || "none"};border-color:${
        box.borderColor || "white"
      };width:${box.w * (width / 12)}px;max-width:${
        box.w * (width / 12)
      }px;min-width:${box.w * (width / 12)}px;transform: translate(${
        box.x * (width / 12)
      }px, ${box.y * (120 / 3)}px);
      ">
        <div id="${box.id}" class="box-content">

        ${setBoxContent(box)}
        </div>
        </div>`;
    });
    return html;
  };
  const setContainers = (containers, width) => {
    let html = "";
    containers.map((container) => {
      html += !container.hasAddButton
        ? `<div style="position: relative;height : ${
            container.height
          }px ; background-color : ${
            container.backgroundColor || "transparent"
          } ; border-style : ${
            container.borderStyle || "none"
          } ; border-color : ${container.borderColor || "white"}; ">
${setBoxes(container.boxes, width)}

</div>`
        : "";
    });
    return html;
  };

  const setContent = (page, index) => {
    return `<div class="page-content" style="display:flex;flex-direction :column;border-bottom : 1px solid gray;height: ${
      page.pageContentHeight
    }px; min-height: ${page.pageContentHeight}px; max-height: ${
      page.pageContentHeight
    }px;">

        ${setContainers(page.containers, page.width)}

        </div>`;
  };
  const setStyle = (style) => {
    return `background-color : ${style.backgroundColor} ; border-style : ${style.borderStyle} ; border-color : ${style.borderColor};`;
  };

  return (
    <div>
      <div
        id="myPdf"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "0.5rem",
        }}
      >
        <Pages preview={true} />
      </div>
    </div>
  );
};

export default PreviewPdf;
