export const getSelectedBox  = (containers)=>{
    let selectedBox = {}
  

        containers.map((container, index)=>{

            if(container.boxes){
                container.boxes.map(box=>{
                    if(box.selected){
                        selectedBox = {...box}
                    }
                })
            }
           
          
           
        })
    
  

    return selectedBox
}