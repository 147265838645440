import React from "react";
import NavDash from "../../navbar/DashboardNav";
import Navbar from "../../navbar/Navbar";

export default function DefaultLayout({ children }) {
  const location = window.location.pathname
  return (
    <>
      {location.includes('/home/')||location.includes('/preview')?<Navbar/>:<NavDash/>}
      <div>{children()}</div>
    </>
  );
}
