import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("*Required"),
  fullname: Yup.string().required("*Required"),
  title:Yup.string().required("*Required"),
  website:Yup.string().required("*Required"),
//   phoneNumber:Yup.string().required("Required").matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,"Not a valid phone number"),
  notesBy:Yup.string().required("*Required"),
  type:Yup.string().required("*Required"),
//   logo:Yup.string().required("Required"),
  profile:Yup.string().required("*Required")
});
