import { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { validationSchema } from "../../../Form/validation/createWorksheet";
import styles from "./styles.module.scss";
import Api from "../../../../ApiRequests";
import { Formik, Field, ErrorMessage, Form } from "formik";
import FormikController from "../../../Form/FormControl";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import { CloseOutlined, Search, SearchRounded } from "@material-ui/icons";

const currencies = [
  {
    value: "Weekly",
    label: "Weekly",
  },
  {
    value: "Homework",
    label: "Homework",
  },
];
const profile = [
  { value: "Teacher", label: "Teacher" },
  { value: "Tutor", label: "Tutor" },
];
const CreateWorksheetModal = ({ open, setOpen, setCreated }) => {
  const [currency, setCurrency] = useState("item0");
  const [text, setText] = useState("");
  const [breadcrumb, setBreadcrumb] = useState(1);
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [uploadedImgs, setUploadedImgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chosenImage, setChosenImage] = useState(null);
  const ref = useRef();
  let initialValues = {
    title: "",
    profile: "",
    fullname: "",
    website: "",
    phoneNumber: "",
    email: "",
    notesBy: "",
    type: "",
    logo: "",
  };
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = ref.current;
    console.log(
      scrollTop + clientHeight >= scrollHeight - 5 && !loading && total > count
    );
    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !loading &&
      total > count
    ) {
      setLoading(true);
      loadImages();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const loadImages = () => {
    setLoading(true);
    Api.getImages({ limit: 18, offset: count, type: "myown" }).then((res) => {
      setUploadedImgs([...uploadedImgs, ...res.data.body.results]);
      setTotal(res.data.body.count);
      setCount(count + res.data.body.results.length);
      setLoading(false);
    });
  };
  useEffect(() => {
    if (open) loadImages();
  }, [open]);
  const handleImage = (e) => {
    // console.log("reached");
    setImage(e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]));
  };

  const uploadImage = () => {
    const form = new FormData();
    form.append("image", image);
    form.append("caption", "Logo");
    Api.uploadImage(form).then((res) => {
      loadImages();
      setChosenImage({
        caption: res.data.body.caption,
        id: res.data.body.id,
        src: res.data.body.imageurl,
      });
      setImage(null);
      setImageURL(null);
      setBreadcrumb(2);
    });
  };
  const chooseImage = (caption, id, src) => {
    setChosenImage({ caption, id, src });
    setBreadcrumb(2);
  };

  const cancelImage = () => {
    setChosenImage(null);
    setBreadcrumb(0);
  };
  const createWorksheet = (values) => {
    values = { ...values, logo: chosenImage.id };
    Api.createWorksheet(values).then((res) => {
      // console.log("Changed");
      setCreated(res.data.body);
      setBreadcrumb(1);
      setChosenImage(null);
    });
  };

  return (
    <Dialog maxWidth="sm" onClose={handleClose} open={open} fullWidth={true}>
      <div className={styles.modal}>
        <h3 className={styles.heading}>
          <img src="/img/9.svg" alt="" /> Create Worksheet
        </h3>
        <div className={styles.underline}></div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              createWorksheet(values);
            }}
          >
            {({ dirty, isValid, values, handleChange, handleBlur }) => (
              <Form>
                <div className={styles.details}>
                  <div className={styles.colomn}>
                    <div className={styles.inputbox}>
                      <label className={styles.label}>Project Title</label>
                      <FormikController
                        id="outlined-textarea"
                        //label="Project Title here..."
                        variant="outlined"
                        //
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                        name="title"
                        control="input"
                      />
                    </div>
                    <div className={styles.inputbox}>
                      <label className={styles.label}>Full Name</label>
                      <FormikController
                        id="outlined-textarea"
                        //label="Project Title here..."
                        variant="outlined"
                        //
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                        name="fullname"
                        control="input"
                      />
                    </div>

                    <div className={styles.inputbox}>
                      <label className={styles.label}>Profile</label>
                      <FormikController
                        name="profile"
                        id="outlined-textarea"
                        //label="Website link here..."
                        variant="outlined"
                        style={{ border: "1px solid #FFA737" }}
                        control="select"
                        className={styles.input}
                        options={profile}
                      />
                    </div>

                    <div className={styles.inputbox}>
                      <label className={styles.label}>Notes By</label>
                      <FormikController
                        name="notesBy"
                        id="outlined-textarea"
                        //label="Author name here..."
                        variant="outlined"
                        control="input"
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                      />
                    </div>
                  </div>

                  <div className={styles.colomn}>
                    <div className={styles.inputbox}>
                      <label className={styles.label}>Email</label>
                      <FormikController
                        name="email"
                        id="outlined-textarea"
                        //label="Email here..."
                        control="input"
                        type="email"
                        variant="outlined"
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                      />
                    </div>

                    <div className={styles.inputbox}>
                      <label className={styles.label}>Phone Number</label>
                      <FormikController
                        name="phoneNumber"
                        id="outlined-textarea"
                        //label="Phone Number here..."
                        type="number"
                        variant="outlined"
                        control="input"
                        style={{ border: "1px solid #FFA737" }}
                        className={styles.input}
                      />
                    </div>

                    <div className={styles.inputbox}>
                      <label className={styles.label}>Website</label>
                      <FormikController
                        name="website"
                        id="outlined-textarea"
                        //label="Website link here..."
                        variant="outlined"
                        style={{ border: "1px solid #FFA737" }}
                        control="input"
                        className={styles.input}
                      />
                    </div>

                    <div className={styles.inputbox}>
                      <label className={styles.label}>Worksheet Type</label>
                      <FormikController
                        name="type"
                        id="outlined-textarea"
                        //label="Website link here..."
                        variant="outlined"
                        style={{ border: "1px solid #FFA737" }}
                        control="select"
                        className={styles.input}
                        options={currencies}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.uploadSection}>
                  <h1>Select Logo</h1>
                  {breadcrumb === 2 ? (
                    <div
                      style={{
                        width: "30%",

                        cursor: "pointer",
                        border: "1px solid #FFA737",
                        borderRadius: "5px",
                        background: "#eae0e0",
                        padding: "2px",
                        position: "relative",
                      }}
                    >
                      <img
                        src={chosenImage.src}
                        alt="pic"
                        style={{ width: "100%", height: "5rem" }}
                      ></img>
                      <IconButton
                        onClick={cancelImage}
                        size="small"
                        style={{ position: "absolute", left: 0 }}
                      >
                        <CloseOutlined
                          style={{
                            color: "rgb(119 119 131)",
                            border: " 1px solid",
                            borderRadius: "50%",
                            background: "#f1f1f1c4",
                          }}
                          fontSize="small"
                        />
                      </IconButton>
                    </div>
                  ) : (
                    <div className={styles.uploadOptions}>
                      <p
                        className={breadcrumb === 0 ? styles.selected : ""}
                        onClick={() => setBreadcrumb(0)}
                      >
                        Media
                      </p>
                      <p
                        className={breadcrumb === 1 ? styles.selected : ""}
                        onClick={() => setBreadcrumb(1)}
                      >
                        Upload Files
                      </p>
                    </div>
                  )}
                  {/* <input type="file" /> */}
                  {breadcrumb === 1 && (
                    <div className={styles.uploadBox}>
                      {!imageURL ? (
                        <label htmlFor="upload-photo" className={styles.icon}>
                          <AddPhotoAlternateIcon />
                        </label>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            height: "70%",
                            display: "flex",
                          }}
                        >
                          <img src={imageURL} alt="pic"></img>
                          <IconButton
                            onClick={() => setImageURL(null)}
                            size="small"
                            style={{ position: "absolute", left: 0 }}
                          >
                            <CloseOutlined
                              style={{
                                color: "rgb(119 119 131)",
                                border: " 1px solid",
                                borderRadius: "50%",
                                background: "#f1f1f1c4",
                              }}
                              fontSize="small"
                            />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  )}
                  {breadcrumb === 1 && imageURL && (
                    <div className={styles.btns}>
                      <Button
                        style={{
                          backgroundColor: "#FFA737",
                          color: "white",
                          marginBottom: "2rem",
                          marginTop: "1rem",
                        }}
                        variant="contained"
                        onClick={uploadImage}
                      >
                        Upload
                      </Button>
                    </div>
                  )}
                </div>
                {breadcrumb === 0 && (
                  <div
                    style={{
                      height: "15rem",
                      alignItems: "flex-start",
                      padding: "10px",
                      border: "1px solid #FFA737",
                      marginBottom: "2rem",
                      borderRadius: "5px",
                      overflow: "auto",
                      position: "relative",
                    }}
                    ref={ref}
                    onScroll={handleScroll}
                  >
                    <Grid container direction="row" spacing={2}>
                      {uploadedImgs.map(({ imageurl, caption, id }) => (
                        <Grid
                          item
                          xs={4}
                          sm={3}
                          md={2}
                          style={{ height: "5.5rem" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              background: "#eae0e0",
                              borderRadius: "5px",
                              border: "1px solid #FFA737",
                              height: "100%",
                              cursor: "pointer",
                            }}
                            onClick={() => chooseImage(caption, id, imageurl)}
                          >
                            <img
                              src={imageurl}
                              style={{
                                width: "100%",
                                position: "relative",
                                maxHeight: "100%",
                              }}
                              alt="pic"
                            />
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                    {loading && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "0.5rem",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                )}
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  id="upload-photo"
                  onChange={(e) => handleImage(e)}
                />
                <div className={styles.btns} style={{ marginTop: "2rem" }}>
                  {/* {   console.log(isValid,dirty,values)} */}
                  <Button
                    style={{
                      backgroundColor:
                        !isValid || !dirty || !chosenImage
                          ? "lightgrey"
                          : "#FFA737",
                      color: "white",
                    }}
                    type="submit"
                    disabled={!isValid || !dirty || !chosenImage}
                    variant="contained"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Create
                  </Button>
                  <button
                    type="reset"
                    className={styles.btn1}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateWorksheetModal;
