import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
  fullname: Yup.string().required("Required"),
  invitationCode: Yup.string().required("Required"),
  confirmPassword:Yup.string().when("password", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    )
  }),
  password: Yup
  .string()
  .required('Please Enter your password')
  .matches(
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
    "Minimum 8 alphabets,Containg one uppercase,lowercase and special character"
  ),
  termAndCondition:  Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')
});
