import { useEffect, useRef, useState } from "react";
import Nav from "../../Nav";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CreateWorksheetModal from "./CreateWorksheetModal/CreateWorksheetModal";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { getMyProfile } from "../../../store/actions/auth";
import Api from "../../../ApiRequests";
import { IconButton } from "@material-ui/core";
import { Grid, Paper, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SwipeableEdgeDrawer from "./CreateWorksheetModal/Drawer";
import { ArrowBack } from "@material-ui/icons";
import { ArrowBackIos } from "@mui/icons-material";
import EditWorksheetModal from "./CreateWorksheetModal/EditWorksheetModal";
import { useHistory, useParams } from "react-router-dom";
import Worksheets from "./Worksheets";
import Worksheet from "./Worksheet";

const Dashboard = () => {
  let { status } = useParams();

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [copiedWorksheet,setCopiedWorksheet] = useState(null)
  const [created, setCreated] = useState(null);
  const [mySheets, setmySheets] = useState([]);
  const [pubSheets, setPubsheets] = useState([]);
  const [loading, setLoading] = useState(false);
  // console.log(open);
  const ref = useRef(null);

  const isopen = Boolean(anchorEl);
  const id = isopen ? "simple-popover" : undefined;

  return (
    <div className={styles.wrapper} onScroll={()=>console.log("scrolling")}>
      {/* <Nav/> */}

      <div className={styles.header}>
        <h1>Design your customised worksheets here</h1>
      </div>

      {status === "All" ? (
        <Grid
          container
          direction="column"
          spacing={2}
          style={{ width: "100%" }}
        >
          <Grid item xs={6} style={{ maxWidth: "100%" }}>
            <div className={styles.section}>
              <div className={styles.sectionHeader}>
                <div className={styles.heading}  onClick={() => history.push("/dashboard/draft")}>
                  <h3>My Worksheets (Draft)</h3>
                  <ArrowForwardIosIcon

                  />
                </div>
                <button
                  className={styles.createBtn}
                  onClick={() => setOpen(true)}
                >
                  Create Worksheet
                </button>
                <button
                  className={styles.createBtnI}
                  onClick={() => setOpen(true)}
                >
                  <img src="/img/9.svg" alt="" />
                </button>
              </div>
              <Worksheet status="draft" created={created} setCreated={setCreated} copiedWorksheet={copiedWorksheet} setCopiedWorksheet={setCopiedWorksheet}/>
            </div>
          </Grid>
          <Grid item xs={6} style={{maxWidth:"100%"}}>
            <div className={styles.section}>
              <div className={styles.sectionHeader}>
                <div className={styles.heading}  onClick={() => history.push("/dashboard/published")}>
                  <h3>My Published Worksheets</h3>
                  <ArrowForwardIosIcon/>
                </div>
              </div>
              <Worksheet status="published" created={created} setCreated={setCreated} copiedWorksheet={copiedWorksheet} setCopiedWorksheet={setCopiedWorksheet}/>

            </div>
          </Grid>
        </Grid>
      ) : (
        <Worksheets status={status} />
      )}
      <CreateWorksheetModal
        open={open}
        setOpen={setOpen}
        setCreated={setCreated}
      />
    </div>
  );
};

export default Dashboard;
