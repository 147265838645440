const initState = {title:'',notesBy:'',phoneNumber:'',email:'',website:'',id:'', load:true}

  const worksheetReducer = (state = initState, action) =>{

     switch (action.type) {

         case "LOAD_WORKSHEET":
             return {...state, ...action.payload, load:false}

          case "WORKSHEET_LOAD":
          return initState;
         default:
             return state;

     }
 }

 export default worksheetReducer;
