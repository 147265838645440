import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  // email: Yup.string().email("Invalid Email").required("*Required"),
  fullname: Yup.string().required("*Required"), 
  phone:Yup.string().required("*Required"),
  role:Yup.string().required("*Required"),
//   logo:Yup.string().required("Required"),
  institution:Yup.string().required("*Required"),
});
