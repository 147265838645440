import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStyle } from "../../store/actions/containerActions";
import SearchIcon from '@mui/icons-material/Search';

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {  Grid, Box } from "@material-ui/core";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import "./styles.scss"

import Api from "../../ApiRequests"
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';



const IconsTab = ({ page , container, box }) => {
  const inputRef = useRef()
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setloading] = useState(false);
  const [images, setimages] =  useState([])
  const [open, setOpen] = React.useState(false);
  const [openiconZoom, setopeniconZoom]= useState(false);
  const [preview, setpreview] = useState({
    view: false,
    url: "",
    file: undefined
  });

  const [search, setsearch] =  useState();

  const handleOpeniconZoom = () => setopeniconZoom(true);
  const handleCloseiconZoom = () => setopeniconZoom(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const Input = styled('input')({
  display: 'none',
});

  const dispatch = useDispatch();

  useEffect(()=>{
    if(activeTab === 0)
    geticon("global", search)
    else
    geticon("myown", search)
  },[activeTab, search ])

  const getsearch = (e)=>{
    setsearch(e.target.value);
  }

  const geticon= async (type, search)=>{
        try{
          setloading(true);
          const res = await Api.getIcon(type,search)
          setimages(res.data.body.results);
          setloading(false);
        } catch (err){
          setloading(false);
          console.log(err.response);
        }
  }

  const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const styleZoom = {
position: 'absolute',
top: '50%',
left: '50%',
transform: 'translate(-50%, -50%)',
bgcolor: 'background.paper',
boxShadow: 24,
p: 4,
width: 550,
};


const uploadIcon = async (e)=>{
  e.preventDefault();
  const form = document.getElementById( "Formicon" )
  const caption = document.getElementById( "caption" ).value
  var formData = new FormData();
  if(preview.file === undefined ){
    return toast.error(`Please choose an image`, {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });
  }
  if(caption === ""){
    return toast.error(`Please fill the caption`, {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });
  }
  formData.append("icon", preview.file);
  formData.append("caption", caption)

  try{
    const res = await Api.uploadIcon(formData);
    toast.success('Icon Uploaded', {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });
  if(activeTab === 0)
  geticon("global")
  else
  geticon("myown")

  handleClose();
  }catch(err){
    console.log(err.response);
    toast.error(`${err.response.data.message}`, {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });
  }
clear();
}

useEffect(()=>{
  if(preview.file){
    const objectUrl = URL.createObjectURL(preview.file);
    setpreview({
      view: true,
      url: objectUrl,
      file: preview.file
    })
  }
}, [preview.file])

const previewfun = (e)=>{
  const file = e.target.files[0]
  setpreview({
    view: false,
    url: "",
    file: file
  })
}

const clear = ()=>{
  const form = document.getElementById( "Formicon" )
  document.getElementById( "caption" ).value= ""
  setpreview({
    view: false,
    url: "",
    file: undefined
  })
}

  return (
    <div style={{marginBottom: "3rem"}}>
      <Tabs>
        <TabList style={{ marginBottom: 0,border: "0px" }}>
          <Tab
            style={{
              backgroundColor: activeTab === 0 ? "orange" : "white",
              color: activeTab === 0 ? "white" : "rgba(0,0,0,0.4)",
              borderTopLeftRadius:"15px",
              borderTopRightRadius:"15px"
            }}
            onClick={() => setActiveTab(0)}
          >
            Global
          </Tab>
          <Tab
            style={{
              backgroundColor: activeTab === 1 ? "orange" : "white",
              borderTopLeftRadius:"15px",
              borderTopRightRadius:"15px",
              color: activeTab === 1 ? "white" : "rgba(0,0,0,0.4)",

            }}
            onClick={() => setActiveTab(1)}
          >
            Private
          </Tab>
        </TabList>

        <TabPanel
          style={{ border:  "2px solid orange" , position: "relative", top:"0" }}
        >

          <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
            <div className="search-comp display-flex">
              <SearchIcon/>
              <input type="text" placeholder="Search" value={search} onChange={getsearch}/>
            </div>
          </Box>

          {loading ? <Box className="load-box" >
            <CircularProgress color="inherit"/>
          </Box> : <Box className="img-box">
            <Grid container className={images.length === 0 ? "justify-content-center" : ""}>
              {images.length === 0 ? <Box><p>No icons found </p> </Box>:images.map((image, index) => {
                return (
                  <Grid item xs={4}>
                    <img
                      src={image.iconUrl}
                      key={index}
                      style={{ width: 100, height: 100, cursor: "pointer" }}
                      onClick={() =>
                        dispatch(setStyle(page, container, box, "icon", image.iconUrl))
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>}
          <Box className="zoom-icon" onClick={handleOpeniconZoom}><OpenInFullIcon /></Box>
        </TabPanel>
        <TabPanel
          style={{ border: "2px solid orange" , position: "relative", top:"0"}}
        >
          <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
            <div className="search-comp display-flex">
              <SearchIcon/>
              <input type="text" placeholder="Search" value={search} onChange={getsearch}/>
            </div>
          </Box>
          {loading ? <Box className="load-box" >
            <CircularProgress color="inherit"/>
          </Box> : <Box className="img-box">
            <Grid container>
              {images.length === 0 ? <p>No icons found </p> : images.map((image, index) => {
                return (
                  <Grid item xs={4} key={index}>
                    <Box className="img-box-each">
                      <img
                        src={image.iconUrl}
                        key={index}
                        onClick={() =>
                          dispatch(setStyle(page, container, box, "icon", image.iconUrl))
                        }
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>}

          <Box className="display-flex justify-content-center brd-botm" style={{padding: " 1rem"}}>
            <Button className="fullwidth" onClick={handleOpen} >Upload Icon</Button>
          </Box>
          <Box className="zoom-icon" onClick={handleOpeniconZoom}><OpenInFullIcon /></Box>
        </TabPanel>
      </Tabs>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{minWidth:500}}>
          <Box style={{display:"flex", justifyContent:"end", cursor:"pointer"}} onClick={handleClose}>
            <CloseIcon/>
          </Box>
          <div>
            <h2>Upload Icon</h2>
          </div>

          {preview.view && <Box className="preview-icon">
            <img src={preview.url}/>
          </Box>}
          <form id="Formicon">
            <Box style={{marginBottom:"1rem"}}>
              <label htmlFor="Iconfile">
                <Input accept="image/*" id="Iconfile" type="file" required onChange={previewfun}/>
                <Button variant="contained" component="span">
                  Choose Icon
                </Button>
              </label>
              {preview.view && <Box component="span" style={{marginLeft:"1rem"}}>
                <Button variant="outlined" component="span" onClick={clear} type="button">
                  Clear icon
                </Button>
              </Box>}
            </Box>

            <Box style={{marginBottom:"1rem"}}>
              <TextField id="caption" fullWidth placeholder="Caption" required name="caption" variant="outlined" />
            </Box>

            <Box>
              <Button variant="contained" onClick={uploadIcon}>
                Upload
              </Button>
            </Box>
          </form>

        </Box>
      </Modal>

      <Modal
        open={openiconZoom}
        onClose={handleCloseiconZoom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleZoom}>
          <Tabs>
            <TabList style={{ marginBottom: 0, border: "0px" }}>
              <Tab
                style={{
                  backgroundColor: activeTab === 0 ? "orange" : "white",
                  color: activeTab === 0 ? "white" : "rgba(0,0,0,0.4)",
                  borderTopLeftRadius:"15px",
                  borderTopRightRadius:"15px"
                }}
                onClick={() => setActiveTab(0)}
              >
                Global
              </Tab>
              <Tab
                style={{
                  backgroundColor: activeTab === 1 ? "orange" : "white",
                  borderTopLeftRadius:"15px",
                  borderTopRightRadius:"15px",
                  color: activeTab === 1 ? "white" : "rgba(0,0,0,0.4)",

                }}
                onClick={() => setActiveTab(1)}
              >
                Private
              </Tab>
            </TabList>

            <TabPanel
              style={{ border:  "2px solid orange" , position: "relative", top:"0" }}
            >

              <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
                <div className="search-comp display-flex">
                  <SearchIcon/>
                  <input type="text" placeholder="Search"/>
                </div>
              </Box>

              {loading ? <Box className="load-box" >
                <CircularProgress color="inherit"/>
              </Box> : <Grid container className={images.length === 0 ? "justify-content-center" : ""}>
                {images.length === 0 ? <Box><p>No icons found </p> </Box>:images.map((image, index) => {
                  return (
                    <Grid item xs={4}>
                      <img
                        src={image.iconUrl}
                        key={index}
                        style={{ width: 100, height: 100, cursor: "pointer" }}
                        onClick={() =>
                          dispatch(setStyle(page, container, box, "icon", image.iconUrl))
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>}
              <Box className="zoom-icon" onClick={handleCloseiconZoom}><CloseFullscreenIcon /></Box>
            </TabPanel>
            <TabPanel
              style={{ border: "2px solid orange" , position: "relative", top:"0"}}
            >
              <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
                <div className="search-comp display-flex">
                  <SearchIcon/>
                  <input type="text" placeholder="Search"/>
                </div>
              </Box>
              {loading ? <Box className="load-box" >
                <CircularProgress color="inherit"/>
              </Box> : <Box className="img-box">
                <Grid container>
                  {images.length === 0 ? <p>No icons found </p> : images.map((image, index) => {
                    return (
                      <Grid item xs={4} key={index}>
                        <Box className="img-box-each">
                          <img
                            src={image.iconUrl}
                            key={index}
                            onClick={() =>
                              dispatch(setStyle(page, container, box, "icon", image.iconUrl))
                            }
                          />
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>}


              <Box className="display-flex justify-content-center brd-botm" style={{padding: " 1rem"}}>
                <Button className="fullwidth"  onClick={handleOpen} >Upload Icon</Button>
              </Box>

              <Box className="zoom-icon" onClick={handleCloseiconZoom}><CloseFullscreenIcon /></Box>
            </TabPanel>
          </Tabs>
        </Box>
      </Modal>
    </div>
  );
};

export default IconsTab;
