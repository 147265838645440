import { addPage, setStylePage } from "./pageActions";
import { v4 as uuidv4 } from "uuid";

import { calculContainersInPage } from "../../utils/container";

const defaultContainer = {
  containerID: "",
  pageIndex: 1,
  hasAddButton: false,
  height: 120,
  selected: true,
  name: "container",
  category: "",
  borderHeight: 1,
  borderStyle: "",
  borderColor: "",
  backgroundColor: "",
  line: 1,
  boxes: [],
};

/**
 * Action method to add or update container in a page,
 * handles addition of page if there is no space to add new container
 *
 * @param {int} pageIndex Index of the page in which cotainer have to be added
 * @param {container} container container to be added
 * @returns
 */
export const addorUpdateContainer = (pageIndex, container, id) => async (dispatch, getState) => {
  let containers = [...getState().container.containers];

  const pages = [...getState().page.pages];
  let noOfContainers = containers.length - 2;
  ///add
  if (container.index > noOfContainers) {
    ///add logic
    console.log("adding container")
    containers = deSelectContainer(containers);
    defaultContainer.containerID=id;
    defaultContainer.id = "container-" + uuidv4();
    defaultContainer.index = containers.length - 1;
    defaultContainer.name = container.name;
    defaultContainer.category = container.category
    defaultContainer.borderStyle = container.borderStyle;
    defaultContainer.borderColor = container.borderColor;
    defaultContainer.backgroundColor = container.backgroundColor;
    containers[containers.length - 1].index = containers.length;


    insertBeforeEnd(containers, { ...defaultContainer });

    dispatch({
      type: "ADD_OR_UPDATE_CONTAINER",
      payload: [...containers],
    })

    noOfContainers += 1
  } else {
    ////update logic
    console.log("updating container")
    containers = deSelectContainer(containers);
    containers[container.index] = container

    dispatch({
      type: "ADD_OR_UPDATE_CONTAINER",
      payload: [...containers],
    })
  }

  let x = "ferfer"
  const { containers_in_page, pageNumberNeeded } = calculContainersInPage(pages, pageIndex, containers);

  const numberPagesNeded =
    pageNumberNeeded - pages.length;


  for (let i = 0; i < numberPagesNeded; i++) {
    dispatch(addPage());
  }
};


export const addContainerBtnClicked = (val) => async (dispatch, getState) => {

  let container = getState().container
  const counter = container.isAddBtnClicked + 1
  dispatch({
    type: "SET_IS_ADD_CONT_BTN_CLICKED",
    payload: counter,
  })
}
const insertBeforeEnd = (array, item) => {
  const last = array[array.length - 1];
  array.push(last);
  array[array.length - 2] = item;

  return array;
};

export const deSelectContainer = (containers) => {
  return containers.map((container) => {
    container.selected = false;
    return container;
  });
};
export const deSelectBoxes = (containers) => {
  return containers.map((container) => {
    container.boxes = container.boxes ? container.boxes.map(box => {
      return { ...box, selected: false }
    }) : []

    return container;
  });
};
//setBoxText
export const setBoxText = (box, text) => async (dispatch, getState) => {
  let containers = [...getState().container.containers];

  containers[box.containerIndex].boxes[box.index].text = text;


  dispatch({
    type: "SET_BOX_TYPE",
    payload: [...containers],
  });
}

export const setTableCeilText = (box, selectedCeil, text) => async (dispatch, getState) => {
  let containers = [...getState().container.containers];


  containers[box.containerIndex].boxes[box.index].table[selectedCeil.i][selectedCeil.j].text = text;


  dispatch({
    type: "SET_TABLE_CEIL_TEXT",
    payload: [...containers],
  });
}


export const setNbLines = (box, nb) => async (dispatch, getState) => {
  let containers = [...getState().container.containers];
  containers[box.containerIndex].boxes[box.index].nbLines = nb;
  dispatch({
    type: "SET_TABLE_NB_LINES",
    payload: [...containers],
  });
}
export const setNbLinesHeight = (box, nb) => async (dispatch, getState) => {
  let containers = [...getState().container.containers];
  containers[box.containerIndex].boxes[box.index].nbLinesHeight = nb;
  dispatch({
    type: "SET_TABLE_NB_LINES",
    payload: [...containers],
  });
}
export const setNbTableRows = (box, nb) => async (dispatch, getState) => {

  let containers = [...getState().container.containers];
  let diff = Math.abs( containers[box.containerIndex].boxes[box.index].nbRows - nb )
  for (let i =0 ; i < diff; i++ ){
    if (containers[box.containerIndex].boxes[box.index].nbRows < nb) {
      const row = []
      for(let j=0; j < containers[box.containerIndex].boxes[box.index].nbColumns ; j++ ){
      row.push({ text: "" });
      }
      containers[box.containerIndex].boxes[box.index].table.push(row);
    } else {
      containers[box.containerIndex].boxes[box.index].table.pop()
    }
  }

  containers[box.containerIndex].boxes[box.index].nbRows = nb;


  dispatch({
    type: "SET_TABLE_NB_ROWS",
    payload: [...containers],
  });
}


export const setNbTableColumns = (box, nb) => async (dispatch, getState) => {
  let containers = [...getState().container.containers];
let diff = Math.abs( containers[box.containerIndex].boxes[box.index].nbColumns - nb )

for (let i =0 ; i < diff; i++ ){
  if (containers[box.containerIndex].boxes[box.index].nbColumns < nb) {
    containers[box.containerIndex].boxes[box.index].table = containers[box.containerIndex].boxes[box.index].table.map(row => {
      row.push({ text: "" });
      return row
    })
  } else {
    containers[box.containerIndex].boxes[box.index].table = containers[box.containerIndex].boxes[box.index].table.map(row => {
      row.pop();
      return row
    })
  }
}

  containers[box.containerIndex].boxes[box.index].nbColumns = nb;


  dispatch({
    type: "SET_TABLE_NB_COLUMNS",
    payload: [...containers],
  });
}

//setBoxType
export const setBoxType = (box, type) => async (dispatch, getState) => {
  let containers = [...getState().container.containers];


  containers[box.containerIndex].boxes[box.index].type = type;
  if (type === "table") {
    const table = []
    for (let i = 0; i < 3; i++) {

      const row = []
      for (let j = 0; j < 3; j++) {

        row.push({ text: "" })

      }
      table.push(row)

    }
    containers[box.containerIndex].boxes[box.index].nbColumns = 3;
    containers[box.containerIndex].boxes[box.index].nbRows = 3;
    containers[box.containerIndex].boxes[box.index].table = [...table]

  }
  if (type === "line") {
    containers[box.containerIndex].boxes[box.index].nbLines = box.h
    containers[box.containerIndex].boxes[box.index].nbLinesHeight = 16
  }

  dispatch({
    type: "SET_BOX_TYPE",
    payload: [...containers],
  });
}

//clear the table
export const clearTable = (box , ilenght, jlenght) => async (dispatch, getState) =>{
  let containers = [...getState().container.containers];

  const table = []
  for (let i = 0; i < ilenght; i++) {

    const row = []
    for (let j = 0; j < jlenght; j++) {

      row.push({ text: "" })

    }
    table.push(row)

  }
  containers[box.containerIndex].boxes[box.index].nbColumns = jlenght;
  containers[box.containerIndex].boxes[box.index].nbRows = ilenght;
  containers[box.containerIndex].boxes[box.index].table = [...table]

  dispatch({
    type: "SET_BOX_TYPE",
    payload: [...containers],
  });
}

export const selectBox = (containerIndex, boxIndex) => async (dispatch, getState) => {

  let containers = [...getState().container.containers];
  deSelectBoxes(containers)
  deSelectContainer(containers)

  containers[containerIndex].boxes[boxIndex].selected = true;
  containers[containerIndex].boxes[boxIndex].containerIndex = containerIndex;
  containers[containerIndex].boxes[boxIndex].index = boxIndex;
  dispatch({
    type: "SELECT_BOX",
    payload: [...containers],
  });
}

export const addBox = (pageIndex, index) => async (dispatch, getState) => {
  let containers = [...getState().container.containers];

  let y = containers[index].boxes.reduce((max, box) => {
    if (box.y) {
      if (max < box.h + box.y) {
        max = box.h + box.y;
      } else {
        if (max < box.h) max = box.h;
      }
    }
    return max;
  }, 0);
  containers[index].boxes = [
    ...containers[index].boxes,
    {
      flex: 1,
      w: 12,
      h: 3,

      y: y == 0 ? 1 : y,
      x: 0,
      id: uuidv4(),
      text: "",
    },
  ];
  containers[index].height = (y + 3) * (120 / 3)

  const pages = [...getState().page.pages];




  dispatch({
    type: "ADD_BOX",
    payload: [...containers],
  });

  const { pageNumberNeeded } = calculContainersInPage(pages, pageIndex, containers);

  const numberPagesNeded =
    pageNumberNeeded - pages.length;

  for (let i = 0; i < numberPagesNeded; i++) {
    dispatch(addPage());
  }
};
export const setBoxes = (page, index, boxes) => async (dispatch, getState) => {
  let containers = [...getState().container.containers];

  let x = 0;
  containers[index].boxes = [
    ...boxes.map((box, index) => {
      const w = (box * 12) / boxes.reduce((p, c) => c + p, 0);

      x += boxes[index - 1]
        ? (boxes[index - 1] * 12) / boxes.reduce((p, c) => c + p, 0)
        : 0;
      return {
        flex: box,
        h: 3,
        w,
        x,
        y: 0,
        id: uuidv4(),
        text: ""
      };
    }),
  ];

  const pages = [...getState().page.pages];



  dispatch({
    type: "SET_BOXES",
    payload: [...containers],
  });
  const { pageNumberNeeded } = calculContainersInPage(pages, page.index, containers);

  const numberPagesNeded =
    pageNumberNeeded - pages.length;

  for (let i = 0; i < numberPagesNeded; i++) {
    dispatch(addPage());
  }
};

export const setBox = (id, item) => async (dispatch, getState) => {
  let containers = getState().container.containers.map((container) => {
    if (container.id === id) {
      container.boxes[item.i.split("-")[1]] = { ...container.boxes[item.i.split("-")[1]], ...item };
    }
    return container;
  });

  dispatch({
    type: "SET_BOX",
    payload: [...containers],
  });
};

const updateContainer = (containers, id, item, value) => {
  return containers.map((container) => {
    if (container.id === id) container[item] = value;
    return container;
  });
};

export const setContainerHeight =
  (page, height, id) => async (dispatch, getState) => {
    let containers = getState().container.containers;

    containers = updateContainer(containers, id, "height", height);

    const pages = [...getState().page.pages];



    dispatch({
      type: "SET_CONTAINER_HEIGHT",
      payload: [...containers],
    });
    const { pageNumberNeeded } = calculContainersInPage(pages, page.index, containers);

    const numberPagesNeded =
      pageNumberNeeded - pages.length;

    for (let i = 0; i < numberPagesNeded; i++) {
      dispatch(addPage());
    }
  };


export const setBoxHeight =
  (page, box, h) => async (dispatch, getState) => {

    const containers = [...getState().container.containers];
    containers[box.containerIndex].boxes[box.index].h = h
    const pages = [...getState().page.pages];



    dispatch({
      type: "SET_BOX_SIZE",
      payload: containers,
    });
    const { pageNumberNeeded } = calculContainersInPage(pages, page.index, containers);

    const numberPagesNeded =
      pageNumberNeeded - pages.length;

    for (let i = 0; i < numberPagesNeded; i++) {
      dispatch(addPage());
    }
  }
export const setBoxSize =
  (page, containerIndex, layout) => async (dispatch, getState) => {
    const containers = [...getState().container.containers];

    layout.map((l) => {
      containers[containerIndex].boxes[l.i.split("-")[1]] = {
        ...containers[containerIndex].boxes[l.i.split("-")[1]],
        ...l,
      };
    });

    const pages = [...getState().page.pages];
    const pageContentHeight = page.pageContentHeight;


    dispatch({
      type: "SET_BOX_SIZE",
      payload: containers,
    });
    const { pageNumberNeeded } = calculContainersInPage(pages, page.index, containers);

    const numberPagesNeded =
      pageNumberNeeded - pages.length;

    for (let i = 0; i < numberPagesNeded; i++) {
      dispatch(addPage());
    }
  };

export const selectContainer = (id) => async (dispatch, getState) => {
  let containers = [...getState().container.containers];
  containers = deSelectContainer(containers);
  deSelectBoxes(containers)

  containers = containers.map((container) => {
    if (container.id === id) {
      container.selected = true;
    }
    return container;
  });

  dispatch({
    type: "SELECT_CONTAINER",
    payload: [...containers],
  });
};



export const reorderContainers =
  (startIndex, endIndex, index_page_source, index_page_destination) => async (dispatch, getState) => {
    let containers = [...getState().container.containers];



    const first = containers[startIndex];


    const second = containers[endIndex];


    if (first.hasAddButton || second.hasAddButton) {
      return;
    }
    if ((first.header || second.header) && index_page_destination !== index_page_source) {
      return;
    }


    first.index = endIndex;
    second.index = startIndex;
    containers[startIndex] = second;

    containers[endIndex] = first;

    dispatch({
      type: "ORDER_CONTAINERS",
      payload: [...containers],
    });
  };


export const setStyle = (page = null, container = null, box = null, item, value) => async (dispatch, getState) => {

  if (page) {

    dispatch(setStylePage(page, item, value))
    return;

  }

  const containers = [...getState().container.containers];

  if (container) {
    containers[container.index][item] = value
  }
  if (box) {
    containers[box.containerIndex].boxes[box.index][item] = value
  }



  dispatch({
    type: "SET_STYLE",
    payload: [...containers],
  });
}


export const deleteContainer = (containerIndex) => async (dispatch, getState) => {
  const containers = [...getState().container.containers];

  containers.splice(containerIndex, 1);
  containers.map((container, index) => {
    container.index = index;
    return container
  })

  dispatch({
    type: "DELETE_CONTAINER",
    payload: [...containers],
  });
}

export const deleteBox = (box) => async (dispatch, getState) => {
  const containers = [...getState().container.containers];
  containers[box.containerIndex].boxes.splice(box.index, 1);
  containers[box.containerIndex].boxes.map((box, index) => {
    box.index = index;
    return box
  })

  dispatch({
    type: "DELETE_BOX",
    payload: [...containers],
  });
}


export const setContainerName = (name, index) => async (dispatch, getState) => {
  const containers = [...getState().container.containers];
  containers[index].name = name
  dispatch({
    type: "SET_CONTAINER_NAME",
    payload: [...containers]
  })
}


export const setContainerCategory = (category, index) => async (dispatch, getState) => {
  const containers = [...getState().container.containers];
  containers[index].category = category
  dispatch({
    type: "SET_CONTAINER_CATEGORY",
    payload: [...containers]
  })
}

export const setBoxGraph = (box, graph) => async (dispatch, getState) => {

  let containers = [...getState().container.containers];

  containers[box.containerIndex].boxes[box.index].graph = graph;


  dispatch({
    type: "SET_BOX_GRAPH",
    payload: [...containers],
  });
}

export const setBoxImage = (box, image) => async (dispatch, getState) => {
  let containers = [...getState().container.containers];

  containers[box.containerIndex].boxes[box.index].image = image;


  dispatch({
    type: "SET_BOX_IMAGE",
    payload: [...containers],
  });
}
export const setCaptionBox = (box, caption) => async (dispatch, getState) => {
  let containers = [...getState().container.containers];

  containers[box.containerIndex].boxes[box.index].caption = caption;


  dispatch({
    type: "SET_BOX_CAPTION",
    payload: [...containers],
  });
}


export const saveAsContainerTemplate = (container) => async (dispatch, getState) => {
  let global_templates = getState().container.global_templates;
  global_templates.push(container)
  dispatch({
    type: "SET_GLOBAL_CONTAINER_TEMPLATE",
    payload: [...global_templates],
  });
}

//setContainerTemplate

export const setTemplate = (template) => async (dispatch, getState) => {
  let containers = [...getState().container.containers]
  let last = containers.pop();
  template.forEach(containerdata=>{
    containers.push(containerdata)
  })
containers.push(last);
  dispatch({
    type: "SET_CONTAINER_TEMPLATE",
    payload: [...containers],
  });
}

export const resetBoxType =(box, type)=> async (dispatch, getState) =>{
    let containers = [...getState().container.containers];
    containers[box.containerIndex].boxes[box.index].type = type;
    dispatch({
      type: "SET_BOX_TYPE",
      payload: [...containers],
    });
}


export const fetchContainer = (payload)=>{
  return {
    type: "LOAD_CONTAINER",
    payload: payload
  }
}
