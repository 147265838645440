import PreviewPdf from "../components/previewpdf/PreviewPdf";
import Content from "../components/content/Content";
import HomePage from "../components/pages/HomePage";
import WorksheetDetails from "../components/pages/WorksheetDetails";
import NotFound from "../components/pages/NotFound";
import AuthLayout from "../components/pages/AuthLayout";
import SegmentedField from "../components/Form/FormField/SegmentedField";
import Dashboard from "../components/pages/Dashboard";
import Settings from "../components/pages/Settings";

const routes = [
  {
    path: [
      "/login",
      "/signup",
      "/otp",
      "/resent_email",
      "/resent_email1",
      "/reset-password",
      "/new-password",
      "/profile",
      "/register/:code",
      "/new-password/:token"
    ],
    component: AuthLayout,
    isProtected: false,
    exact: true,
    isValid: true,
  },
  {
    path: "/sth",
    component: SegmentedField,
    isProtected: false,
    exact: true,
    isValid: true,
  },
  {
    path: "/dashboard/:status",
    component: Dashboard,
    isProtected: true,
    exact: true,
    isValid: true,
  },
  {
    path: "/settings/:id",
    component: Settings,
    isProtected: true,
    exact: true,
    isValid: true,
  },
  // {
  //   path: "/dashboard",
  //   component: Dashboard,
  //   isProtected: false,
  //   exact: true,
  //   isValid: true,
  // },
  
  {
    path: "/home/:id",
    component: HomePage,
    isProtected: true,
    exact: true,
    isValid: true,

    routes: [
      {
        path: "/home/details",
        component: WorksheetDetails,
        isProtected: true,
        exact: true,
        isValid: true,
      },
    ],
  },
 
  {
    path: "/content",
    component: Content,
    isProtected: true,
    exact: true,
    isValid: true,
  },
  {
    path: "/preview",
    component: PreviewPdf,
    isProtected: true,
    exact: true,
    isValid: true,
  },
  {
    path: "/404",
    component: NotFound,
  },
];

export default routes;
