import { useDispatch, useSelector } from "react-redux";

//icons
import TextFieldsIcon from "@material-ui/icons/TextFields";
import TableChartIcon from "@material-ui/icons/TableChart";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import MenuIcon from "@material-ui/icons/Menu";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import TimelineIcon from "@material-ui/icons/Timeline";
import CalculateIcon from '@mui/icons-material/Calculate';

import Box from '@mui/material/Box';

//redux
import {
  addBox,
  setBoxes,
  setBoxType,
} from "../../store/actions/containerActions";

//Edit
import EditTextBox from "../edit/EditTextBox";
import EditMathBox from "../edit/EditMathBox";
import EditTableBox from "../edit/EditTableBox";
import EditBlankSpace from "../edit/EditBlankSpace";
import EditLine from "../edit/EditLine";
import EditGraph from "../edit/EditGraph";
import EditImage from "../edit/EditImage";

import Select from "react-select";

import "./style.scss"

const BoxTypeTab = ({ selectedPage, selectedBox }) => {
  const dispatch = useDispatch();

  const options = [
    {
      value: "text",
      label: (
        <div>
          <Box style={{padding: "0rem 1rem"}} className="label-opt">
            <Box style={{margin:"5px 15px 0 0"}}>
              <TextFieldsIcon />
            </Box>
            Text
          </Box>
        </div>
      ),
    },
    {
      value: "table",
      label: (
        <div>
          <Box style={{padding: "0rem 1rem"}} className="label-opt">
            <Box style={{margin:"5px 15px 0 0"}}>
              <TableChartIcon />
            </Box>
            Table
          </Box>
        </div>
      ),
    },
    {
      value: "image",
      label: (
        <div>
          <Box style={{padding: "0rem 1rem"}} className="label-opt">
            <Box style={{margin:"5px 15px 0 0"}}>
              <InsertPhotoIcon />
            </Box>
            Image
          </Box>
        </div>
      ),
    },
    {
      value: "line",
      label: (
        <div>
          <Box style={{padding: "0rem 1rem"}} className="label-opt">
            <Box style={{margin:"5px 15px 0 0"}}>
              <MenuIcon />
            </Box>
            Line
          </Box>
        </div>
      ),
    },
    {
      value: "blank",
      label: (
        <div>
          <Box style={{padding: "0rem 1rem"}} className="label-opt">
            <Box style={{margin:"5px 15px 0 0"}}>
              <InsertDriveFileIcon />
            </Box>
            Blank spaces
          </Box>
        </div>
      ),
    },
    {
      value: "math",
      label: (
        <div>
          <Box style={{padding: "0rem 1rem"}} className="label-opt">
            <Box style={{margin:"5px 15px 0 0"}}>
              <CalculateIcon />
            </Box>
            Mathematics
          </Box>
        </div>
      ),
    },
    {
      value: "graph",
      label: (
        <div>
          <Box style={{padding: "0rem 1rem"}} className="label-opt">
            <Box style={{margin:"5px 15px 0 0"}}>
              <TimelineIcon />
            </Box>
            Graph
          </Box>
        </div>
      ),
    },
  ];

  return (
    <div >
      {!selectedBox.type ? (
        <div style={{ padding: "1rem 2rem" }}>
          <h4 className="head-type">Box {selectedBox.index + 1}</h4>
          <Select
            options={options}
            placeholder="Weekly or homework here"
            onChange={(value) => dispatch(setBoxType(selectedBox, value.value))}
            theme={(theme) => ({
              ...theme,
              borderRadius: 10,
              colors: {
                ...theme.colors,
                primary25: '#007aff',
                primary: '#007aff',
              },
            })}
          />
        </div>
      ) : (
        <>
          {selectedBox.type === "text" && (
            <EditTextBox selectedPage={selectedPage} box={selectedBox} />
          )}

          {selectedBox.type === "math" && (
            <EditMathBox selectedPage={selectedPage} box={selectedBox} />
          )}

          {selectedBox.type === "table" && (
            <EditTableBox selectedPage={selectedPage} box={selectedBox} />
          )}

          {selectedBox.type === "blank" && (
            <EditBlankSpace selectedPage={selectedPage} box={selectedBox} />
          )}

          {selectedBox.type === "line" && (
            <EditLine selectedPage={selectedPage} box={selectedBox} />
          )}

          {selectedBox.type === "graph" && (
            <EditGraph selectedPage={selectedPage} box={selectedBox} />
          )}

          {selectedBox.type === "image" && (
            <EditImage selectedPage={selectedPage} box={selectedBox} />
          )}
        </>
      )}
    </div>
  );
};

export default BoxTypeTab
