import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.scss";
// import Api from "../../../../ApiRequests";
import { useSelector } from "react-redux";
import Api from "../../ApiRequests";
import { toast } from "react-toastify";

const CreateTemplate = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const initialState = {
    email: "na@gm.com",
    newPassword: "",
    confirmPassword: "",
  };
  const [_containers, setContainers] = useState([]);
  const [name, setName] = useState(null);
  const containers = useSelector((state) => state.container.containers);
  useEffect(() => {
    setContainers(containers);
  }, [containers]);
  const handleSelect = (id) => {
    console.log("clicked");
    setContainers(
      _containers.map((container) => {
        if (container.id === id) container.selected = !container.selected;
        return container;
      })
    );
  };
  const handleSubmit = () => {
    const selContainer = _containers.filter((item) => item.selected);
    console.log(_containers, selContainer);
    const categories = selContainer.map((item) => item.containerID);
    Api.createTemplate({
      templateName: name,
      categories,
      bigJson: selContainer,
    })
      .then(() => {
        toast.success(`Template created`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    handleClose();
  };

  return (
    <Dialog maxWidth="sm" onClose={handleClose} open={open} fullWidth={true}>
      <div className={styles.modal}>
        <h3 className={styles.heading}>
          <img src="/img/9.svg" alt="" /> {"  "}Create Template
          <div className={styles.underline}></div>
        </h3>
        <div className={styles.text}>Name of Template</div>
        <input
          placeholder="Template Name"
          className={styles.input}
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></input>
        {name === "" && (
          <div
            style={{ position: "absolute", fontSize: "xx-small", color: "red" }}
          >
            *Required
          </div>
        )}
        <div className={styles.text}>Select Container</div>
        <div className={styles.containers}>
          {_containers.map((container, index) => {
            return (
              !container.hasAddButton && (
                <div key={index} className={styles.Results}>
                  <div
                    onClick={(e) => {
                      handleSelect(container.id);
                    }}
                    className={
                      container.selected ? styles.Selected : styles.Others
                    }
                  >
                    {container.name}
                    {"<"}
                    {container.category || "Category"}
                    {">"}
                  </div>
                  <br />
                </div>
              )
            );
          })}
        </div>
        <div className={styles.btns}>
          {/* {   console.log(isValid,dirty,values)} */}
          <button
            type="submit"
            style={{ background: " #FFA737" }}
            className={styles.btn2}
            variant="contained"
            onClick={() => {
              console.log("cleicked");
              handleSubmit();
            }}
          >
            Save
          </button>
          <button type="reset" className={styles.btn1} onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateTemplate;
