// Render Prop
import { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, CircularProgress, Grid } from "@mui/material";

import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import FormControl from "../Form/FormControl";
import { Privacy, Terms } from "../pages/DocsLayout";
import { validationSchema } from "../Form/validation/signUpValidation";
import {useParams} from "react-router-dom"
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const SignUpWrapper = ({ onSignUpSubmit, initialValues }) => {
  const {code} = useParams()
  if(window.location.pathname.includes('register'))
  initialValues={...initialValues,invitationCode:code}
  // console.log(code)
  const {load} = useSelector(state=>state.auth)
  const [show, setShow] = useState(false);
  const [openP, setOpenP] = useState(false);
  const [openT, setOpenT] = useState(false);
  const [showConf,setShowConf] = useState(false)

  const icon = {
    fontSize: "1rem",
    color: "#79747E",
    cursor: "pointer",
  };

  const DisplayDialog = (url) => {
    if (url === "terms") setOpenT(true);
    else if (url === "policy") setOpenP(true);

    window.history.pushState("", "", `/${url}`);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onSignUpSubmit(values);
        }}
      >
        {(formProps) => (
          <Form>
            <Grid
              className={styles.formCont}
              container
              direction="column"
              rowGap={{ xs: 2, sm: 3.6 }}
              justifyContent="center"
            >
              <Grid item>
                <h1 className={styles.heading}>Signup</h1>
              </Grid>

              <Grid item>
                <div className={styles.inputbox}>
                  <EmailIcon style={{ fontSize: "1rem", color: "#79747E" }} />
                  <FormControl
                    control="input"
                    type="email"
                    name="email"
                    placeholder="Email"
                    errorout={true}
                  />
                </div>
                <ErrorMessage component="div" style={{position:"absolute",color:"red",fontSize:"xx-small"}} name="email" />

              </Grid>

              <Grid item>
                <div className={styles.inputbox}>
                  <img
                    src="/assets/user.svg"
                    style={{ width: "1rem" }}
                    alt=""
                  />
                  <FormControl
                    control="input"
                    type="text"
                    name="fullname"
                    placeholder="Full Name"
                    errorout={true}
                  />
                </div>
                <ErrorMessage component="div" style={{position:"absolute",color:"red",fontSize:"xx-small"}} name="fullname" />
              </Grid>

              <Grid item>
                <div className={styles.inputbox}>
                  <img
                    src="/assets/code.svg"
                    style={{ width: "1rem" }}
                    alt=""
                  />
                  <FormControl
                    control="input"
                    type="text"
                    name="invitationCode"
                    placeholder="Invitation Code"
                    errorout={true}
                  />
                </div>
                <ErrorMessage component="div" style={{position:"absolute",color:"red",fontSize:"xx-small"}} name="invitationCode" />

              </Grid>

              <Grid item>
                <div className={styles.inputbox}>
                  <LockIcon style={{ fontSize: "1rem", color: "#79747E" }} />
                  <FormControl
                    control="input"
                    type={show ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    errorout={true}
                  />
                  {show ? (
                    <VisibilityIcon
                      style={icon}
                      onClick={() => setShow(!show)}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={icon}
                      onClick={() => setShow(!show)}
                    />
                  )}
                </div>
                <div style={{position:"relative",}}>
                <ErrorMessage component="div" style={{position:"absolute",color:"red",fontSize:"xx-small"}} name="password" />
                </div>

              </Grid>
              <Grid item>
                <div className={styles.inputbox}>
                  <LockIcon style={{ fontSize: "1rem", color: "#79747E" }} />
                  <FormControl
                    control="input"
                    type={showConf ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    errorout={true}
                  />
                  {showConf ? (
                    <VisibilityIcon
                      style={icon}
                      onClick={() => setShowConf(!showConf)}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={icon}
                      onClick={() => setShowConf(!showConf)}
                    />
                  )}
                </div>
                <div style={{position:"relative"}}>
                <ErrorMessage component="div" style={{position:"absolute",color:"red",fontSize:"xx-small"}} name="confirmPassword" />
                </div>

              </Grid>
             
              
              <div item style={{ display: "flex",marginBottom:"1rem",alignItems:"center"}}>
                <FormControl
                  control="checkbox"
                  name="termAndCondition"
                  label=""
                  errorout={true}
                  className={styles.checkbox}
                  />
                <p className={styles.link} style={{margin:"0rem"}}>
                  By creating your account, you agree to the{" "}
                  <span
                    className={styles.fakelink}
                    onClick={() => DisplayDialog("terms")}
                  >
                    Terms and Conditions
                  </span>
                  {" "}and
                  <span
                    className={styles.fakelink}
                    onClick={() => DisplayDialog("policy")}
                    >
                    {" "}
                    Privacy Policies
                  </span>
                  .
                </p>
              </div>
              </Grid>
              <Grid
               container
               direction="column"
               rowGap={{ xs: 1.2, sm: 1.5 }}>
              <Grid item>
                {load?<CircularProgress/>:<Button
                  variant="contained"
                  type="Submit"
                  disabled={!formProps.dirty || !formProps.isValid}
                  // onClick={formProps.handleSubmit}
                  // disabled={formProps.isSubmitting}
                  className={styles.btn}
                >
                  Sign Up
                </Button>}
              </Grid>
              <Grid item container alignItems="baseLine">
                <p className={styles.link}>
                  Aleardy have an account? <Link to="/login" className={styles.fakelink}>Log In</Link>
                </p>
              </Grid>
              </Grid>
          </Form>
        )}
      </Formik>

      <Terms open={openT} setOpen={setOpenT} />
      <Privacy open={openP} setOpen={setOpenP} />
    </div>
  );
};

export default SignUpWrapper;
