import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { BorderStyle } from "../borderstyle/BorderStyle";

import {
  addPage,
  addPerNote,
  deletePerNote,
} from "../../store/actions/pageActions";
import {  Box } from "@material-ui/core";
import Button from '@mui/material/Button';
import "./PageFormTab.scss";
import TextEditor from "../../components/texteditor/TextEditor"
import { MathJax } from "better-react-mathjax";

import parse from "html-react-parser";
import { setBoxText } from "../../store/actions/containerActions";
import { clearTxtEditor } from "../../store/actions/editActions";

const PageFormTab = ({ selectedPage }) => {
  const dispatch = useDispatch();

  const [per_note_text, setPerNoteText] = useState(selectedPage?.per_note);

  const [length ,setLength] = useState(0)

  return (
    <div>
      <Box style={{padding:"1rem"}} className="brd-botm">
        <Button className="btn-blue fullwidth" onClick={() => dispatch(addPage())}>
          Add Page
        </Button>
      </Box>
      {/* <ul>
        {selectedPage?.per_notes &&
          selectedPage.per_notes.map((per_note, index) => {
        return (
        <li key={index}>
        <div className="ql-editor text">
        <MathJax>{parse(per_note.text || "")}</MathJax>
        </div>
        <button
        onClick={() => {
        dispatch(deletePerNote(selectedPage, index));
        }}
        >
        delete
        </button>
        </li>
        );
          })}
      </ul> */}
      {/* <TextEditor setData={setPerNoteText} showFormulaBox={true} setLength={setLength}/> */}
      {/* <p>{` ${length}/2000`}</p> */}

      <Box   className="brd-botm " style={{padding:"1rem"}}>
        <Button
          variant="outlined"
          className="fullwidth"
          style={{textTransform: 'none'}}
          onClick={() => {
            dispatch(addPerNote(selectedPage, per_note_text));
            setPerNoteText("");
            dispatch(clearTxtEditor());
          }}
        >
          Add Personal Note
        </Button>
      </Box>

      <BorderStyle type="page" page={selectedPage} />
    </div>
  );
};

export default PageFormTab;
