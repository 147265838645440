import React from "react";
import { Redirect } from "react-router-dom";
import DefaultLayout from "../DefaultLayout";
import { Route } from "react-router-dom";
import {useSelector} from "react-redux"
export default function ProtectedPages({ route }) {
  const isAuthenticated = localStorage.getItem('user.access')
  // console.log(isAuthenticated)

  if (isAuthenticated) {
    return (
      <Route
        exact
        path={route.path}
        render={(props) => (
          <DefaultLayout
            children={() => (
              <route.component reactRouterProps={props} routes={route.routes} />
            )}
          />
        )}
      />
    );
  } else {
    return <Redirect to={{ pathname: "/login" }} />;
  }
}
