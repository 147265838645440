import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Grid } from "@material-ui/core";
import {
  setBoxImage,
  deleteBox,
  setCaptionBox,
  resetBoxType
} from "../../store/actions/containerActions";

import { BorderStyle } from "../borderstyle/BorderStyle";

//icons
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from '@mui/icons-material/Search';
import "./style.scss"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Api from "../../ApiRequests"

const EditImage = ({ selectedPage, box }) => {
  const [captiontxt, setcaptiontxt] = useState(box.caption || "");

useEffect(()=>{
  setcaptiontxt(box.caption || "")
},[box])

  const inputRef = useRef();
  const cRef = useRef();
  const [open, setOpen] = React.useState(false);
  const [openiconZoom, setopeniconZoom]= useState(false);
  const [images, setimages]=useState([]);
  const [loading, setloading] =  useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [preview, setpreview] = useState({
    view: false,
    url: "",
    file: undefined
  });

  const handleOpeniconZoom = () => setopeniconZoom(true);
  const handleCloseiconZoom = () => setopeniconZoom(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [search, setsearch] =  useState();

  const Input = styled('input')({
  display: 'none',
});

  const dispatch = useDispatch();

  const getsearch = (e)=>{
    setsearch(e.target.value);
  }

  const captionChange = (e) => {
    setcaptiontxt(e.target.value);
    dispatch(setCaptionBox(box, e.target.value));
  };

const getImages = async(type,search)=>{
  setloading(true)
  try{
    const res = await Api.getImage(search);
    setimages(res.data.body.results);
    setloading(false);
  } catch (err){
    console.log(err.response);
  }
}

useEffect(()=>{
  if(activeTab === 0)
  getImages("global",search)
  else
  getImages("myown", search)
},[activeTab,search])


const style = {
position: 'absolute',
top: '50%',
left: '50%',
transform: 'translate(-50%, -50%)',
bgcolor: 'background.paper',
boxShadow: 24,
p: 4,
};

const styleZoom = {
position: 'absolute',
top: '50%',
left: '50%',
transform: 'translate(-50%, -50%)',
bgcolor: 'background.paper',
boxShadow: 24,
p: 4,
width: 550,
};

useEffect(()=>{
  if(preview.file){
    const objectUrl = URL.createObjectURL(preview.file);
    setpreview({
      view: true,
      url: objectUrl,
      file: preview.file
    })
  }
}, [preview.file])

const previewfun = (e)=>{
  const file = e.target.files[0]
  setpreview({
    view: false,
    url: "",
    file: file
  })
}

const clear = ()=>{
  const form = document.getElementById( "Formimage" )
  document.getElementById( "caption" ).value= ""
  setpreview({
    view: false,
    url: "",
    file: undefined
  })
}

const uploadImage = async (e)=>{
  e.preventDefault();

  const caption = document.getElementById( "caption" ).value
  var formData = new FormData();
  formData.append("image", preview.file);
  formData.append("caption", caption)

  try{
    const res = await Api.uploadImage(formData);
    toast.success('Image Uploaded', {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });

  getImages();

  handleClose();
  }catch(err){
    console.log(err.response);
    toast.error(`${err.response.data.message}`, {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });
  }
clear();
}

  return (
    <div>

      <Box className="display-flex box-head brd-botm">

        <Button
          variant="outlined"
          onClick={()=>{
            dispatch(resetBoxType(box,false));
            dispatch(setBoxImage(box, ""));
            dispatch(setCaptionBox(box, ""));
          }}
        >
          Reset
        </Button>

        <Button
          variant="outlined"
          onClick={() =>{ dispatch(setBoxImage(box, "")); dispatch(setCaptionBox(box, ""));}}
        >
          clear
        </Button>

        <Button
          variant="outlined"
          color="error"
          onClick={() => dispatch(deleteBox(box))}
        >
          {/* <DeleteIcon fontSize="small" /> */}
          Delete Box
        </Button>
      </Box>
      <Box className="head brd-botm">
        <h4>
          Box {box.index + 1} : {box.type}
        </h4>
      </Box>

      <Box className="brd-botm" style={{padding:"1rem"}}>
        <div>
          <Tabs>
            {/* <TabList style={{ marginBottom: 0 }}>
              <Tab
                style={{
              backgroundColor: activeTab === 0 ? "orange" : "white",
              borderColor: "#fff",
              borderBottom: "1px solid #aaa",
              color: activeTab === 0 ? "white" : "rgba(0,0,0,0.4)",
              borderTopLeftRadius:"15px",
              borderTopRightRadius:"15px"
                }}
                onClick={() => setActiveTab(0)}
              >
                Global
              </Tab>
              <Tab
                style={{
              backgroundColor: activeTab === 1 ? "orange" : "white",
              borderColor: "#fff",
              borderBottom: "1px solid #aaa",
              color: activeTab === 1 ? "white" : "rgba(0,0,0,0.4)",
              borderTopLeftRadius:"15px",
              borderTopRightRadius:"15px"
                }}
                onClick={() => setActiveTab(1)}
              >
                Private
              </Tab>
            </TabList> */}

            <TabPanel
              style={{ border:  "2px solid orange" , position: "relative", top:"0" }}
            >
              <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
                <div className="search-comp display-flex">
                  <SearchIcon/>
                  <input type="text" placeholder="Search" value={search} onChange={getsearch}/>
                </div>
              </Box>
              {loading ? <Box className="load-box" >
                <CircularProgress color="inherit"/>
              </Box> : <Box className="img-box-img">
                <Grid container className={images.length === 0 ? "justify-content-center" : ""}>
                  {images.length === 0 ? <Box><p>No images found </p> </Box>:images.map((image, index) => {
                    return (
                      <Grid item xs={4}>
                        <img
                          src={image.imageurl}
                          key={index}
                          onClick={() => dispatch(setBoxImage(box, image.imageurl))}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>}
              <Box className="zoom-icon" onClick={handleOpeniconZoom}><OpenInFullIcon /></Box>
            </TabPanel>
            <TabPanel
              style={{ border:  "2px solid orange" , position: "relative", top:"0" }}
            >
              <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
                <div className="search-comp display-flex">
                  <SearchIcon/>
                  <input type="text" placeholder="Search" value={search} onChange={getsearch}/>
                </div>
              </Box>
              {loading ? <Box className="load-box" >
                <CircularProgress color="inherit"/>
              </Box> : <Box className="img-box-img-private">
                <Grid container className={images.length === 0 ? "justify-content-center" : ""}>
                  {images.length === 0 ? <Box><p>No images found </p> </Box>:images.map((image, index) => {
                    return (
                      <Grid item xs={4}>
                        <img
                          src={image.imageurl}
                          key={index}
                          onClick={() => dispatch(setBoxImage(box, image.imageurl))}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>}
              <Box className="display-flex justify-content-center brd-botm" style={{padding: " 1rem"}}>
                <Button className="fullwidth" onClick={handleOpen} >Upload Image</Button>
              </Box>
              <Box className="zoom-icon" onClick={handleOpeniconZoom}><OpenInFullIcon /></Box>
            </TabPanel>
          </Tabs>
        </div>
        <Box className="head">
          <h5>Caption</h5> <br/>
          <textarea className="cpt-txt" cols="30" onChange={captionChange} value={captiontxt}></textarea>
        </Box>
      </Box>
      <BorderStyle type="container" box={box} />


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{minWidth:500}}>
          <Box style={{display:"flex", justifyContent:"end", cursor:"pointer"}} onClick={handleClose}>
            <CloseIcon/>
          </Box>
          <div>
            <h2>Upload image</h2>
          </div>

          {preview.view && <Box className="preview-icon">
            <img src={preview.url}/>
          </Box>}
          <form id="Formimage">
            <Box style={{marginBottom:"1rem"}}>
              <label htmlFor="Iconfile">
                <Input accept="image/*" id="Iconfile" type="file" required onChange={previewfun}/>
                <Button variant="contained" component="span">
                  Choose Image
                </Button>
              </label>
              {preview.view && <Box component="span" style={{marginLeft:"1rem"}}>
                <Button variant="outlined" component="span" onClick={clear} type="button">
                  Clear image
                </Button>
              </Box>}
            </Box>

            <Box style={{marginBottom:"1rem"}}>
              <TextField id="caption" fullWidth placeholder="Caption" required name="caption" variant="outlined" />
            </Box>

            <Box>
              <Button variant="contained" onClick={uploadImage}>
                Upload
              </Button>
            </Box>
          </form>

        </Box>
      </Modal>

      <Modal
        open={openiconZoom}
        onClose={handleCloseiconZoom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleZoom}>
          <Tabs>
            {/* <TabList style={{ marginBottom: 0 }}>
              <Tab
                style={{
              backgroundColor: activeTab === 0 ? "orange" : "white",
              borderColor: "#fff",
              borderBottom: "1px solid #aaa",
              color: activeTab === 0 ? "white" : "rgba(0,0,0,0.4)",
              borderTopLeftRadius:"15px",
              borderTopRightRadius:"15px"
                }}
                onClick={() => setActiveTab(0)}
              >
                Global
              </Tab>
              <Tab
                style={{
              backgroundColor: activeTab === 1 ? "orange" : "white",
              borderColor: "#fff",
              borderBottom: "1px solid #aaa",
              color: activeTab === 1 ? "white" : "rgba(0,0,0,0.4)",
              borderTopLeftRadius:"15px",
              borderTopRightRadius:"15px"
                }}
                onClick={() => setActiveTab(1)}
              >
                Private
              </Tab>
            </TabList> */}

            <TabPanel
              style={{ border:  "2px solid orange" , position: "relative", top:"0" }}
            >
              <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
                <div className="search-comp display-flex">
                  <SearchIcon/>
                  <input type="text" placeholder="Search"/>
                </div>
              </Box>
              {loading ? <Box className="load-box" >
                <CircularProgress color="inherit"/>
              </Box> : <Box className="img-box-img">
                <Grid container className={images.length === 0 ? "justify-content-center" : ""}>
                  {images.length === 0 ? <Box><p>No images found </p> </Box>:images.map((image, index) => {
                    return (
                      <Grid item xs={4}>
                        <img
                          src={image.imageurl}
                          key={index}
                          onClick={() => dispatch(setBoxImage(box, image.imageurl))}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>}
              <Box className="zoom-icon" onClick={handleCloseiconZoom}><CloseFullscreenIcon /></Box>

            </TabPanel>
            <TabPanel
              style={{ border:  "2px solid orange" , position: "relative", top:"0" }}
            >
              <Box style={{padding:"1.5rem 1rem 0.6rem"}}>
                <div className="search-comp display-flex">
                  <SearchIcon/>
                  <input type="text" placeholder="Search"/>
                </div>
              </Box>
              {loading ? <Box className="load-box" >
                <CircularProgress color="inherit"/>
              </Box> : <Box className="img-box-img-private">
                <Grid container className={images.length === 0 ? "justify-content-center" : "justify-content-center"}>
                  {images.length === 0 ? <Box><p>No images found </p> </Box>:images.map((image, index) => {
                    return (
                      <Grid item xs={4}>
                        <img
                          src={image.imageurl}
                          key={index}
                          onClick={() => dispatch(setBoxImage(box, image.imageurl))}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>}
              <Box className="display-flex justify-content-center brd-botm" style={{padding: " 1rem"}}>
                <Button className="fullwidth" onClick={handleOpen} >Upload Image</Button>
              </Box>
              <Box className="zoom-icon" onClick={handleCloseiconZoom}><CloseFullscreenIcon /></Box>
            </TabPanel>
          </Tabs>
        </Box>
      </Modal>
    </div>
  );
};

export default EditImage;
