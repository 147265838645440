import * as Yup from "yup";

export const validationSchemaPass = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
  confirmPassword:Yup.string().when("newPassword", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("newPassword")],
      "Both password need to be the same"
    )
  }),
  newPassword: Yup
  .string()
  .required('Please Enter your password')
  .matches(
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  ),
});
