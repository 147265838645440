import React, { useState } from "react";
import { useSelector } from "react-redux";

import "./Header.scss";





const Header = ({ index, page }) => {
  const data = useSelector((state)=>state.worksheet)

  const {currentUser} = useSelector(state=>state.auth)


  return (
    <div  id={"header-"+ index} style={{height : page. headerHeight , maxHeight : page. headerHeight , minHeight : page. headerHeight}}>

      <div key={"header-" + index}>
        {index === 0 ? (
          <div className="header">
            <p className="header-title">INSTITUTION NAME</p>

            <p className="header-sous-title">Tutor : John Doe</p>
            <p className="header-text">
              Cell | +91-8858150032 | Email | johdoe@loren.com | Website |
              www.loren.com{" "}
            </p>
            <p className="header-text">Semester 1: Physics 3⁄4...</p>
          </div>
        ) : (
          <div className="header-2">
            <p className="header-title-2">{currentUser?.institution}</p>

            <div>
              <p className="header-text">
                <b>Tutor</b> : {data?.notesBy}
              </p>
              <p className="header-text">
                <b>Website</b> : {data?.website}
              </p>
              <p className="header-text">
                <b>Phone</b> : {data?.phoneNumber}
              </p>
              <p className="header-text">
                <b>Email</b> : {data?.email}
              </p>

            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default Header;
