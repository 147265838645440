import { Page, Container } from "../constants/index.js"


/**
 * Function to create a default container object
 * @returns a container default object
 */
export function createContainerObj() {
  const container = {
    pageIndex: 1,
    hasAddButton: false,
    height: 120,
    selected: true,
    name: "",
    category: "",
    borderHeight: 1,
    borderStyle: "",
    borderColor: "",
    backgroundColor: "",
    line: 1,
    boxes: [],
  }
  return container
}

export const getSelectedContainer = (containers) => {
  let selectedContainer = {};

  containers.map((container, index) => {
    if (container.selected) {
      selectedContainer = { ...container, index };
    }
  });

  return selectedContainer;
};

/**
 * Function to calculate containers in page
 * @param {any} pages 
 * @param {any} pageIndex 
 * @param {any} containers 
 * @returns 
 *  containers_in_page,
 *  pageNumberNeeded
 * 
 */
export const calculContainersInPage = (
  pages,
  pageIndex,
  containers
) => {

  let newC = [];
  let _pageIndex = 0;
  let pageContentHeight = 0
  let pagePersonnelNotesHeight = 0
  let containersMarginHeight = 0
  let spacefromPerNote = Container.CONTAINER_MIN_HEIGHT / 4

  let newContainers = [];
  let height = 0;
  for (let i = 0; i < containers.length; i++) {
    /// page content height calculation
    if (pages[_pageIndex]) {
      const total_per_notes = pages[_pageIndex].per_notes?.length ?? 0
      pagePersonnelNotesHeight = calculateTotalPerNoteHeight(_pageIndex, total_per_notes);
      pageContentHeight = pages[_pageIndex].pageContentHeight - pagePersonnelNotesHeight
    }
    pageContentHeight = pages[_pageIndex] ? pages[_pageIndex].pageContentHeight : pageContentHeight
    containersMarginHeight = 5

    height += containers[i].height +
      (containers[i].borderHeight * 4) +
      containersMarginHeight
    if (_pageIndex === 0) {
      if (height > pageContentHeight) {
        height = 0;
        newContainers.push(newC);
        newC = [];
        _pageIndex++;
      }
    } else {
      if (height + 120 > pageContentHeight) {
        height = 0;
        newContainers.push(newC);
        newC = [];
        _pageIndex++;
      }
    }


    if (i === containers.length - 1 && height <= pageContentHeight) {

      newContainers.push(newC);
      _pageIndex++;
    }

    newC.push(containers[i]);
  }

  return {

    containers_in_page: newContainers[pageIndex],
    pageNumberNeeded: newContainers.length,
  };
};

/**
 * Calculate height
 * @param {*} array 
 * @returns 
 */
function calculHeight(array) {
  return array.reduce((p, c) => (c ? p + c.height : 0), 0);
}

/**
 * Function to calculate total per note height
 * @param {int} page_index index of the page
 * @param {int} no_of_notes total no. of notes in the page
 * @returns 
 */
function calculateTotalPerNoteHeight(page_index, no_of_notes) {
  let total_per_note_height = 0;
  for (let per_note_index = 0; per_note_index < no_of_notes; per_note_index++) {
    let perNoteDiv = document.getElementById(`page-${page_index}-perNote-${per_note_index}`)
    total_per_note_height += perNoteDiv?.offsetHeight ?? 0
  }
  return total_per_note_height + Page.PAGE_PER_NOTE_MARGIN;
}

/**
 * Calculate no of pages needed
 * @param {*} pageContentHeight 
 * @param {*} containers 
 * @returns 
 */
export const calculNumberPagesNeded = (pageContentHeight, containers) => {
  return Math.ceil(calculHeight(containers) / pageContentHeight) + 1;
};

/**
 * Calculate container height
 * @param {*} containers 
 * @returns 
 */
export const calculContainersHeight = (containers) =>
  containers ? calculHeight(containers) : 0;
