import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function PassswordField(props) {
  const [show, setShow] = useState(false);
  const { name, label, ...rest } = props;
  return (
      <>
    <div   {...rest} style={{justifyContent:"space-between",display:"flex"}}>
      <Field
        style={{
          border: "none",
          outline: "none",
          width:"90%"
        }}
        variant="outlined"
        name={name}
        type={show?"text":"password"}
       id={name}
      />
      {show ? (
        <VisibilityIcon onClick={() => setShow(!show)} />
      ) : (
        <VisibilityOffIcon onClick={() => setShow(!show)} />
      )}
    </div>
      <ErrorMessage
        component="div"
        style={{ color: "red", fontSize: "xx-small",position:"absolute" }}
        name={name}
      />
      </>
  );
}
export default PassswordField;
