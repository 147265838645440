import Api from "../../ApiRequests";

export const signup = (values, history) => async (dispatch) => {
  try {
    // console.log(values);
    dispatch({ type: "LOADING" });
    const res = await Api.signUp(values);
    dispatch({ type: "OTP_EMAIL", payload: values });
    dispatch({ type: "LOADED" });
    history.push("/otp");
    // console.log(res, values);
    return res;
  } catch (err) {
    dispatch({ type: "LOADED" });
    dispatch({ type: "ERROR", payload: err.response.data.message });
    console.log(err.response);
  }
};

export const otpVerify = (OTP, history) => async (dispatch) => {
  try {
    console.log(OTP);
    dispatch({ type: "LOADING" });
    const res = await Api.otpVerification(OTP);
    await Api.login({ email: OTP.email, password: OTP.password }).then(
      (res) => {
        localStorage.setItem("user.access", res.data.body.token);
        dispatch({ type: "SIGN_IN", payload: res.data.body });
        history.push("/profile");
      }
    );
    dispatch({ type: "LOADED" });
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADED" });
    dispatch({ type: "ERROR", payload: error.response.data.message });
  }
};

export const Login = (values, history) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING" });
    Api.login(values)
      .then((res) => {
        localStorage.setItem("user.access", res.data.body.token);
        dispatch({ type: "SIGN_IN", payload: res.data.body });
        dispatch({ type: "LOADED" });
        history.push("/dashboard/All");
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "LOADED" });
        dispatch({ type: "ERROR", payload: err.response.data.message });
      });
  } catch (error) {
    console.log(error);
  }
};
export const getMyProfile = () => async (dispatch) => {
  try {
    const res = await Api.getMyProfile();
    dispatch({ type: "LOAD_USER", payload: res.data.body });
  } catch (error) {
    dispatch({ type: "ERROR", payload: error.response.data.message });
  }
};

export const loadUser = () => async (dispatch) => {
  try {
    Api.getMyProfile().then((res) =>
      dispatch({ type: "LOAD_USER", payload: res.data.body })
    );
  } catch (error) {
    dispatch({ type: "ERROR", payload: error.response.data.message });
  }
};
export const logOut=()=>async(dispatch)=>{
  try{
    localStorage.clear()
    dispatch({type:"LOGOUT"})
  }
  catch(err){
    console.log(err)
  }
}