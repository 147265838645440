import { Grid } from "@material-ui/core";
import React from "react";
import Pages from "../pages/Pages";
import Sideleft from "../sideleft/Sideleft";
import Sideright from "../sideright/Sideright";


import "./Content.scss"

const Content = () => {
  return (
    <div className="content">

      <div  style={{width : "16%" ,position : "sticky"}}>
        <Sideleft />
      </div>

      <div style={{display :"flex", justifyContent :  "center", width : "60%"}}>
        <Pages />
      </div>

      <div style={{width : "24%", position : "relative", top:"0", right:"0" }} >
        <Sideright />
      </div>

    </div>

  );
};

export default Content;
