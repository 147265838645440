import { filter } from "domutils";

/**
 * Create page
 * @returns
 */
export const addPage = () => (dispatch, getState) => {
  const pages = [
    ...getState().page.pages.map((page) => {
      page.selected = false;
      return page;
    }),
  ];
  dispatch({ type: "ADD_PAGE", payload: pages });

};

export const deletePage=(index)=>(dispatch,getState)=>{

  dispatch({type:"DELETE_PAGE",payload:index})
}

export const setActivetab = (activeTab) => async (dispatch, getState) => {
  dispatch({
    type: "ACTIVE_TAB",
    payload: activeTab,
  });
};

//deletePerNote



export const selectPage = (index) => async (dispatch, getState) => {
  console.log("Clicked")
  const pages = [
    ...getState().page.pages.map((page, i) => {
      if (i === index) {
        page.selected = true;
      } else {
        page.selected = false;
      }
      return page;
    }),
  ];

  dispatch({
    type: "SELECT_PAGE",
    payload: pages,
  });
};

export const setStylePage =
  (page, item, value) => async (dispatch, getState) => {
    const pages = [
      ...getState().page.pages.map((_page, index) => {
        if (_page.id === page.id) {
          _page.style[item] = value;
        }
        return _page;
      }),
    ];

    dispatch({
      type: "SET_PAGE_STYLE",
      payload: pages,
    });
  };

function checkAutoPersonnelAuto(arr) {
  let filterArr = arr?.filter(note => note.isAuto)
  if (filterArr?.length > 0)
    return true;
  else
    return false;
}

/**
 * Function to add personnel note
 * @param {any} page
 * @param {any} per_note_text
 * @param {int} height
 * @param {bool} auto
 * @returns
 */
export const addPerNote =
  (page, per_note_text, height, auto = false) => async (dispatch, getState) => {
    if (per_note_text.trim().length === 0 && !auto) return;
    const pages = [
      ...getState().page.pages.map((_page, index) => {
        if (_page.id === page.id) {
          //    _page.pageContentHeight = _page.pageContentHeight - 30;

          ////_page.per_note = per_note_text
          /// logic for auto personnel note
          if (auto) {
            if (!checkAutoPersonnelAuto(_page.per_notes)) {
              _page.per_notes.push({
                isAuto: auto,
                text: per_note_text,
                height: height,
              })
            }
          } else {
            _page.per_notes.push({
              isAuto: auto,
              text: per_note_text,
            });
          }

        }
        return _page;
      }),
    ];

    dispatch({
      type: "ADD_PER_NOTE",
      payload: pages,
    });
  };


export const deletePerNote =
  (page, index) => async (dispatch, getState) => {

    const pages = [
      ...getState().page.pages.map((_page, i) => {
        if (_page.id === page.id) {


          _page.per_notes.splice(index, 1)
        }
        return _page;
      }),
    ];

    dispatch({
      type: "ADD_PER_NOTE",
      payload: pages,
    });
  };


//calculPageContentHeight

export const calculPageContentHeight =
  (type, height, h, index) => async (dispatch, getState) => {

    const pages = getState().page.pages;
    if (type === "header") {
      pages[index].headerHeight = h;
      pages[index].pageContentHeight = pages[index].height - pages[index].footerHeight - height
    }

    dispatch({
      type: "CHANGE_HEIGHT",
      payload: pages,
    })

  }



  export const fetchPage = (payload)=>{
    return {
      type: "LOAD_PAGE",
      payload: payload
    }
  } 
