import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
  otp: Yup
  .string()
  .required('Please Enter your OTP')
  .matches(
    /^[0-9]{6,8}$/,
    "Must Contain 6 Characters"
  ),
});