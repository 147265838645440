import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.scss";
import { Delete } from "@material-ui/icons";
import { Form, Formik } from "formik";
import { validationSchemaPass } from "../../Form/validation/resetPassword";
import FormikController from "../../Form/FormControl";
import Button from "@mui/material/Button";
import { CircularProgress, Collapse } from "@mui/material";
import Api from "../../../ApiRequests";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const AskModal = ({ open, setOpen, id }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const history = useHistory();
  const [load, setLoad] = useState();
  const handleSubmit = (isPass) => {
    setLoad(true);
    const allCSS = [...document.styleSheets]
      .map((styleSheet) => {
        try {
          return [...styleSheet.cssRules].map((rule) => rule.cssText).join("");
        } catch (e) {
          console.log(
            "Access to stylesheet %s is denied. Ignoring...",
            styleSheet.href
          );
        }
        return 0;
      })
      .filter(Boolean)
      .join("\n");
    const prtContent = document.getElementById("pages");

    try {
      if (isPass) {
        Api.publishWorksheet(id, {
          htmlContent: `<style>${allCSS}</style> ${prtContent.innerHTML}`,
          pass: isPass,
          password: document.getElementById("newPassword").value,
        })
          .then((res) => {
            // console.log(res);
            var a = document.createElement('a');
            a.href = res.data.body.url;
            a.download = true;
            // a.click();
            history.push("/dashboard/All");
            window.open(res.data.body.url);
            setLoad(false);
          })
          .catch((err) => {
            console.log(err);
            setLoad(false);
            toast.error(`${err.response.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      } else {
        Api.publishWorksheet(id, {
          htmlContent: `<style>${allCSS}</style> ${prtContent.innerHTML}`,
          pass: isPass,
        })
          .then((res) => {
            console.log(res);
            var a = document.createElement('a');
            a.href = res.data.body.url;
            a.download = true;
            // a.click();
            history.push("/dashboard/All");
            window.open(res.data.body.url);
            setLoad(false);
          })
          .catch((err) => {
            console.log(err);
            setLoad(false);
            toast.error(`${err.response.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      }
    } catch (err) {
      console.log(err);
      setLoad(false);
      toast.error(`${err.response.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const initialState = {
    email: "na@gm.com",
    newPassword: "",
    confirmPassword: "",
  };
  const [pass, setPass] = useState(false);

  return (
    <Dialog maxWidth="sm" onClose={handleClose} open={open} fullWidth={true}>
      <div className={styles.modal}>
        <h3 className={styles.heading} style={{ display: "flex" }}>
          <Delete />
          {"  "}Publish your PDF
        </h3>
        <div className={styles.underline}></div>
        <div
          style={{
            margin: "1rem 0rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Do You want to encrypt PDF?</div>
          <div className={styles.btns}>
            <Button
              type="submit"
              style={{ background: " #FFA737" }}
              variant="contained"
              disabled={load}
              className={styles.btn}
              onClick={() => {
                setPass(false);
                handleSubmit(false);
              }}
            >
              {!load && <div style={{ marginRight: "5px" }}>No, Publish</div>}
              {load && !pass && (
                <CircularProgress size={20} style={{ color: "white" }} />
              )}
            </Button>
            <Button
              type="reset"
              className={styles.btn1}
              onClick={() => setPass(true)}
            >
              Yes
            </Button>
          </div>
        </div>
        <Collapse in={pass}>
          <Formik
            initialValues={initialState}
            validationSchema={validationSchemaPass}
            onSubmit={(values) => handleSubmit(true)}
          >
            {({ dirty, isValid }) => (
              <Form>
                {" "}
                <div className={styles.password}>
                  <div className={styles.text} style={{ marginTop: "5px" }}>
                    <label className={styles.topic}>Password</label>
                    <FormikController
                      variant="outlined"
                      style={{ border: "1px solid #FFA737" }}
                      className={styles.input}
                      name="newPassword"
                      control="password"
                    />
                  </div>
                </div>
                <div className={styles.btns} style={{ marginTop: "5%" }}>
                  {/* {   console.log(isValid,dirty,values)} */}

                  <Button
                    variant="contained"
                    type="submit"
                    disabled={load || !dirty || !isValid}
                    className={styles.btn2}
                    style={{
                      backgroundColor:
                        load || !dirty || !isValid
                          ? "rgba(0, 0, 0, 0.12)"
                          : "#FFA737",
                    }}
                  >
                    <div style={{ marginRight: "5px" }}>Publish</div>
                    {load && (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    )}
                  </Button>
                  <Button
                    type="reset"
                    className={styles.btn1}
                    onClick={() => setPass(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Collapse>

        <div></div>
      </div>
    </Dialog>
  );
};

export default AskModal;
